import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { ServerSideEventData, ServerSideEventState, SseProgressService } from '../../service/sse-progress.service';

@Component({
  selector: 'app-sse-progress-bar',
  templateUrl: './sse-progress-bar.component.html',
  styleUrls: ['./sse-progress-bar.component.scss']
})
export class SseProgressBarComponent implements OnInit {

  @ViewChild('modalSseDetails') modalSseDetails: ModalComponent;

  currentValue = 0;

  constructor(
    public sseProgressService: SseProgressService
  ) {
    this.updateProgress();
  }

  ngOnInit(): void {
    this.sseProgressService.$progressEvents.subscribe(() => this.updateProgress());
  }

  onClickProgressBar(): void {
    this.showModal();
  }

  showModal(): void {
    if (!this.modalSseDetails) { return; }
    this.modalSseDetails.show();
  }

  hideModal(): void {
    if (!this.modalSseDetails) { return; }
    this.modalSseDetails.hide();
  }

  updateProgress(): void {
    const mostRelevantEvent = this.getMostRelevantEvent();
    if (!mostRelevantEvent) { return; }
    this.currentValue = Math.max(...mostRelevantEvent.progressEvents.map(pe => pe.progress)); // Get highest progress from all progress events
  }

  getMostRelevantEvent(): ServerSideEventData {
    if (!this.sseProgressService.events) { return null; }
    if (this.sseProgressService.events.length === 0) { return null; }
    if (this.sseProgressService.events.length === 1) { // If there is only one event, return it
      return this.sseProgressService.events[0];
    }
    // Get all events that are not finished
    const eventsNotFinished = this.sseProgressService.events.filter(e => e.state !== ServerSideEventState.FINISHED);
    if (eventsNotFinished.length === 0) { // If all events are finished, return the latest one
      return this.sseProgressService.events[this.sseProgressService.events.length - 1];
    } else {
      return eventsNotFinished[0]; // If there are events that are not finished, return the first one
    }
  }
}
