<div class="schedule-item-container" [class.dropdown-open]="dropdownExpanded" *ngIf="schedule">
  <div class="card-header" (click)="toggleDropdown()">
    <div class="col-arrow"[title]="(dropdownExpanded ? eCommonMessages.COLLAPSE : eCommonMessages.EXPAND) | translate">
      <ng-container *ngIf="canToggleDropdown">
        <div class="fal fa-fw fa-caret-right"></div>
      </ng-container>
    </div>
    <div class="col-details">
      <div class="display-schedule-purpose">
        <h5 class="standard-heading">{{schedule.purposeTitle}}</h5>
        <div class="standard-description">
          <span>{{schedule.startDateTime | date}}</span>
          <ng-container *ngIf="lastMeetingTimestamp">
            <span class="m-right-5"></span>
            <span class="m-right-5">-</span>
            <span class="m-right-5"></span>
            <span>{{lastMeetingTimestamp | date}}</span>
          </ng-container>
          <!-- <span class="m-right-5"></span>
          <span>({{schedule.frequency}})</span> -->
        </div>
        <!-- <span class="m-right-5"></span>
        <app-pill [type]="ePillType.YELLOW" *ngIf="schedule.category">
          <strong>{{schedule.category}}</strong>
        </app-pill> -->
      </div>
    </div>
  </div>
  <div class="dropdown-contents">
    <div class="display-schedule-manager p-bottom-5" *ngIf="schedule.manager">
      <strong>Manager:</strong>
      <span class="m-right-5"></span>
      <div class="d-inline-block">
        <app-profile-photo [width]="'30px'" [src]="schedule.manager.imageUrl" [firstName]="schedule.manager.firstName" [lastName]="schedule.manager.lastName"></app-profile-photo>
      </div>
      <span class="m-right-5"></span>
      <span>{{schedule.manager.firstName}} {{schedule.manager.lastName}}</span>
    </div>

    <div class="display-schedule-participants p-bottom-5" *ngIf="schedule.participants">
      <ng-container *ngIf="schedule.participants.length > 1; else templateSingleParticipant;">
        <strong>Participants:</strong>
        <span class="m-right-5"></span>
        <div class="d-inline-block">
          <app-person-picture-list [users]="schedule.participants"></app-person-picture-list>
        </div>
      </ng-container>
      <ng-template #templateSingleParticipant>
        <strong>Participant:</strong>
        <span class="m-right-5"></span>
        <div class="d-inline-block">
          <app-profile-photo [width]="'30px'" [src]="schedule.participants[0].imageUrl" [firstName]="schedule.participants[0].firstName" [lastName]="schedule.participants[0].lastName"></app-profile-photo>
        </div>
        <span class="m-right-5"></span>
        <span>{{schedule.participants[0].firstName}} {{schedule.participants[0].lastName}}</span>
      </ng-template>
    </div>

    <div class="display-schedule-frequency p-bottom-5">
      <strong>Frequency:</strong>
      <span class="m-right-5"></span>
      <span>{{schedule.frequency}}</span>
    </div>

    <div class="display-schedule-meeting-length p-bottom-5">
      <strong>Meeting length:</strong>
      <span class="m-right-5"></span>
      <span>{{schedule.meetingLength | wordMinsToNumerical}}</span>
    </div>

    <div class="display-schedule-location-type">
      <strong>Location:</strong>
      <span class="m-right-5"></span>
      <span>{{schedule.locationType | underscoreToSpace | titlecase}} - {{schedule.location}}</span>
    </div>

    <hr class="m-top-10 m-bottom-10"/>

    <div class="display-schedule-meetings p-bottom-5">
      <div class="p-bottom-5">
        <div class="frankli-form-group frankli-form-group-filled">
          <strong>Meeting</strong>
          <select class="form-control" [formControl]="controlSelectedMeeting">
            <option [ngValue]="null" *ngIf="!controlSelectedMeeting.value" translate>{{eCommonMessages.SELECT_PROMPT}}</option>
            <ng-container *ngFor="let meeting of meetingList; index as index">
              <option [ngValue]="meeting.id">{{meeting.meetingTimestamp ? (meeting.meetingTimestamp | date) : ('Meeting ' + (index + 1))}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <ng-container *ngIf="controlSelectedMeeting.value && selectedMeeting">
        <app-one-to-one-meeting-display [schedule]="schedule" [meeting]="selectedMeeting"></app-one-to-one-meeting-display>
      </ng-container>
    </div>
  </div>
</div>
