<app-nav-tabs [data]="navTabData" [selected]="tabOpen" (tabChanged)="switchTab($event)"></app-nav-tabs>

<app-state [state]="state" [stateHeight]="'200px'">
  <ng-container *ngIf="tabOpen !== ePageTabs.NONE" [ngSwitch]="tabOpen">
    <ng-container *ngSwitchCase="ePageTabs.CONTACT">
      <div class="info-box">
        <div class="content">
          <div class="row">
            <div class="col-xs-3 text-left user-info">
              <label>Role</label>
            </div>
            <div class="col-xs-9 text-left user-info" [class.competency-popup]="globals.hasFeature(eFeature.COMPETENCIES)">
              <ng-container *ngIf="userProfile.position; else noRole">
                <span #positionText>
                  {{userProfile.position.name}}
                  {{(userProfile.position.grade.trim().length > 0 && globals.hasFeature(eFeature.GRADES)) ? '(' + userProfile.position.grade + ')' : ''}}
                </span>
                <ng-container *ngIf="globals.hasFeature(eFeature.COMPETENCIES)">
                  <div class="competency-popup-pointer" [style.left]="((positionText.offsetWidth/2)+5)+'px'">
                  </div>
                  <div class="competency-popup-window">
                    <ng-container *ngIf="userProfile.position.competencies.length > 0">
                      <h3 class="m-top-0">
                        {{globals.getTerminology(eTerminologyEntity.COMPETENCY_PLURAL) | titlecase}} for {{userProfile.position.name}}
                        {{(userProfile.position.grade.trim().length > 0 && globals.hasFeature(eFeature.GRADES)) ? '(' + userProfile.position.grade + ')' : ''}}
                      </h3>
                    </ng-container>
                    <ng-container *ngFor="let competency of userProfile.position.competencies; let i = index">
                      <div>
                        <div class="popup-competency-name">{{competency.name}}</div>
                        <div class="popup-competency-description">
                          <app-rich-display [text]="competency.description"></app-rich-display>
                        </div>
                        <hr *ngIf="i !== (userProfile.position.competencies.length - 1)"/>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="userProfile.position.competencies.length === 0">
                      <div class="popup-competency-empty text-center">This position has no {{globals.getTerminology(eTerminologyEntity.COMPETENCY_PLURAL) | lowercase}}
                        assigned
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noRole>
                Role Pending 
              </ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-3 text-left user-info">
              <label>Manager</label>
            </div>
            <div class="col-xs-9 text-left user-info">
              <span *ngIf="userProfile.managerId !== userProfile.id; else noManager" [routerLink]="'/profile/' + userProfile.managerId" class="text-capitalize clickable frankli-view" [title]="userProfile.managerName | titlecase">{{userProfile.managerName}}</span>
              <ng-template #noManager>
                <span class="no-manager noselect">{{userProfile.managerName}}</span>
              </ng-template>
            </div>
          </div>
          <div class="row" *ngIf="userProfile.email">
            <div class="col-xs-3 text-left user-info">
              <label>Email</label>
            </div>
            <div class="col-xs-9 text-left user-info">
              <a href="mailto:{{userProfile.email}}">{{userProfile.email}}</a>
            </div>
          </div>
          <div class="row" *ngIf="userProfile.twitterHandle">
            <div class="col-xs-3 text-left user-info">
              <label>Twitter</label>
            </div>
            <div class="col-xs-9 text-left user-info">
              <a href="https://twitter.com/{{userProfile.twitterHandle}}">&#64;{{userProfile.twitterHandle}}</a>
            </div>
          </div>
          <div class="row" *ngIf="userProfile.birthdayReminder && userProfile.dateOfBirth">
            <div class="col-xs-3 text-left user-info">
              <label>Birthday</label>
            </div>
            <div class="col-xs-9 text-left user-info">
              {{userProfile.dateOfBirth | date:"dd MMM"}}
            </div>
          </div>
          <div class="row" *ngIf="userProfile.phoneNumber">
            <div class="col-xs-3 text-left user-info">
              <label>Phone</label>
            </div>
            <div class="col-xs-9 text-left user-info">
              {{userProfile.phoneNumber}}
            </div>
          </div>
          <div class="row" *ngIf="userProfile.preferredContactMethod">
            <div class="col-xs-3 text-left user-info">
              <label>Contact Me</label>
            </div>
            <div class="col-xs-9 text-left user-info" [ngSwitch]="userProfile.preferredContactMethod">
              <span *ngSwitchCase="ePreferredContactMethod.NONE">No preference</span>
              <span *ngSwitchCase="ePreferredContactMethod.IN_PERSON">In Person</span>
              <span *ngSwitchCase="ePreferredContactMethod.EMAIL">By Email</span>
              <span *ngSwitchCase="ePreferredContactMethod.PHONE">By Phone</span>
              <span *ngSwitchCase="ePreferredContactMethod.TEXT">By Text</span>
              <span *ngSwitchCase="ePreferredContactMethod.LINKEDIN">On LinkedIn</span>
              <span *ngSwitchCase="ePreferredContactMethod.MICROSOFT_TEAMS">On Microsoft Teams</span>
              <span *ngSwitchCase="ePreferredContactMethod.SLACK">On Slack</span>
              <span *ngSwitchCase="ePreferredContactMethod.TWITTER">On Twitter</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    
    <ng-container *ngSwitchCase="ePageTabs.PERSONAL">
      <div class="p-left-15 p-right-15">
        <ng-container *ngIf="(personalInterests.length > 0); else noPersonalInterests">
          <div class="scroll-x">
            <table width="100%">
              <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
                <th>Interest Name</th>
                <th>People who share this interest</th>
              </tr>
              <ng-container *ngFor="let pi of personalInterests; index as i">
                <tr class="p-top-5" *ngIf="i < displayCount">
                  <td>
                    <a class="interest-link" routerLink="connect/socialise/interest/{{pi.interest.id}}" [queryParams]="{source: 'profile'}">{{pi.interest.name}}</a>
                  </td>
                  <td width="60%" class="text-nowrap ellipsis-text similar-overflow">
                    <ng-container *ngFor="let u of pi.users; index as i">
                      <span class="img-similar safari-transform-fix" (click)="navigateToUser(u.id)">
                        <app-profile-photo *ngIf="i < 11" [src]="u.imageUrl" [firstName]="u.firstName" [lastName]="u.lastName"></app-profile-photo>
                      </span>
                    </ng-container>
                    <span *ngIf="pi.users.length > 10" class="img-similar safari-transform-fix similar-overflow-icon">
                      +{{pi.users.length - 10}}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
          <div class="text-center p-bottom-10" *ngIf="personalInterests.length > displayCount">
            <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-small btn-load-more" (click)="loadMore()">Load more</button>
          </div>
        </ng-container>
        <ng-template #noPersonalInterests>
          <app-empty-state [height]="'200px'" [message]="'No personal interests selected'" [primaryButtonShown]="true" [primaryButtonText]="'Select interests'" (primaryButtonPressed)="this.navigateToRoute(['/settings/interests'])"></app-empty-state>
        </ng-template>
      </div>
    </ng-container>
    
    <ng-container *ngSwitchCase="ePageTabs.PROFESSIONAL">
      <div class="p-left-15 p-right-15">
        <ng-container *ngIf="(professionalInterests.length > 0); else noProfessionalInterests">
          <div class="scroll-x">
            <table width="100%">
              <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
                <th>Interest Name</th>
                <th>People who share this interest</th>
              </tr>
              <ng-container *ngFor="let pi of professionalInterests; index as i">
                <tr class="p-top-5" *ngIf="i < displayCount">
                  <td>
                    <a  class="interest-link" routerLink="connect/socialise/interest/{{pi.interest.id}}" [queryParams]="{source: 'profile'}">{{pi.interest.name}}</a>
                  </td>
                  <td width="60%" class="text-nowrap ellipsis-text similar-overflow">
                    <ng-container *ngFor="let u of pi.users; index as i">
                      <span class="img-similar safari-transform-fix" (click)="navigateToUser(u.id)">
                        <app-profile-photo *ngIf="i < 11" [src]="u.imageUrl" [firstName]="u.firstName" [lastName]="u.lastName"></app-profile-photo>
                      </span>
                    </ng-container>
                    <span *ngIf="pi.users.length > 10" class="img-similar safari-transform-fix similar-overflow-icon">
                      +{{pi.users.length - 10}}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
          <div class="text-center p-bottom-10" *ngIf="professionalInterests.length > displayCount">
            <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-small btn-load-more" (click)="loadMore()">Load more</button>
          </div>
        </ng-container>
        <ng-template #noProfessionalInterests>
          <app-empty-state [height]="'200px'" [message]="'No professional interests selected'" [primaryButtonShown]="true" [primaryButtonText]="'Select interests'"(primaryButtonPressed)="this.navigateToRoute(['/settings/interests'])"></app-empty-state>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="ePageTabs.SKILLS">
      <ng-container *ngIf="(userProfile?.skills?.length > 0); else templateNoSkills">
        <div class="container-interest-list">
          <ng-container *ngFor="let skill of userProfile.skills; index as i">
            <app-interest-display [interest]="skill" [showIcon]="false"></app-interest-display>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #templateNoSkills>
        <ng-container *ngIf="userProfile.id === globals.user.id; else templateOtherUserProfile;">
          <app-empty-state [height]="'200px'" [message]="'No ' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL) + ' selected'" [primaryButtonShown]="true" [primaryButtonText]="'Select ' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL)" (primaryButtonPressed)="this.navigateToRoute(['/settings/skills'])"></app-empty-state>
        </ng-container>
        <ng-template #templateOtherUserProfile>
          <app-empty-state [height]="'200px'" [message]="'No ' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL) + ' selected'"></app-empty-state>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>  
</app-state>

<ng-template #stateLoading>
  <div class="p-top-15 p-bottom-15 text-center">
    <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
  </div>
</ng-template>
