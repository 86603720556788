<div class="sidebar-tasks-container">
  <div class="sidebar-content-title">
    <span translate>{{eTasksMessages.TASKS}}</span>
  </div>

  <div class="content-inner-regular">
    <app-state [state]="state" [stateHeight]="'200px'">
      <div class="task-filters">
        <app-button-group [options]="statusOptions" [formControl]="controlTaskStatus"></app-button-group>
        <div class="text-right p-top-5">
          <label class="control-archived">
            <span>Show archived</span>
            <span class="m-right-5"></span>
            <input type="checkbox" [formControl]="controlTaskArchived" />
          </label>
        </div>
      </div>

      <ng-container *ngIf="tasks.length > 0; else templateNoTasksSearch;">
        <div class="tasks-list">
          <ng-container *ngFor="let task of tasks; index as index;">
            <div class="tasks-list-item">
              <app-task-display [task]="task" [actionsShown]="['view']" (onClickAction)="onTaskAction($event, task)"></app-task-display>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #templateNoTasksSearch>
        <app-empty-state [height]="'200px'" [message]="eTasksMessages.EMPTY_STATE_SEARCH"></app-empty-state>
      </ng-template>

      <app-modal #modalViewTask [sidePanel]="true" [showCloseButton]="false" *ngIf="taskViewing">
        <app-task-details-component [task]="taskViewing" (onTaskUpdated)="taskUpdated($event, taskViewing)"></app-task-details-component>
      </app-modal>
    </app-state>
  </div>
</div>