<div class="vincere-sidebar-container">
  <div class="panel-heading">
    <span class="header-img" title="Vincere">
      <img src="assets/img/integrations/vincere.svg" />
    </span>
    <h5 *ngIf="own">Your Vincere stats</h5>
    <h5 *ngIf="!own">{{user ? user.firstName + '\'s' : 'Their' }} Vincere stats</h5>
  </div>

  <div class="mh-context" *ngIf="userStatus">
    <div class="context-card">
      <span class="fal fa-fw fa-info-circle"></span>
      <ng-container [ngSwitch]="userStatus.type">
        <span *ngSwitchCase="eVincereUserStatusType.NOT_SYNCED" class="context-text">We will get your data from Vincere soon...</span>
        <span *ngSwitchCase="eVincereUserStatusType.USER_SYNCED" class="context-text">We will get your data from Vincere soon...</span>
        <span *ngSwitchCase="eVincereUserStatusType.DATA_SYNCED" class="context-text">Last updated {{userStatus.lastSync! | dateAgo | lowercase}} </span>
        <span *ngSwitchCase="eVincereUserStatusType.NO_VINCERE_USER" class="context-text">We could not find any information in Vincere relating to your email</span>
        <span *ngSwitchCase="eVincereUserStatusType.ERROR" class="context-text">There was an error when trying to get data from Vincere (last tried {{userStatus.lastSync! | dateAgo | lowercase}})</span>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!state.loading; else stateLoading">
    <ng-container *ngIf="!state.error; else stateError">
      <ng-container *ngIf="metrics && metrics.length > 0; else stateEmpty">
        <!-- Filters -->
        <div class="p-bottom-10">
          <div class="frankli-form-group frankli-form-group-filled">
            <label for="month">Month</label>
            <select id="month" name="month" class="form-control" [formControl]="monthControl">
              <option [ngValue]="null" selected *ngIf="monthControl.value === null">Please select...
              </option>
              <option *ngFor="let dt of dates" [ngValue]="dt">{{ dt | momentPipe: 'MMMM YYYY'}}</option>
            </select>
          </div>
        </div>

        <!-- Data Output -->
        <div class="metric-list">
          <ng-container *ngFor="let metric of metrics">
            <div class="metric-item">
              <div class="metric-item-top">
                <div class="metric-value">{{metric.result}}</div>
                <div class="metric-icon">
                  <span class="fal fa-fw" [ngClass]="metric.vincereMetric.iconClass!"></span>
                </div>
              </div>
              <div class="metric-title">{{metric.vincereMetric.name}}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- TODO: Use component -->
      <ng-template #stateEmpty>
        <div class="p-top-15 p-bottom-15 text-center">
          <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
          <br />
          <span>No data was found</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- #region - Alt states -->
  <!-- TODO: Use component -->
  <ng-template #stateLoading>
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>

  <!-- TODO: Use component -->
  <ng-template #stateError>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br />
      <span>An error occurred loading this information</span>
    </div>
  </ng-template>
  <!-- #endregion -->
</div>