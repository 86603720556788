import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {User, UserState} from 'app/models/user/user.model';
import {UserAPIService} from 'app/shared/api/user.api.service';
import {Globals} from 'app/shared/globals/globals';
import {PraiseAPIService} from 'app/shared/api/praise.api.service';
import {CreatePraiseDto} from 'app/models/feedback/praise.model';
import {CompanyAPIService} from '@app/shared/api/company/company.api.service';
import { CompanyFeatures } from '@app/models/company-features.model';
import {ProfilePraiseComponent} from 'app/shared/profile/profile-praise/profile-praise.component';
import {RoleName} from '@app/models/user-role.model';
import {OneToOneAPIService} from '@app/domain/one_to_one/api/one-to-one-api.service';
import {OneToOneScheduleDetailsView} from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import {Tag} from '@app/domain/tag/model/tag.model';
import {Breadcrumb} from 'app/models/breadcrumb.model';
import {BreadcrumbService} from 'app/shared/breadcrumbs/breadcrumbs.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalComponent} from '@app/shared/modal/modal.component';
import {FrankliValidators} from '@app/shared/validators/validators';
import {forkJoin} from 'rxjs';
import moment from 'moment';
import {ScimUser} from '@app/models/user/scim-user.model';
import {ScimUserApiService} from '@app/shared/api/scim-user.api.service';
import {NotifyUtils} from '@app/shared/utils/notify.utils';
import {SecondaryManagerAPIService} from '@app/shared/api/company/secondary-manager.api.service';
import {SecondaryManager} from '@app/models/company/company-secondary-manager/secondary-manager.model';
import {PillType} from '@app/shared/components/pill/pill.component';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import {UserGoalsModalComponent} from '@app/goals/goals-components/user-goals-modal/user-goals-modal.component';
import {Site} from '@app/models/site.model';
import {ProfileMessages} from '@app/profile/profile.messages';
import {CommonMessages} from '@app/constants/common.messages';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';
import { SettingsMessages } from '@app/domain/settings/locale/settings.messages';
import { TagBusinessService } from '@app/domain/tag/service/tag-business.service';
import { IState } from '@app/models/state/state.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

interface PageTag extends Tag {
  selected: boolean;
}

@Component({
  selector: 'app-user-component',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.scss'],
})

export class UserComponent implements OnInit, OnDestroy {

  public readonly largeEditorMinLength = 10;
  public readonly largeEditorMaxLengthSoft = 1000;
  public readonly largeEditorMaxLengthHard = 2000;

  public readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eSettingsMessages = SettingsMessages;
  public readonly eProfileMessages = ProfileMessages;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eCommonMessages = CommonMessages;
  public readonly eButtonType = ButtonType;
  public readonly eUserState = UserState;
  public readonly eRoleName = RoleName;

  @ViewChild('praiseModal') private praiseModal !: ModalComponent;
  @ViewChild('praiseComp') private praiseComp !: ProfilePraiseComponent;
  @ViewChild('aboutMeCard') private aboutMe !: ElementRef;
  @ViewChild('userGoalsModal') userGoalsModal!: UserGoalsModalComponent;

  id: number;
  user: User;
  userProfile: User;
  scimUser: ScimUser | undefined = undefined;

  praiseSubmitted = false;

  userBirthday: string;
  titleText: string;
  workingFrom: string;

  userTimezone: string;
  usersTime: string;

  officeLocations: Site[];
  officeLocation: FormControl;
  city: FormControl;
  showManagerLink = false;
  praiseForm!: FormGroup;
  archived: boolean;
  hasOneToOne: OneToOneScheduleDetailsView;

  availableValues: PageTag[];
  selectedValues: PageTag[];

  state: IState;
  breadcrumb: Breadcrumb;

  minHeightContact: number;
  secondaryManager: SecondaryManager | undefined;
  pillType = PillType.GREEN;

  constructor(
    public globals: Globals,
    private userService: UserAPIService,
    public route: ActivatedRoute,
    private scimUserApiService: ScimUserApiService,
    private router: Router,
    private praiseAPIService: PraiseAPIService,
    private companyAPIService: CompanyAPIService,
    private oneToOneAPIService: OneToOneAPIService,
    private breadcrumbService: BreadcrumbService,
    private secondaryManagerService: SecondaryManagerAPIService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private notifyUtils: NotifyUtils,
    private tagBusinessService: TagBusinessService
  ) {
    this.id = undefined!;
    this.user = undefined!;
    this.userProfile = undefined!;
    this.usersTime = undefined!;
    this.userTimezone = undefined!;

    this.officeLocation = this.initFormSite();
    this.city = this.initFormCity();

    this.officeLocations = [];
    this.availableValues = [];
    this.selectedValues = [];

    this.archived = false;

    this.userBirthday = '';
    this.titleText = '';
    this.workingFrom = '';

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.hasOneToOne = {} as OneToOneScheduleDetailsView;
    this.breadcrumb = this.breadcrumbService.init(this.route);

    this.minHeightContact = 0;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  getData() {
    this.route.paramMap.subscribe(
      (params) => {
        const id = params.get('id');

        forkJoin([
          this.companyAPIService.getAllSites(),
          this.tagBusinessService.get(null, null, [TagType.COMPANY_VALUE])
        ]).subscribe(
          ([officeLocations, companyValues]) => {
            this.officeLocations = officeLocations;

            // Populate company values
            this.availableValues = this.parseTagToPageTagMultiple(companyValues);

            // If no ID supplied or current users ID is supplied, get current users data
            if (!id || (+id === this.globals.user.id)) {
              return this.getCurrentUserData();
            }

            this.id = +id;

            // If ID is not a number, show error
            if (isNaN(this.id)) {
              this.doError('Invalid user ID');
              setTimeout(() => this.getContactHeight(), 100);
              return;
            }

            this.getOtherUsersData(this.id);
          },
          (err: HttpErrorResponse) => this.doError(err.message)
        );
      }
    );

    this.initPraiseForm();
  }

  parseTagToPageTagMultiple(tags: Tag[]): PageTag[] {
    return tags.map(t => this.parseTagToPageTagSingle(t));
  }

  parseTagToPageTagSingle(tag: Tag): PageTag {
    const output = tag as PageTag;

    if (output) {
      output.selected = false;
    }

    return output;
  }

  getCurrentUserData() {
    this.breadcrumbService.updateSpecificBreadcrumb(this.breadcrumb, 'My Profile', null);
    this.populateUserDetails();
    this.getManager();

    if (this.globals.hasFeature(CompanyFeatures.AZURE_ACTIVE_DIRECTORY)) {
      this.getCurrentScimUser();
    }

    this.userTimezone = this.getUserTimezone(this.globals.user);

    this.startTimeLoop();

    this.state.loading = false;

    this.startCheckContactHeight();
  }

  getCurrentScimUser() {
    this.scimUserApiService.getScimUserByUserId(this.globals.user.id).subscribe(scimUser => {
      this.scimUser = scimUser;
    });
  }

  getOtherUsersData(id: number) {
    // Get other users profile info
    this.userService.getById(id).subscribe(
      (userResponse) => {
        this.userProfile = userResponse;
        this.breadcrumbService.updateSpecificBreadcrumb(this.breadcrumb, `${userResponse.firstName} ${userResponse.lastName}'s Profile`, null);

        // Check if archived
        this.archived = this.userProfile.roles.some(role => role.name === RoleName.ARCHIVED);

        // Get 1:1s
        this.getOneToOnes();

        this.titleText = `Hey ${this.globals.user.firstName}, lets hear your praise for ${this.userProfile.firstName}!`;

        this.userBirthday = (new Date(this.userProfile.dateOfBirth!).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
        }));

        this.getManager();

        this.userTimezone = this.getUserTimezone(userResponse);
        this.checkSecondaryManager();

        this.startTimeLoop();

        this.state.loading = false;

        this.startCheckContactHeight();
      },
      (err: HttpErrorResponse) => this.doError(err.message)
    );
  }

  getOneToOnes(){
    this.oneToOneAPIService.getOneToOneSchedulesForManagerMe().subscribe(
      (success: OneToOneScheduleDetailsView[]) => {
        this.hasOneToOne = success.find(x => x.participants.length === 1 && x.participants[0].id === this.userProfile.id && x.status !== 'ARCHIVED')!;
        if (!this.hasOneToOne) {
          this.oneToOneAPIService.getOneToOneSchedulesForUserMe().subscribe(resp => {
            this.hasOneToOne = resp.find(x => x.manager.id === this.userProfile.id && x.status !== 'ARCHIVED')!;
          });
        }
      }
    );
  }

  checkSecondaryManager(){
    if(!this.globals.hasFeature(CompanyFeatures.SECONDARY_MANAGER) || !this.globals.hasRole(RoleName.SECONDARY_MANAGER)){
      return;
    }

    this.secondaryManagerService.getSecondaryManagerForUser(this.userProfile.id, this.globals.user.id).subscribe(x => {
      if (!x) {
        return;
      }

      this.secondaryManager = x;
    });
  }

  getUserTimezone(user: User): string | undefined {
    if (!user.homeAddress) {
      return undefined;
    }

    if (!user.homeAddress.timezone) {
      return undefined;
    }

    return user.homeAddress.timezone || undefined;
  }

  startTimeLoop() {
    this.updateTime();

    const time = new Date();
    const secondsRemaining = (60 - time.getSeconds()) * 1000;

    setTimeout(() => {
      this.updateTime();

      setInterval(() => {
        this.updateTime();
      }, 60000);
    }, secondsRemaining + 100);
  }

  updateTime() {
    try {
      this.usersTime = moment().tz(this.userTimezone).format('H:mm a');
    } catch {
      this.usersTime = this.translateService.instant(CommonMessages.UNKNOWN);
    }
  }

  startCheckContactHeight() {
    setTimeout(() => this.getContactHeight(), 100);
  }

  getContactHeight() {
    if (this.aboutMe && this.aboutMe.nativeElement) {
      this.minHeightContact = this.aboutMe.nativeElement.clientHeight;
    }
  }

  initFormSite(site?: Site): FormControl {
    const formControl = new FormControl(null, []);

    if (site) {
      formControl.setValue(site);
    }

    formControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(val => {
        if (val) {
          this.onSiteChanged(val);
        }
      });

    return formControl;
  }

  initFormCity(city?: string): FormControl {
    const formControl = new FormControl(null, []);

    if (city) {
      formControl.setValue(city);
    }

    formControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(val => {
        if (val) {
          this.onCityChanged(val);
        }
      });

    return formControl;
  }

  onSiteChanged(site: Site): void {
    this.userService.updateUserOfficeLocation(site).subscribe(user => {
      this.notifyUtils.notify('Site updated');
      if (this.globals.user.id === user.id) {
        this.globals.user = user;
        this.userProfile = user;
      }
      this.getManager();
    });
  }

  onCityChanged(city: string): void {
    this.userService.updateUserCity(city).subscribe(user => {
      this.notifyUtils.notify('City updated');
      if (this.globals.user.id === user.id) {
        this.globals.user = user;
        this.userProfile = user;
      }
      this.getManager();
    });
  }

  getManager(): void {
    if (+this.userProfile.managerId === +this.userProfile.id) {
      this.showManagerLink = false;
      this.userProfile.managerName = 'No Manager';
    } else if (this.userProfile.managerId !== null) {
      this.userService.getById(this.userProfile.managerId).subscribe(manager => {
        this.showManagerLink = (manager.userState !== 'INVITED');
        this.userProfile.managerName = manager.firstName + ' ' + manager.lastName;
        this.userProfile.managerId = manager.id;
      });
    } else {
      this.showManagerLink = false;
    }
  }

  getPraise(): void {
    this.praiseComp.getPraise();
  }

  private populateUserDetails(): void {
    this.userProfile = this.globals.user;

    this.officeLocation = this.initFormSite(this.globals.user ? this.globals.user.officeLocation : null);
    this.city = this.initFormCity(this.globals.user ? this.globals.user.homeAddress.city : null);

    // making the date show up as Month Date
    this.userBirthday = (new Date(this.userProfile.dateOfBirth!).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    }));
  }

  toggleValue(value: PageTag) {
    value.selected = !value.selected;
  }

  // TODO: Make all these work properly
  getEmploymentTime() {
    return 345;
  }

  getCompanyName() {
    return 'Frankli';
  }

  getHighFives() {
    return 104;
  }

  getEndorsements() {
    return 69;
  }

  getPraiseCount() {
    return 89;
  }

  createOneToOne(user: User) {
    this.router.navigate(
      ['/one-to-one/create'],
      {
        queryParams: {
          userId: this.id
        }
      }
    );
  }

  // #region - PROFILE PRAISE
  initPraiseForm() {
    this.praiseForm = this.formBuilder.group({
      praise: new FormControl('', [Validators.required, FrankliValidators.softMinValidation(this.largeEditorMinLength), FrankliValidators.softMaxValidation(this.largeEditorMaxLengthSoft), Validators.maxLength(this.largeEditorMaxLengthHard)]),
    });

    this.praiseForm.controls.praise.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    });
  }

  startPraise(): void {
    this.initPraiseForm();
    this.praiseSubmitted = false;
    this.praiseModal.show();
  }

  cancelPraise(hideModal = true): void {
    this.praiseSubmitted = false;
    this.praiseForm.reset();

    if (hideModal) {
      this.praiseModal.hide();
    }
  }

  confirmPraise() {
    this.praiseSubmitted = true;
    if (this.praiseForm.valid) {
      const praise: CreatePraiseDto = {
        recipientId: this.userProfile.id,
        message: this.praiseForm.controls.praise.value,
        values: this.availableValues.filter(av => av.selected)
      };
      this.praiseAPIService.submitPraise(praise).subscribe(response => {
        this.notifyUtils.notifyIcon({
          icon: 'fal fa-thumbs-up',
          message: 'Praise successfully posted',
        }, {
          type: 'frankli-blue',
          placement: {
            from: 'top',
            align: 'right',
          },
        });
        this.praiseSubmitted = false;
        this.praiseForm.controls.praise.setValue('');
        this.availableValues.forEach(av => av.selected = false);
        this.getPraise();
        this.praiseModal.hide();
      });
    }
  }

  // #endregion

  doError(message: string) {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  compareSites(siteA: Site, siteB: Site): boolean {
    if (!siteA && !siteB) {
      return true;
    }
    if (!siteA) {
      return false;
    }
    if (!siteB) {
      return false;
    }
    return siteA.id === siteB.id;
  }
}
