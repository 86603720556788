<div class="container-sse-details-item" *ngIf="event">
  <div class="sse-details-heading">
    <h4 class="standard-heading" [ngSwitch]="event.type">
      <span *ngSwitchCase="eServerSideEventType.TEST_EVENT">Test event</span>
      <span *ngSwitchCase="eServerSideEventType.GOAL_DISTRIBUTION">Goal distribution</span>
      <span *ngSwitchDefault>Unknown</span>
    </h4>
    <ng-container *ngIf="highestProgress === 100">
      <div (click)="onClickRemoveEvent()">
        <app-table-action-icon [icon]="'fa-trash'" [tooltipText]="'Hide this job'" [hoverColor]="eIconHoverColor.RED" [boxSize]="'26px'" [iconSize]="'12px'"></app-table-action-icon>
      </div>
    </ng-container>
  </div>
  <div class="progress-event-bar">
    <app-progress-bar [currentValue]="highestProgress"></app-progress-bar>
  </div>
  <ng-container *ngIf="event.progressEvents && (event.progressEvents.length > 0)">
    <div class="progress-event-header" (click)="toggleDropdownOpen()">
      <span class="progress-event-header-dropdown-indicator">
        <app-table-dropdown-toggle [opened]="dropdownOpen" [size]="'18px'"></app-table-dropdown-toggle>
      </span>
      <span class="progress-event-header-text">{{dropdownOpen ? 'Hide' : 'Show'}} events</span>
      <span class="text-danger" *ngIf="eventHasErrors">
        <span class="m-right-5"></span>
        <span>(There were errors during this job)</span>
      </span>
    </div>
    <div class="progress-event-list" *ngIf="dropdownOpen">
      <div class="progress-event-list-inner">
        <ng-container *ngFor="let progressEvent of event.progressEvents">
          <app-sse-details-progress-event-item [progressEvent]="progressEvent"></app-sse-details-progress-event-item>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
