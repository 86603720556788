<router-outlet></router-outlet>

<ng-container *ngIf="route.children.length === 0">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <ng-container *ngIf="!archived; else stateArchived">
        <ng-container *ngIf="!state.loading">
          <ng-container [ngSwitch]="userProfile.userState">
            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="(userProfile.id === globals.user.id); else templateTopActionsOtherUserProfile;">
                <div class="text-right p-bottom-15" >
                  <a [routerLink]="['/settings']">
                    <span class="fal fa-fw fa-edit"></span>
                    <span class="m-right-5"></span>
                    <span translate>{{eProfileMessages.EDIT_PROFILE}}</span>
                  </a>
                </div>
              </ng-container>
              <ng-template #templateTopActionsOtherUserProfile>
                <div class="action-button-container" *ngIf="userProfile.id !== globals.user.id">
                  <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="startPraise()">
                      <span class="fal fa-bullhorn"></span>
                      <span class="m-right-5"></span>
                      <span>Give Praise</span>
                    </app-button>
                  </ng-container>
                  <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE) && !hasOneToOne">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="createOneToOne(userProfile)">
                      <span class="fal fa-calendar-plus"></span>
                      <span class="m-right-5"></span>
                      <span>Create 1:1</span>
                    </app-button>
                  </ng-container>
                  <ng-container *ngIf="((userProfile.managerId === globals.user.id) || globals.hasRole(eRoleName.GOAL_COMPANY) || (secondaryManager && secondaryManager.goals)) && globals.hasFeature(eCompanyFeatures.GOALS)">
                    <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="userGoalsModal.show()">
                      <span class="fal fa-bullseye"></span>
                      <span class="m-right-5"></span>
                      <span>View Goals</span>
                    </app-button>
                  </ng-container>
                </div>
              </ng-template>

              <div class="row">
                <!-- Left Col -->
                <div class="col-md-4 p-bottom-15">
                  <!-- About Me card -->
                  <div class="card" #aboutMeCard>
                    <div class="content">
                      <!-- Profile picture -->
                      <div class="image-container">
                        <span class="image-container-inner">
                          <span class="img-user">
                            <app-profile-photo [src]="userProfile.imageUrl" [width]="'140px'" [firstName]="userProfile.firstName" [lastName]="userProfile.lastName"></app-profile-photo>
                          </span>
                        </span>
                      </div>
    
                      <!-- Name -->
                      <div class="text-center">
                        <h3 class="standard-heading user-name">
                          <span class="text-capitalize">{{userProfile.firstName}} {{userProfile.lastName}}</span>
                          <span class="secondary-pill" *ngIf="secondaryManager">
                            <app-pill [type]="pillType">
                              <span>{{globals.getTerminology(eTerminologyEntity.SECONDARY)}}</span>
                            </app-pill>
                          </span>
                        </h3>
                      </div>
    
                      <!-- Description -->
                      <div class="info-box text-left">
                        <!-- TODO: Length limit and read more on overflow -->
                        <p class="user-description">
                          <app-rich-display [text]="userProfile.description"></app-rich-display>
                        </p>
                      </div>
                    </div>
                  </div>
    
                  <!-- Location and Work Info card -->
                  <div class="card">
                    <div class="content">
                      <h4 class="standard-heading">Location & Work Info</h4>
  
                      <div class="row">
                        <hr/>
                      </div>
    
                      <!-- Current time -->
                      <div class="frankli-form-group frankli-form-group-filled p-bottom-15" *ngIf="usersTime">
                        <label>
                          <span class="fal fa-fw fa-clock"></span>
                          <span class="m-right-5"></span>
                          <span>Current time</span>
                        </label>
                        <div class="p-left-25">
                          <h4 class="standard-heading font-500">{{usersTime}}</h4>
                        </div>
                      </div>
    
                      <!-- Working From -->
                      <div class="frankli-form-group frankli-form-group-filled p-bottom-15">
                        <label>
                          <span class="fal fa-fw fa-map-marker-alt"></span>
                          <span class="m-right-5"></span>
                          <span>Working from</span>
                        </label>
                        <div class="p-left-25">
                          <h4 class="standard-heading font-500">
                            <ng-container *ngIf="(globals.user.id !== userProfile.id); else selectLocation">
                              <ng-container *ngIf="(userProfile.officeLocation !== null); else noOfficeLocation">
                                <ng-container *ngIf="(userProfile.officeLocation.name !== 'Working remotely'); else workingRemotely">
                                  <div>{{userProfile.officeLocation.name}}</div>
                                </ng-container>
                                <ng-template #workingRemotely>
                                  <ng-container *ngIf="userProfile.homeAddress && userProfile.homeAddress.city; else noHomeAddress;">
                                    <div>{{userProfile.homeAddress.city}}</div>
                                  </ng-container>
                                  <ng-template #noHomeAddress>
                                    <span>Home</span>
                                  </ng-template>
                                </ng-template>
                              </ng-container>
                              <ng-template #noOfficeLocation>
                                <div>No {{globals.getTerminology(eTerminologyEntity.SITE) | titlecase}}</div>
                              </ng-template>
                            </ng-container>
                            <ng-template #selectLocation>
                              <div class="frankli-form-group frankli-form-group-filled p-bottom-15">
                                <label for="site" class="small-label">{{globals.getTerminology(eTerminologyEntity.SITE) | titlecase}}</label>
                                <select name="site" class="form-control" [disabled]="!!scimUser"
                                  [tooltip]="scimUser ? 'Your ' + globals.getTerminology(eTerminologyEntity.SITE) + ' is managed through Active Directory' : ''"
                                  [formControl]="officeLocation" [compareWith]="compareSites"
                                  angulartics2On="click" angularticsAction="ChangedOfficeLoc"
                                  angularticsCategory="Profile">
                                  <option [ngValue]="null">No {{globals.getTerminology(eTerminologyEntity.SITE) | titlecase}}</option>
                                  <option *ngFor="let location of officeLocations" [ngValue]="location">{{location.name}}</option>
                                </select>
                              </div>
                              <div class="frankli-form-group frankli-form-group-filled">
                                <label for="city" class="small-label">City</label>
                                <input type="text" name="city" class="form-control" [formControl]="city"
                                  angulartics2On="click" angularticsAction="ChangedCity"
                                  angularticsCategory="Profile"/>
                              </div>
                            </ng-template>
                          </h4>
                        </div>
                      </div>
    
                      <!-- Department -->
                      <div class="frankli-form-group frankli-form-group-filled p-bottom-15">
                        <label>
                          <span class="fal fa-fw fa-briefcase"></span>
                          <span class="m-right-5"></span>
                          <span>{{globals.getTerminology(eTerminologyEntity.DEPARTMENT) | titlecase}}</span>
                        </label>
                        <div class="p-left-25">
                          <h4 class="standard-heading font-500">{{(userProfile.organisationalUnit !== null) ? (userProfile.organisationalUnit.name) : ('No ' + globals.getTerminology(eTerminologyEntity.DEPARTMENT)) | titlecase}}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        
                <!-- Right Col -->
                <div class="col-md-8 p-bottom-15">
                  <!-- Contact details & interests card -->
                  <div class="card" [style.min-height.px]="minHeightContact">
                    <app-profile-contact-interests [(userProfile)]="userProfile"></app-profile-contact-interests>
                  </div>
  
                  <!-- Praise card -->
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                    <app-profile-praise #praiseComp [(userProfile)]="userProfile" (clickGivePraise)="startPraise()"></app-profile-praise>
                  </div>
    
                  <!-- Goals -->
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.GOALS)">
                    <app-profile-goals [(userProfile)]="userProfile"></app-profile-goals>
                  </div>
  
                  <!-- Qualifications -->
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.QUALIFICATIONS)">
                    <app-profile-qualifications [user]="userProfile"></app-profile-qualifications>
                  </div>
    
                  <!-- Development -->
                  <!-- <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.CONNECT)">
                    <app-profile-development [(userProfile)]="userProfile"></app-profile-development>
                  </div> -->

                  <!-- 1:1s -->
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE) && globals.hasMinimumAccessLevelOf(eRoleName.ADMIN)">
                    <app-profile-one-to-ones [user]="userProfile"></app-profile-one-to-ones>
                  </div>

                  <!-- Absorb certificates -->
                  <div class="card" *ngIf="globals.hasFeature(eCompanyFeatures.ABSORBLMS)">
                    <app-profile-absorblms-certificates [user]="userProfile"></app-profile-absorblms-certificates>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="eUserState.PENDING">
              <div class="card" >
                <div class="content">
                  <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has not been invited to Frankli yet'"></app-empty-state>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="eUserState.INVITED">
              <div class="card">
                <div class="content">
                  <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has not completed sign up yet'"></app-empty-state>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
  
      <ng-template #stateArchived>
        <div class="card">
          <div class="content">
            <app-empty-state [height]="'300px'" [imageSrc]="'assets/img/UserNotSignedUp.svg'" [message]="(userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person') + ' has been archived'"></app-empty-state>
          </div>
        </div>
      </ng-template>

      <app-modal (modalHidden)="cancelPraise(false)" [width]="'90%'" [minWidth]="'100px'" [maxWidth]="'800px'" #praiseModal>
        <div class="praise-modal-inner">
          <div class="p-bottom-15 text-center">
            <h1 class="standard-heading">{{titleText}}</h1>
          </div>
    
          <ng-container *ngIf="praiseModal.visible">
            <div class="p-bottom-15">
              <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="praiseSubmitted && praiseForm.controls.praise.invalid">
                <app-editor-response [formControl]="praiseForm.controls.praise" [enforceCharacterLimit]="false" [columnIndent]="false" [showErrorBorder]="false" [toolbar]="editorToolbar" [height]="200" [maxCharacters]="largeEditorMaxLengthSoft" [minlength]="largeEditorMinLength" [placeholder]="'Please write here (Max ' + largeEditorMaxLengthSoft + ' characters)'"></app-editor-response>
              </div>
      
              <div class="frankli-form-group-alert">
                <ng-container *ngIf="praiseSubmitted">
                  <div *ngIf="praiseForm.controls.praise.hasError('required')">Please provide a little detail here</div>
                  <div *ngIf="praiseForm.controls.praise.hasError('softmin')">Your submission must be at least {{largeEditorMinLength}} characters</div>
                </ng-container>
                <div *ngIf="praiseForm.controls.praise.hasError('softmax')">Your submission must be {{largeEditorMaxLengthSoft}} characters or fewer</div>
                <div *ngIf="praiseForm.controls.praise.hasError('maxlength')">Too much formatting</div>
              </div>
            </div>
    
            <div class="p-bottom-15">
              <div class="p-bottom-10 text-center">
                <h5 class="standard-heading">Which company values did they demonstrate?</h5>
              </div>
              <div class="area-praise-values">
                <ng-container *ngIf="availableValues.length > 0; else templateNoPraiseValues;">
                  <div class="area-praise-values-list">
                    <ng-container *ngFor="let value of availableValues">
                      <span class="praise-value-item" [class.praise-value-item-selected]="value.selected"  (click)="toggleValue(value)">{{value.text}}</span>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #templateNoPraiseValues>
                  <p>No values found</p>
                </ng-template>
              </div>
            </div>
    
            <div class="area-praise-buttons p-top-15">
              <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelPraise()">Cancel</app-button>
              <app-button [type]="eButtonType.GREEN" (onClick)="confirmPraise()">Submit</app-button>
            </div>
          </ng-container>
        </div>
      </app-modal>
    
      <app-user-goals-modal #userGoalsModal [owner]="userProfile"></app-user-goals-modal>
    </ng-container>
  </app-state>
</ng-container>
