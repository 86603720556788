import {appVersion} from './version';

export const environment = {
  production: true,
  websocketUrl: 'wss://app.frankli.io/livesync',
  apiUrl: 'https://app.frankli.io/api',
  sso: {
    redirectUri: 'https://app.frankli.io/callback',
    logoutUri: 'https://app.frankli.io',
    microsoft: {
      clientId: '1fe6f42d-1209-4c2b-8548-e0f07fca8768',
    },
    google: {
      clientId: '995821072743-jv6o94fk2ipgva5bljet1it2aie9ftd7.apps.googleusercontent.com',
    }
  },
  sentry: {
    enabled: true,
    environment: 'beta',
    key: 'https://c07fba51f1fb4eb1903d83f2551e07d2@sentry.io/1231287',
  },
  intercom: {
    id: 'yrb9n1sk',
    enabled: true
  },
  vincere: {
    redirectUri: 'https://app.frankli.io/callback-vincere'
  },
  zendesk: {
    redirectUri: 'https://app.frankli.io/callback-zendesk',
    clientId: 'zdg-frankli-io'
  },
  password: {
    minimumLength: 8,
    maximumLength: 100
  },
  integrations: {
    slack: {
      enabled: true
    }
  },
  mock: {
    enabled: false,
    apiUrl: 'https://app.frankli.io'
  },
  version: appVersion,
  baseUrl: 'https://app.frankli.io'
};
