import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SSE } from 'sse.js';
import { ServerSideEventProgress } from '../model/server-side-event-progress.model';
import { SseProgressService } from './sse-progress.service';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  eventSource: SSE;

  constructor(
    private sseProgressService: SseProgressService
  ) { }

  /**
   * Create an event source of POST request
   * @param API url 
   * @formData data (file, ...etc.)
   */
  public getEventSourceWithPost(url: string, formData: FormData): SSE {
    return this.buildEventSource(url, 'POST', formData);
  }

  /**
   * Create an event source of GET request
   * @param API url 
   * @formData data (file, ...etc.)
   */
  public getEventSourceWithGet(url: string, formData: FormData): SSE {
    return this.buildEventSource(url, 'GET', formData);
  }

  public generateProgressEventMonitor(url: string): Observable<ServerSideEventProgress> {
    return new Observable<ServerSideEventProgress>((observer) => {
      const eventSource = this.getEventSourceWithGet(url, null);

      // on answer from progress listener 
      eventSource.onmessage = eventMessage => {
        const eventMessageJson: ServerSideEventProgress = JSON.parse(eventMessage.data);
        observer.next(eventMessageJson);
        this.sseProgressService.addProgressEvent(eventMessageJson.guid, eventMessageJson);

        // PROGRESS EVENTS GET FIRED HERE
        eventSource.addEventListener(eventMessageJson.guid, (eventGuid) => {
          const eventGuidJson: ServerSideEventProgress = JSON.parse(eventGuid.data);
          observer.next(eventGuidJson);
          this.sseProgressService.addProgressEvent(eventGuidJson.guid, eventGuidJson);
        });
      };

      eventSource.onerror = eventError => {
        // TODO: Pass to http error interceptor?
        const eventErrorJson = JSON.parse(eventError.data);
        observer.error(eventErrorJson);
        this.sseProgressService.addProgressEvent(eventErrorJson.guid, eventErrorJson);
      };

      eventSource.stream();

      return () => {
        eventSource.close();
      };
    });
  }

  /**
   * Building the event source
   * @param url  API URL
   * @param meth  (POST, GET, ...etc.)
   * @param formData data
   */
  private buildEventSource(url: string, meth: string, formData: FormData): SSE {
    const options = this.buildOptions(meth, formData);
    this.eventSource = new SSE(url, options);
    
    // add listener
    this.eventSource.addEventListener('message', (e) => {
      return e.data;
    });

    return  this.eventSource;
  }

  /**
   * close connection
   */
  public closeEventSource(eventSource: SSE) {
    if (eventSource) {
      eventSource.close();
    }
  }

  protected checkAuthorization(): string {
    const authToken = localStorage.getItem('access_token');
    const auth = 'Bearer ' + authToken;
    return auth;
  }

  /**
   * Build query options
   * @param meth POST or GET
   * @param formData data
   */
  private buildOptions(
    meth: string,
    formData: FormData,
  ): {
    payload: FormData;
    method: string;
    headers: string | { [key: string]: string };
  } {
    let headers: { [key: string]: string } = {
      accept: 'application/json'
      // accept: 'multipart/form-data'
    };

    const auth = this.checkAuthorization();
    if (auth) {
      headers = {
        ...headers,
        Authorization: auth
      };
    }
    return {
      payload: formData,
      method: meth,
      headers: headers,
    };
  }
}
