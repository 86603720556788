import { Component, Input } from '@angular/core';
import { ServerSideEventProgress } from '../../model/server-side-event-progress.model';

@Component({
  selector: 'app-sse-details-progress-event-item',
  templateUrl: './sse-details-progress-event-item.component.html',
  styleUrls: ['./sse-details-progress-event-item.component.scss']
})
export class SseDetailsProgressEventItemComponent {

  @Input() progressEvent: ServerSideEventProgress;

  constructor() {
    this.progressEvent = undefined;
  }
}
