import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SurveyFormComponentsModule} from '@app/shared/survey-components/survey-form-components.module';
import {RouterModule} from '@angular/router';
import {PipesModule} from 'app/shared/pipes/pipes.module';
import {SharedProfileModule} from 'app/shared/profile/shared-profile.module';
import {Angulartics2Module} from 'angulartics2';
import {SharedModule} from 'app/shared/shared.module';

import {EmployeeComponent} from './employee.component';
import {PeopleDirectoryComponent} from './people-directory/people-directory.component';
import {OrgChartComponent} from './org-chart/org-chart.component';
import {PaginationModule} from '@app/shared/pagination/pagination.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TranslateModule} from '@ngx-translate/core';

const components = [
  EmployeeComponent,
  PeopleDirectoryComponent,
  OrgChartComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SurveyFormComponentsModule,
    RouterModule,
    PipesModule,
    SharedProfileModule,
    Angulartics2Module,
    SharedModule,
    PaginationModule,
    ScrollingModule,
    TranslateModule
  ],
  declarations: components,
  exports: components
})
export class EmployeeComponentsModule { }
