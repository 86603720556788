import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import {SharedProfileModule} from 'app/shared/profile/shared-profile.module';
import {Angulartics2Module} from 'angulartics2';

@NgModule({
    imports: [ RouterModule, CommonModule, Angulartics2Module ],
    declarations: [ FooterComponent ],
    exports: [ FooterComponent ]
})

export class FooterModule {}
