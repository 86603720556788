<div class="container-sidebar-reviews">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="schedule.evaluationCycleId && hasData; else templateNoData;">
        <div class="container-button-group">
          <app-button-group [formControl]="controlTab" [options]="buttonGroupOptions" [style]="'dark'" [consistentWidths]="true"></app-button-group>
        </div>

        <div class="container-response-preview">
          <ng-container [ngSwitch]="controlTab.value">
            <!-- TODO: Add a generic component for displaying these responses instead of duplicating code between these tabs -->

            <ng-container *ngSwitchCase="eReviewTabType.SELF_REFLECTION">
              <ng-container *ngIf="selfReflection && selfReflection.completed; else noSelfReflection">
                <div class="area-question-list">
                  <ng-container *ngFor="let questionAnswered of selfReflection.selfQuestionsAnswered; index as i">
                    <div class="area-question-outer">
                      <div class="area-question-text">
                        <span>{{ i + 1 }}.</span>
                        <span class="m-right-5"></span>
                        <span>{{ questionAnswered.question.questionText }}</span>
                        <ng-container *ngIf="questionAnswered.question.questionBankQuestion.assessmentQuestion">
                          <span class="m-right-5"></span>
                          <app-pill>Assessment question</app-pill>
                        </ng-container>
                      </div>
                      <div class="area-question-response">
                        <ng-container [ngSwitch]="questionAnswered.question.questionBankQuestion.surveyQuestionType">
                          <ng-container *ngSwitchCase="eSurveyQuestionType.DYNAMIC_SCALE">
                            <app-dynamic-scale [display]="true" [ngModel]="questionAnswered.textAnswer" [options]="questionAnswered.question.questionBankQuestion.possibleAnswers"></app-dynamic-scale>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE">
                            <span>
                              <ng-container [ngSwitch]="questionAnswered.textAnswer">
                                <div *ngSwitchCase="'1'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'2'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'3'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.NEUTRAL | translate}}:</div>
                                <div *ngSwitchCase="'4'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.AGREE | translate}}:</div>
                                <div *ngSwitchCase="'5'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_AGREE | translate}}:</div>
                                <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
                              </ng-container>
                            </span>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE_COMMENT">
                            <span>
                              <ng-container [ngSwitch]="questionAnswered.textAnswer">
                                <div *ngSwitchCase="'1'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'2'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'3'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.NEUTRAL | translate}}:</div>
                                <div *ngSwitchCase="'4'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.AGREE | translate}}:</div>
                                <div *ngSwitchCase="'5'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_AGREE | translate}}:</div>
                                <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
                                <div><b>{{eCommonMessages.COMMENT | translate}}: </b>{{questionAnswered.comment}}
                                </div>
                              </ng-container>
                            </span>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.TEXT">
                            <app-rich-display [text]="questionAnswered.textAnswer"></app-rich-display>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_FOUR">
                            <div>{{getAnswerForScale(eEvaluationModuleType.SELF_REFLECTION, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_THREE">
                            <div>{{getAnswerForScale(eEvaluationModuleType.SELF_REFLECTION, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_FIVE">
                            <div>{{getAnswerForScale(eEvaluationModuleType.SELF_REFLECTION, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-template #noSelfReflection>
                <app-empty-state [height]="'200px'" [message]="'No self reflection'"></app-empty-state>
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="eReviewTabType.MANAGER_REVIEW">
              <ng-container *ngIf="managerReview && managerReview.request.state === eEvaluationFeedbackRequestState.COMPLETED; else noManagerReview">
                <div class="area-question-list">
                  <ng-container *ngFor="let questionAnswered of managerReview.questionsAnswered; index as i">
                    <div class="area-question-outer">
                      <div class="area-question-text">
                        <span>{{ i + 1 }}.</span>
                        <span class="m-right-5"></span>
                        <span>{{ questionAnswered.question.questionText }}</span>
                        <ng-container *ngIf="questionAnswered.question.questionBankQuestion.assessmentQuestion">
                          <span class="m-right-5"></span>
                          <app-pill>Assessment question</app-pill>
                        </ng-container>
                      </div>
  
                      <div class="area-question-response">
                        <ng-container [ngSwitch]="questionAnswered.question.questionBankQuestion.surveyQuestionType">
                          <ng-container *ngSwitchCase="eSurveyQuestionType.DYNAMIC_SCALE">
                            <app-dynamic-scale [display]="true" [ngModel]="questionAnswered.textAnswer" [options]="questionAnswered.question.questionBankQuestion.possibleAnswers"></app-dynamic-scale>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE">
                            <span>
                              <ng-container [ngSwitch]="questionAnswered.textAnswer">
                                <div *ngSwitchCase="'1'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'2'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'3'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.NEUTRAL | translate}}:</div>
                                <div *ngSwitchCase="'4'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.AGREE | translate}}:</div>
                                <div *ngSwitchCase="'5'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_AGREE | translate}}:</div>
                                <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
                              </ng-container>
                            </span>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.LIKERT_SCALE_COMMENT">
                            <span>
                              <ng-container [ngSwitch]="questionAnswered.textAnswer">
                                <div *ngSwitchCase="'1'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'2'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.DISAGREE | translate}}:</div>
                                <div *ngSwitchCase="'3'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.NEUTRAL | translate}}:</div>
                                <div *ngSwitchCase="'4'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.AGREE | translate}}:</div>
                                <div *ngSwitchCase="'5'"><b>{{eCommonMessages.ANSWER | translate}}:
                                  </b>{{eCommonMessages.STRONGLY_AGREE | translate}}:</div>
                                <div *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</div>
                                <div><b>{{eCommonMessages.COMMENT | translate}}: </b>{{questionAnswered.comment}}
                                </div>
                              </ng-container>
                            </span>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.TEXT">
                            <app-rich-display [text]="questionAnswered.textAnswer"></app-rich-display>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_FOUR">
                            <div>{{getAnswerForScale(eEvaluationModuleType.MANAGER_REVIEW, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_THREE">
                            <div>{{getAnswerForScale(eEvaluationModuleType.MANAGER_REVIEW, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                          <ng-container *ngSwitchCase="eSurveyQuestionType.ONE_TO_FIVE">
                            <div>{{getAnswerForScale(eEvaluationModuleType.MANAGER_REVIEW, questionAnswered.question, questionAnswered.textAnswer)}}</div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-template #noManagerReview>
                <app-empty-state [height]="'200px'" [message]="'No manager review'"></app-empty-state>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-reviews.svg'" [message]="'No reviews'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
