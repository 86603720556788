import { Component } from '@angular/core';
import { ServerSideEventData, SseProgressService } from '../../service/sse-progress.service';

@Component({
  selector: 'app-sse-details-overview',
  templateUrl: './sse-details-overview.component.html',
  styleUrls: ['./sse-details-overview.component.scss']
})
export class SseDetailsOverviewComponent {

  get events(): ServerSideEventData[] {
    return this.sseProgressService.events;
  }

  constructor(
    public sseProgressService: SseProgressService
  ) { }
}
