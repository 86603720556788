import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EvaluagentAPIService } from '@app/domain/evaluagent/api/evaluagent-api.service';
import { EvaluagentEvaluationObject } from '@app/domain/evaluagent/model/evaluagent-evaluation-object.model';
import { EvaluagentScorecardAverage } from '@app/domain/evaluagent/model/evaluagent-scorecard-average.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';

interface PageEvaluagentScorecardAverage extends EvaluagentScorecardAverage {
  evaluations: EvaluagentEvaluationObject[];
  passRate: number;
}
@Component({
  selector: 'app-evaluagent-sidebar',
  templateUrl: './evaluagent-sidebar.component.html',
  styleUrls: ['./evaluagent-sidebar.component.scss']
})
export class EvaluagentSidebarComponent implements OnInit {

  @Input() user: UserMinimal;

  state: IState;
  scorecards: PageEvaluagentScorecardAverage[];

  oldestDate: Date;
  controlDateRangePicker: FormControl;

  get viewingOwnUserId(): boolean {
    if (!this.user) { return false; }
    if (!this.globals.user) { return false; }
    if (this.globals.user.id !== this.user.id ) { return false; }
    return true;
  }

  constructor(
    public globals: Globals,
    private evaluagentApiService: EvaluagentAPIService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
    this.scorecards = [];
    this.oldestDate = undefined;
    this.controlDateRangePicker = this.initDateRangeForm();
  }

  ngOnInit(): void {
    this.populateYearPicker().then(() => {
      this.getData(this.controlDateRangePicker.value.start, new Date());
    });
  }

  populateYearPicker(): Promise<Date> {
    return this.evaluagentApiService.getOldestEvaluationPublishDate()
      .toPromise()
      .then(oldestDate => {
        this.state.loading = true;

        this.oldestDate = oldestDate;
        this.controlDateRangePicker = this.initDateRangeForm(oldestDate);

        return oldestDate;
      });
  }

  getData(startDate: Date, endDate: Date): void {
    this.evaluagentApiService.getEvaluationsByUserId(this.user.id, startDate, endDate)
      .toPromise()
      .then(evaluations => {
        evaluations = evaluations.filter(evaluation => evaluation.scorecard !== 'Complaints Scorecard');
        this.scorecards = this.parseEvaluationsToScorecards(evaluations);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  parseEvaluationsToScorecards(evaluations: EvaluagentEvaluationObject[]): PageEvaluagentScorecardAverage[] {
    const evaluationsGroupedByScorecard: {
      [scorecard: string]: {
        evaluations: EvaluagentEvaluationObject[],
        totalScore: number,
        passedCount: number
      }
    } = {};

    evaluations.forEach(evaluation => {
      if (!evaluationsGroupedByScorecard[evaluation.scorecard]) {
        evaluationsGroupedByScorecard[evaluation.scorecard] = {
          evaluations: [],
          totalScore: 0,
          passedCount: 0
        };
      }

      evaluationsGroupedByScorecard[evaluation.scorecard].evaluations.push(evaluation);
      evaluationsGroupedByScorecard[evaluation.scorecard].totalScore += +evaluation.qualityScore;
      if (evaluation.outcomeName === 'PASS') {
        evaluationsGroupedByScorecard[evaluation.scorecard].passedCount++;
      }
    });

    const uniqueScorecards = Object.keys(evaluationsGroupedByScorecard);
    const scorecards: PageEvaluagentScorecardAverage[] = uniqueScorecards.map(scorecard => {
      const scorecardObject = evaluationsGroupedByScorecard[scorecard];

      if (!scorecardObject) { return; }

      const averageScore = (scorecardObject.totalScore / scorecardObject.evaluations.length);

      const pageScorecard: PageEvaluagentScorecardAverage = {
        scorecard: scorecard,
        averageQualityScore: averageScore,
        totalEvaluations: scorecardObject.evaluations.length,
        evaluations: this.sortEvaluations(scorecardObject.evaluations),
        passRate: (scorecardObject.passedCount / scorecardObject.evaluations.length) * 100
      };

      return pageScorecard;
    });

    return scorecards;
  }

  sortEvaluations(evaluationsInput: EvaluagentEvaluationObject[]): EvaluagentEvaluationObject[] {
    // Sort by scorecard
    // return evaluationsInput.sort((a, b) => a.scorecard.localeCompare(b.scorecard));
    // Sort by publish date
    return evaluationsInput.sort((a, b) => {
      const dateA = new Date(a.publishedAt);
      const dateB = new Date(b.publishedAt);
      return dateB.getTime() - dateA.getTime();
    });
  }

  initDateRangeForm(oldestDate?: Date): FormControl {
    // Default date should be the past 180 days
    let defaultStartDate: Date = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 180);

    // If the oldest date is after the default start date, use the oldest date instead for the range start
    if (oldestDate && oldestDate > defaultStartDate) {
      defaultStartDate = oldestDate;
    }

    const formControl = new FormControl({
      start: defaultStartDate,
      end: new Date(),
      label: 'Default Range'
    }, []);

    formControl.valueChanges
      .subscribe(newValue => {
        this.getData(newValue.start, newValue.end);
      });

    return formControl;
  }
}
