import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ZendeskUserStatus } from '@app/models/integrations/zendesk/zendesk-user-status.model';
import { IState } from '@app/models/state/state.model';
import { ZendeskAPIService } from '@app/shared/api/zendesk.api.service';
import { Subject, forkJoin } from 'rxjs';
import moment from 'moment';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';

@Component({
  selector: 'app-information-sidebar-zendesk',
  templateUrl: './information-sidebar-zendesk.component.html',
  styleUrls: ['./information-sidebar-zendesk.component.scss']
})
export class InformationSidebarZendeskComponent implements OnInit {

  private readonly ngUnsubscribe$ = new Subject<void>();

  @Input() userIds: number[];
  @Input() schedule: OneToOneScheduleDetailsView;

  userIdUsing: number;

  dates: Date[];
  userStatus: ZendeskUserStatus;

  controlMonth: FormControl;
  state: IState;
  
  csatScore: number;
  csatScorePrevious: number;
  ticketsSolved: number;

  constructor(
    private zendeskAPIService: ZendeskAPIService
  ) {
    this.userIds = [];
    this.schedule = undefined;

    this.dates = [];
    this.userStatus = undefined;
    this.userIdUsing = undefined;
    this.csatScore = 0;
    this.csatScorePrevious = 0;
    this.ticketsSolved = 0;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.controlMonth = this.initForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getData(): void {
    const userId = this.getQueryUserId(this.schedule, this.userIds);
    this.userIdUsing = userId;
    if (!userId) { return; }

    forkJoin([
      this.zendeskAPIService.getUserStatus(userId),
      this.zendeskAPIService.getOldestTicketForUser(userId)
    ])
      .toPromise()
      .then(([userStatus, oldestTicketDate]) => {
        this.userStatus = userStatus;

        this.dates = this.getDatesBetweenTimestampAndNow(oldestTicketDate);

        if (this.dates.length === 0) {
          this.state.loading = false;
          return;
        }

        this.controlMonth.setValue(this.dates[this.dates.length - 1]); // Default to the latest date
      });
  }

  getQueryUserId(schedule: OneToOneScheduleDetailsView, userIds: number[]): number {
    if (schedule) {
      if (schedule.participants.length === 1) {
        return schedule.participants[0].id;
      }
    } {
      return userIds[0];
    }
  }

  // Make a list of each month/year combo between the oldest ticket and now
  getDatesBetweenTimestampAndNow(oldestTicketDate: Date): Date[] {
    const oldestTicketMoment = moment(oldestTicketDate);
    const now = moment();

    const dates = [];
    const current = oldestTicketMoment.clone();
    while (current.isSameOrBefore(now)) {
      dates.push(current.toDate());
      current.add(1, 'month');
    }
    return dates;
  }

  initForm(): FormControl {
    const formControl = new FormControl(null, []);

    formControl.valueChanges.subscribe(date => this.requestMetrics(date));

    return formControl;
  }

  requestMetrics(date: Date): void {
    if (date === null) { return; }

    this.state.loading = true;

    const userId = this.userIdUsing;

    // In the past 90 days from today
    // eg. If it's 20th March, the period start should be the end of the 20th December and the end of the 20th March
    const periodEnd = moment(date).endOf('day').toDate();
    const periodStart = moment(periodEnd).subtract(90, 'days').startOf('day').toDate();

    // In the 90 days before that
    // eg. If it's the 20th March, the period start should be the end of the 20th September and the end of the 20th December
    const periodEndPrevious = moment(periodStart).subtract(1, 'day').endOf('day').toDate();
    const periodStartPrevious = moment(periodEndPrevious).subtract(90, 'days').startOf('day').toDate();

    forkJoin([
      this.zendeskAPIService.getCsatScoreForUser(userId, periodStart, periodEnd),
      this.zendeskAPIService.getCsatScoreForUser(userId, periodStartPrevious, periodEndPrevious),
      this.zendeskAPIService.getTicketsSolvedForUser(userId, periodStart, periodEnd),
    ])
      .toPromise()
      .then(([csatScore, csatScorePrevious, ticketsSolved]) => {
        this.csatScore = csatScore;
        this.csatScorePrevious = csatScorePrevious;
        this.ticketsSolved = ticketsSolved;
        this.state.loading = false;
      });
  }

}
