import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbsorbLmsAPIService } from '@app/domain/absorblms/api/absorblms-api.service';
import { AbsorbLmsCertificate } from '@app/domain/absorblms/model/absorblms-certificate.model';
import { AbsorbLmsEnrollmentStatus } from '@app/domain/absorblms/model/absorblms-enrollment-status.model';
import { AbsorbLmsEnrollment } from '@app/domain/absorblms/model/absorblms-enrollment.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { Globals } from '@app/shared/globals/globals';

@Component({
  selector: 'app-absorblms-sidebar',
  templateUrl: './absorblms-sidebar.component.html',
  styleUrls: ['./absorblms-sidebar.component.scss']
})
export class AbsorblmsSidebarComponent implements OnInit {

  public static readonly eAbsorbLmsEnrollmentStatus = AbsorbLmsEnrollmentStatus;

  @Input() user: UserMinimal;

  state: IState;
  enrollments: AbsorbLmsEnrollment[];

  formGroup: FormGroup;

  dataEnrollmentStatuses: AbsorbLmsEnrollmentStatus[];

  get controlStatus(): FormControl {
    return this.formGroup.controls.status as FormControl;
  }

  get viewingOwnUserId(): boolean {
    if (!this.user) { return false; }
    if (!this.globals.user) { return false; }
    if (this.globals.user.id !== this.user.id ) { return false; }
    return true;
  }

  constructor(
    public globals: Globals,
    private absorbLmsApiService: AbsorbLmsAPIService
  ) {
    this.enrollments = [];
    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.dataEnrollmentStatuses = Object.values(AbsorbLmsEnrollmentStatus);
    this.formGroup = this.initForm(null);
  }

  ngOnInit(): void {
    this.getData(this.controlStatus.value);
  }

  initForm(status: AbsorbLmsEnrollmentStatus): FormGroup {
    const formGroup = new FormGroup({
      status: new FormControl(null)
    });

    if (status) {
      formGroup.controls.status.setValue(status, { emitEvent: false });
    }

    formGroup.valueChanges.subscribe(val => {
      this.getData(val.status);
    });

    return formGroup;
  }

  getData(status: AbsorbLmsEnrollmentStatus): void {
    if (!this.user) { return; }
    if (!this.user.id) { return; }

    this.absorbLmsApiService.getDataForUser(this.user.id)
      .toPromise()
      .then(userData => {
        this.dataEnrollmentStatuses = this.getUniqueEnrollmentStatusesFromUserData(userData.enrollments);
        this.enrollments = this.filterEnrollments(userData.enrollments, status);
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  getUniqueEnrollmentStatusesFromUserData(enrollments: AbsorbLmsEnrollment[]): AbsorbLmsEnrollmentStatus[] {
    const statuses: AbsorbLmsEnrollmentStatus[] = [];
    enrollments.forEach(enrollment => {
      if (!statuses.includes(enrollment.status)) {
        statuses.push(enrollment.status);
      }
    });
    return statuses;
  }
  
  filterEnrollments(enrollments: AbsorbLmsEnrollment[], status: AbsorbLmsEnrollmentStatus): AbsorbLmsEnrollment[] {
    if (!status) { return enrollments; }
    return enrollments.filter(enrollment => enrollment.status === status);
  }

  openAbsorb(): void {
    const url = 'https://myabsorb.eu/';
    window.open(url, '_blank');
  }
}
