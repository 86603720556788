import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SseProgressService } from '../service/sse-progress.service';
import { SseProgressBarComponent } from '../component/sse-progress-bar/sse-progress-bar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SseDetailsItemComponent } from '../component/sse-details-item/sse-details-item.component';
import { SseDetailsOverviewComponent } from '../component/sse-details-overview/sse-details-overview.component';
import { SseDetailsProgressEventItemComponent } from '../component/sse-details-progress-event-item/sse-details-progress-event-item.component';

const components = [
  SseProgressBarComponent,
  SseDetailsOverviewComponent,
  SseDetailsItemComponent,
  SseDetailsProgressEventItemComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    TooltipModule,
    SharedModule
  ],
  providers: [
    SseProgressService
  ]
})
export class ServerSideEventModule { }
