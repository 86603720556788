<div class="container-absorblms-sidebar">
  <div class="panel-heading">
    <div class="sidebar-content-title">
      <img src="assets/img/integrations/absorblms.svg" title="Absorb LMS" alt="Absorb LMS logo" (click)="openAbsorb()"/>
      <span class="m-right-10"></span>
      <ng-container *ngIf="viewingOwnUserId; else templateViewingOthersData;">
        <span>Your enrollments</span>
      </ng-container>
      <ng-template #templateViewingOthersData>
        <span>{{user ? user.firstName + '\'s' : 'Their' }} enrollments</span>
      </ng-template>
    </div>
  </div>

  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <div class="area-filters p-bottom-15">
        <div class="frankli-form-group frankli-form-group-filled">
          <label for="enrollmentStatus">Status</label>
          <select id="enrollmentStatus" class="form-control" [formControl]="controlStatus">
            <option [ngValue]="null">Show all</option>
            <ng-container *ngFor="let enrollmentStatus of dataEnrollmentStatuses">
              <option [ngValue]="enrollmentStatus">{{enrollmentStatus | underscoreToSpace | titlecase}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <!-- <div class="certificates-list">
        <ng-container *ngIf="certificates.length > 0; else templateNoCertificates;">
          <ng-container *ngFor="let certificate of certificates">
            <app-absorblms-certificate-display [certificate]="certificate"></app-absorblms-certificate-display>
          </ng-container>
        </ng-container>
        <ng-template #templateNoCertificates>
          <app-empty-state [height]="'200px'" [message]="'No certificates found'"></app-empty-state>
        </ng-template>
      </div> -->
    
      <div class="enrollments-list">
        <ng-container *ngIf="enrollments.length > 0; else templateNoEnrollments;">
          <ng-container *ngFor="let enrollment of enrollments">
            <app-absorblms-enrollment-display [enrollment]="enrollment"></app-absorblms-enrollment-display>
          </ng-container>
        </ng-container>
        <ng-template #templateNoEnrollments>
          <app-empty-state [height]="'200px'" [message]="'No enrollments found'"></app-empty-state>
        </ng-template>
      </div>
    </ng-container>
  </app-state>
</div>