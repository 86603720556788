import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Competency } from '../model/competency.model';
import { CreateCompetencyDto } from '../model/create-competency.model';
import { ICompetencyAPI } from './competency-api.model';

@Injectable()
export class CompetencyAPIService implements ICompetencyAPI {
  private readonly BASE_URL = 'api/competency';

  constructor(
      private http: HttpClient
  ) {
  }

  get(ids: number[], name: string, categories: string[]): Observable<Competency[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (categories) {
      categories.forEach(category => {
        params = params.append('category', category);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Competency[]>(url, { params: params });
  }

  create(competency: CreateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Competency>(url, competency);
  }

  update(id: number, competency: CreateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Competency>(url, competency);
  }

  delete(id: number): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Competency>(url);
  }

  deleteMultiple(competencyIds: number[]): Observable<Competency[]> {
    const url = `${this.BASE_URL}/delete`;
    return this.http.post<Competency[]>(url, competencyIds);
  }

  /**
   * Imports file with
   * @param file CSV files in the format 'Category','Name','Description',
   * @returns Competency[]
   */
  importCompetencies(file: File): Observable<Competency[]> {
    const url = `${this.BASE_URL}/import`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Competency[]>(url, formData);
  }

  downloadCSV(): Observable<Blob> {
    const url = `${this.BASE_URL}/download-csv`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
