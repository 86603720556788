<div class="container-evaluagent-sidebar">
  <div class="panel-heading">
    <div class="sidebar-content-title">
      <img src="assets/img/integrations/evaluagent.svg" />
      <span class="m-right-10"></span>
      <ng-container *ngIf="viewingOwnUserId; else templateViewingOthersData;">
        <span>Your EvaluAgent stats</span>
      </ng-container>
      <ng-template #templateViewingOthersData>
        <span>{{user ? user.firstName + '\'s' : 'Their' }} EvaluAgent stats</span>
      </ng-template>
    </div>
  </div>

  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <div class="p-bottom-15">
        <div class="frankli-form-group frankli-form-group-filled">
            <label for="daterange">Date range</label>
            <app-date-range-picker [formControl]="controlDateRangePicker" [minDate]="oldestDate" [name]="'daterange'" [maxSpan]="{year: 1}"></app-date-range-picker>
        </div>
      </div>
    
      <div class="evaluations-list">
        <ng-container *ngIf="scorecards.length > 0; else templateNoEvaluations;">
          <ng-container *ngFor="let scorecard of scorecards">
            <app-evaluagent-scorecard-average-display [scorecard]="scorecard" [showEvaluationsList]="true" [evaluations]="scorecard.evaluations" [passRate]="scorecard.passRate"></app-evaluagent-scorecard-average-display>
          </ng-container>
        </ng-container>
        <ng-template #templateNoEvaluations>
          <app-empty-state [height]="'200px'" [message]="'No evaluations found'"></app-empty-state>
        </ng-template>
      </div>
    </ng-container>
  </app-state>
</div>