import { Component, Input } from '@angular/core';
import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { DynamicScalePossibleAnswer } from '@app/models/evaluation/dynamic-scale-possible-answer.model';
import { CycleStepMap } from '@app/models/evaluation/evaluation-module-type.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';

@Component({
  selector: 'app-sidebar-question-item',
  templateUrl: './sidebar-question-item.component.html',
  styleUrls: ['./sidebar-question-item.component.scss']
})
export class SidebarQuestionItemComponent {
  public readonly eSurveyQuestionType = SurveyQuestionType;

  @Input() index: number;
  @Input() question: string;
  @Input() answer: string;
  @Input() comment: string;
  @Input() questionType: SurveyQuestionType;
  @Input() assessmentGroups: CycleStepMap<AssessmentGroupInfo[]>;
  @Input() options: DynamicScalePossibleAnswer[];

  constructor() {
    this.index = 0;
    this.question = '';
    this.comment = '';
    this.answer = '';
    this.options = [];
  }
}
