import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerSideEventProgress } from '@app/domain/server_side_event/model/server-side-event-progress.model';
import { SseService } from '@app/domain/server_side_event/service/sse.service';

@Injectable()
export class StatusAPIService {
  private readonly baseURL = '/api/status';

  constructor(
    private http: HttpClient,
    private sseService: SseService
  ) {
  }

  getStatus(): Observable<null> {
    const url = `${this.baseURL}/`;
    return this.http.get<null>(url);
  }

  sendTestStatus(statusToSend: number): Observable<void> {
    const url = `${this.baseURL}/send-test/${statusToSend}`;
    return this.http.get<void>(url);
  }

  sendTestStatusEC(statusToSend: number): Observable<void> {
    const url = `${this.baseURL}/send-test-ec/${statusToSend}`;
    return this.http.get<void>(url);
  }

  testServerSideEventEmitters(): Observable<ServerSideEventProgress> {
    const url = `${this.baseURL}/test-sse/progress`;
    return this.sseService.generateProgressEventMonitor(url);
  }

  getAvailableZoneIds(): Observable<string[]> {
    const url = `${this.baseURL}/available-zoneIds`;
    return this.http.get<string[]>(url);
  }

  sendTestSentryError(): Observable<void> {
    const url = `${this.baseURL}/test-sentry-error`;
    return this.http.get<void>(url);
  }
}
