<div class="zendesk-sidebar-container">
  <div class="sidebar-content-title">
    <img src="assets/img/integrations/zendesk.svg" />
    <span class="m-right-10"></span>
    <ng-container *ngIf="own; else templateViewingOthersData;">
      <span>Your Zendesk stats</span>
    </ng-container>
    <ng-template #templateViewingOthersData>
      <span>{{user ? user.firstName + '\'s' : 'Their' }} Zendesk stats</span>
    </ng-template>
  </div>

  <div class="mh-context">
    <div class="context-card">
      <span class="fal fa-fw fa-info-circle"></span>
      <span class="context-text">
        <ng-container *ngIf="userStatus; else templateNoUserStatus;">
          <span>Last updated {{userStatus.lastSync | dateAgo | lowercase}} </span>
        </ng-container>
        <ng-template #templateNoUserStatus>
          <span>We will get your data from Zendesk soon...</span>
        </ng-template>
      </span>
    </div>
  </div>

  <ng-container *ngIf="!state.loading; else stateLoading">
    <ng-container *ngIf="!state.error; else stateError">
      <ng-container *ngIf="dates && dates.length > 0; else stateEmpty;">
        <!-- Filters -->
        <ng-container *ngIf="dates && dates.length > 1">
          <div class="p-bottom-10">
            <div class="frankli-form-group frankli-form-group-filled">
              <label for="month">Month</label>
              <select id="month" name="month" class="form-control" [formControl]="controlMonth">
                <option [ngValue]="null" selected *ngIf="controlMonth.value === null">Please select...
                </option>
                <option *ngFor="let dt of dates" [ngValue]="dt">{{ dt | momentPipe: 'MMMM YYYY'}}</option>
              </select>
            </div>
          </div>
        </ng-container>      
  
        <!-- Data Output -->
        <div class="metric-list">
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{ticketsSolved}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-check"></span>
              </div>
            </div>
            <div class="metric-title">Solved in the past month</div>
          </div>
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{csatScore}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-range"></span>
              </div>
            </div>
            <div class="metric-title">CSAT score for the current 90 days</div>
          </div>
          <div class="metric-item">
            <div class="metric-item-top">
              <div class="metric-value">{{csatScorePrevious}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-calendar-clock"></span>
              </div>
            </div>
            <div class="metric-title">CSAT score previous 90 days</div>
          </div>
        </div>
      </ng-container>
      <ng-template #stateEmpty>
        <div class="p-top-15 p-bottom-15 text-center">
          <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
          <br />
          <span>No data was found</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- #region - Alt states -->
  <!-- TODO: Use component -->
  <ng-template #stateLoading>
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>

  <!-- TODO: Use component -->
  <ng-template #stateError>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br />
      <span>An error occurred loading this information</span>
    </div>
  </ng-template>
  <!-- #endregion -->
</div>