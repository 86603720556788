import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { PaginationModule } from 'app/shared/pagination/pagination.module';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SurveyFormComponentsModule } from '@app/shared/survey-components/survey-form-components.module';

import { GoalsComponentsModule } from '@app/goals/goals-components/goals-components.module';

import { GoalOverviewManagerComponent } from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager.component';
import {
  GoalOverviewManagerRowComponent
} from '@app/goals/goals-components/goal-overview-manager/goal-overview-manager-row/goal-overview-manager-row.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  GoalOverviewManagerComponent,
  GoalOverviewManagerRowComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PaginationModule,
    Angulartics2Module,
    SharedModule,
    SurveyFormComponentsModule,
    GoalsComponentsModule,
    TooltipModule.forRoot(),
    TranslateModule
  ],
  declarations: components,
  exports: components
})
export class ManagerGoalsComponentsModule { }
