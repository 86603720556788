<div class="container-sidebar-goals">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <div class="p-bottom-15">
        <div class="frankli-form-group frankli-form-group-filled p-bottom-10">
          <select class="form-control goal-type-select" [formControl]="controlFilterGoalType">
            <option [ngValue]="null" translate>{{eCommonMessages.ALL}}</option>
            <option *ngFor="let goalTypeItem of optionsGoalType" [ngValue]="goalTypeItem" translate>
              {{eGoalUtils.getMessageCodeForGoalType(goalTypeItem, globals.terminology) | translate}}
            </option>
          </select>
        </div>
      </div>

      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let goalData of data.display; index as index;">
            <app-goal-display [goal]="goalData.current" [goalPrevious]="goalData.previous" [canOpen]="true" [openRelative]="true"></app-goal-display>
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-goals.svg'" [message]="'No goals'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
