<div class="competency-item-container" [class.dropdown-open]="dropdownExpanded" *ngIf="competency">
  <div class="card-header" (click)="toggleDropdown()">
    <div class="col-arrow"[title]="(dropdownExpanded ? eCommonMessages.COLLAPSE : eCommonMessages.EXPAND) | translate">
      <div class="fal fa-fw fa-caret-right"></div>
    </div>
    <div class="col-details">
      <div class="display-competency-name">
        <h5 class="standard-heading">{{competency.name}}</h5>
        <ng-container *ngIf="competency.category">
          <div class="standard-description">{{competency.category}}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="dropdown-contents">
    <div class="display-competency-description">
      <div class="standard-description">
        <app-rich-display [text]="competency.description"></app-rich-display>
      </div>
    </div>
  </div>
</div>
