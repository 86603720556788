import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { DynamicFormQuestionComponent } from '@app/shared/survey-components/dynamic-form/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from '@app/shared/survey-components/dynamic-form/dynamic-form.component';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { OneToThreeScaleEditComponent } from '@app/shared/survey-components/one-to-three-scale-edit/one-to-three-scale-edit.component';
import { OneToFiveScaleEditComponent } from '@app/shared/survey-components/one-to-five-scale-edit/one-to-five-scale-edit.component';
import { OneToFourScaleEditComponent } from '@app/shared/survey-components/one-to-four-scale-edit/one-to-four-scale-edit.component';
import { OneToFourScaleComponent } from '@app/shared/survey-components/one-to-four-scale/one-to-four-scale.component';
import { SidebarQuestionItemComponent } from '../components/displays/sidebar-question-item/sidebar-question-item.component';
import { EmotionScaleComponent } from './emotion-scale/emotion-scale.component';
import { EnpsScaleComponent } from './enps-scale/enps-scale.component';
import { OneToThreeScaleComponent } from './one-to-three-scale/one-to-three-scale.component';
import { OneToFiveScaleComponent } from './one-to-five-scale/one-to-five-scale.component';
import { LikertScaleComponent } from './likert-scale/likert-scale.component';
import { MultiChoiceComponent } from './multi-choice/multi-choice.component';
import { SingleChoiceComponent } from './single-choice/single-choice.component';
import { ScaleComponent } from './scale/scale.component';
import { DynamicScaleComponent } from './dynamic-scale/dynamic-scale.component';
import { DynamicScaleOptionComponent } from './dynamic-scale-option/dynamic-scale-option.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

// NB: Update this when you add a new component
const components = [
  EmotionScaleComponent,
  LikertScaleComponent,
  MultiChoiceComponent,
  SingleChoiceComponent,
  OneToFourScaleComponent,
  OneToFourScaleEditComponent,
  OneToThreeScaleComponent,
  OneToThreeScaleEditComponent,
  OneToFiveScaleComponent,
  OneToFiveScaleEditComponent,
  DynamicFormComponent,
  DynamicFormQuestionComponent,
  EnpsScaleComponent,
  SidebarQuestionItemComponent,
  ScaleComponent,
  DynamicScaleComponent,
  DynamicScaleOptionComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Angulartics2Module,
    SharedModule,
    ReactiveFormsModule,
    PipesModule,
    DragDropModule
  ],
  declarations: components,
  exports: components
})
export class SurveyFormComponentsModule { }
