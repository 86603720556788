import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Praise, UpdatePraiseDto } from 'app/models/feedback/praise.model';
import { User } from 'app/models/user/user.model';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from '@app/models/company-features.model';
import { PraiseAPIService } from 'app/shared/api/praise.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Tag } from '@app/domain/tag/model/tag.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityReaction, ReactionType } from '@app/models/activity-reaction.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { TagType } from '@app/domain/tag/model/tag-type.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { TagBusinessService } from '@app/domain/tag/service/tag-business.service';

enum ModalTab {
  ALL = 'ALL',
  LIKE = 'LIKE',
  CELEBRATE = 'CELEBRATE',
  LOVE = 'LOVE'
}

interface ReactionUser {
  reaction: ActivityReaction;
  user: User;
}

interface PageTag extends Tag {
  selected: boolean;
}

@Component({
  selector: 'app-profile-praise',
  templateUrl: './profile-praise.component.html',
  styleUrls: ['./profile-praise.component.scss']
})
export class ProfilePraiseComponent implements OnInit, OnChanges {
  public readonly largeEditorMinLength = 10;
  public readonly largeEditorMaxLengthSoft = 1000;
  public readonly largeEditorMaxLengthHard = 2000;
  public readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';
  public readonly eButtonType = ButtonType;

  @Input() userProfile!: User;
  @Output() clickGivePraise: EventEmitter<any> = new EventEmitter();
  @ViewChild('reactionUserModal') reactionUserModal?: ModalComponent;
  @ViewChildren('pop') popovers!: QueryList<PopoverDirective>;

  user: User;
  modalTab: ModalTab;
  eFeature = CompanyFeatures;
  eReactionType = ReactionType;
  eModalTab = ModalTab;
  loading = true;
  error = false;
  reactionUsers: Array<ReactionUser>;
  reactionUsersDisplay: Array<ReactionUser>;
  praiseList: Array<Praise>;
  availableValues: Array<PageTag>;
  selectedValues: Array<PageTag>;
  imgList: Array<any>;
  labelText = '';
  titleText = '';
  praise = '';
  submittedPraise = false;
  mouseInPop = false;
  maxLengthPraise = 1000;
  minLengthPraise = 1;
  editorStyles = {
    'overflow-y': 'auto',
    'max-height': '120px',
    'min-height': '120px',
  };

  editingId: number | null;
  editForm!: FormGroup;

  constructor(
    public globals: Globals,
    private _praiseAPIService: PraiseAPIService,
    private _userAPIService: UserAPIService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private swalUtils: SwalUtils,
    private notifyUtils: NotifyUtils,
    private tagBusinessService: TagBusinessService
  ) {
    this.user = this.globals.user;
    this.praiseList = [];
    this.imgList = [];
    this.availableValues = [];
    this.selectedValues = [];
    this.reactionUsers = [];
    this.reactionUsersDisplay = [];
    this.editingId = null;
    this.modalTab = ModalTab.ALL;
  }

  ngOnInit() {
    this.tagBusinessService.get(null, null, [TagType.COMPANY_VALUE]).subscribe(resList => {
      this.availableValues = this.parseTagToPageTagMultiple(resList);
    });
  }

  toggleValue(value: PageTag) {
    value.selected = !value.selected;
  }

  ngOnChanges() {
    this.getPraise();
  }

  noPraise() {
    this.praise = '';
    this.swalUtils.displayErrorSwal({
      title: 'Praise cancelled!',
      text: 'No praise will be posted'
    });
  }

  getPraise() {
    const funcpraise = this.user.id === this.userProfile.id
      ? this._praiseAPIService.getPraiseReceived() : this._praiseAPIService.getPraiseReceivedByUserId(this.userProfile.id);
    funcpraise.subscribe((praiseArr) => {
      this.praiseList = [];
      this.imgList = [];
      this.praiseList = praiseArr.filter(x => !x.hidden).slice(0).slice(-5).reverse();
    });
    this.loading = false;
  }
  
  parseTagToPageTagMultiple(tags: Tag[]): PageTag[] {
    return tags.map(t => this.parseTagToPageTagSingle(t));
  }

  parseTagToPageTagSingle(tag: Tag): PageTag {
    const output = tag as PageTag;

    if (output) {
      output.selected = false;
    }

    return output;
  }

  removePraise(praise: Praise) {
    this.swalUtils.displayWarningConfirmationSwal({
      title: 'Remove praise?',
      text: `Your praise will be removed from ${this.userProfile.firstName}'s profile.`
    }).then(result => {
      if (result.value) {
        this._praiseAPIService.deletePraiseById(praise.id).subscribe(() => {
          this.notifyUtils.notifyIcon({
            icon: 'fal fa-thumbs-up',
            message: 'Praise successfully deleted',
          }, {
            type: 'frankli-blue',
            placement: {
              from: 'top',
              align: 'right',
            },
          });
          this.getPraise();
        });
      }
    });
  }

  hidePraise(praise: Praise) {
    this.swalUtils.displayWarningConfirmationSwal({
      title: 'Hide praise?',
      text: 'Praise will be hidden from your public profile.'
    }).then(result => {
      if (result.value) {
        this._praiseAPIService.togglePraiseVisibleById(praise.id).subscribe(res => {
          this.getPraise();
        });
      }
    });
  }

  editPraise(praise: Praise) {
    this.clearValues();
    this.editingId = praise.id;

    // Init form
    this.editForm = this._formBuilder.group({
      editMessage: new FormControl(praise.message, [Validators.required, this.softMinValidation(this.largeEditorMinLength), this.softMaxValidation(this.largeEditorMaxLengthSoft), Validators.maxLength(this.largeEditorMaxLengthHard)]),
    });

    this.editForm.controls.editMessage.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    });

    // Values
    const selectedValues = praise.values || [];
    const selectedValueIds = selectedValues.map(value => value.id) || [];
    this.availableValues.forEach(value => {
      if (selectedValueIds.includes(value.id)) {
        this.toggleValue(value);
      }
    });
  }

  cancelEditing() {
    this.editingId = null;
    this.clearValues();
  }

  softMinValidation(min: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length < min) {
          return {
            softmin: true,
          };
        }
      }

      return null!;
    };
  }

  softMaxValidation(max: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }

      return null!;
    };
  }

  clearValues() {
    this.availableValues.forEach(val => val.selected = false);
  }

  savePraise(praise: Praise) {
    this.submittedPraise = true;
    if (this.editForm.valid) {
      const updatePraiseDto: UpdatePraiseDto = {
        message: this.editForm.controls.editMessage.value,
        values: this.availableValues.filter(val => val.selected === true)
      };
      this._praiseAPIService.updatePraiseById(praise.id, updatePraiseDto).subscribe(() => {
        this.notifyUtils.notify('Praise successfully updated!');
        this.submittedPraise = false;
        this.getPraise();
        this.cancelEditing();
      });
    }
  }

  givePraise() {
    this.clickGivePraise.emit(true);
  }

  viewAllPraise() {
    if (this.userProfile.id === this.globals.user.id) {
      this.router.navigate(
        ['feedback/history'],
        { queryParams: {type: 'praise', filter: 'received'}}
      );
    } else {
      this.router.navigate(
        ['./praise'],
        { relativeTo: this.route });
    }
  }

  toggleReactionMain(praise: Praise, type: ReactionType, event?: Event) {
    if (this.globals.onMobile) {
      return;
    }

    this.toggleReaction(praise, type, event);
  }

  toggleReaction(praise: Praise, type: ReactionType, event?: Event) {
    if (event) { // Stop popover closing on touch
      event.preventDefault();
      event.stopPropagation();
    }

    this._praiseAPIService.toggleReactionForPraiseByIdAndReactionType(praise.id, type).subscribe(praise => {
      const idx = this.praiseList.findIndex(p => p.id === praise.id);
      this.praiseList[idx].reactions = praise.reactions;
      if (this.getUniqueReactions(praise.reactions).has(type)) {
        this.notifyUtils.notify(`Successfully ${type.toLowerCase()}d ${praise.praisedUser.firstName}'s praise!`);
      }
      setTimeout(() => this.popovers.forEach(popover => popover.hide()), 200);
    });
  }

  userHasReacted(praise: Praise, type?: ReactionType): boolean {
    if (praise.reactions && praise.reactions.length > 0) {
      return !!praise.reactions
        .filter(r => r.userId === this.globals.user.id)
        .find(r => (type ? (r.type === type) : true));
    } else {
      return false;
    }
  }

  getUniqueReactions(reactions: Array<ActivityReaction>): Set<ReactionType> {
    return new Set(reactions.map(r => r.type));
  }

  loadReactionUserModal(reactions: Array<ActivityReaction>) {
    const newReactionUsers = new Array<ReactionUser>();
    this.reactionUsers = newReactionUsers;
    reactions.forEach(reaction =>
      this._userAPIService.getById(reaction.userId).subscribe(user => {
        newReactionUsers.push({
          user,
          reaction
        });
      })
    );
    this.reactionUsersDisplay = this.reactionUsers;
    this.modalTab = ModalTab.ALL;
    if (this.reactionUserModal) {
      this.reactionUserModal.show();
    }
  }

  changeTab(tab: ModalTab) {
    if (tab !== ModalTab.ALL) {
      const tabAsType = ReactionType[tab];
      this.reactionUsersDisplay = this.getReactionUsersForType(tabAsType);
    } else {
      this.reactionUsersDisplay = this.reactionUsers;
    }
    this.modalTab = tab;
  }

  getReactionUsersForType(type: ReactionType): Array<ReactionUser> {
    return this.reactionUsers.filter(ru => ru.reaction.type === type);
  }

  // To prevent duplicate popovers, we need this method to hide popovers that are no longer referenced
  hideOtherPopovers(event: PopoverDirective, praise: Praise) {
    const currentPop = this.popovers.find(p => p.popoverContext.praise === praise);
    if (currentPop) {
      const otherPopsShown = this.popovers.filter(p => p.isOpen && p !== currentPop);
      if (otherPopsShown.length > 0) {
        otherPopsShown.forEach(p => p.hide());
      }
    }
    this.checkMousePosition();
  }

  // If mouse not over element, hide pop
  checkMousePosition() {
    setTimeout(() => {
      if (!this.mouseInPop) {
        this.popovers.forEach(popover => popover.hide());
      } else {
        this.checkMousePosition();
      }
    }, 4000);
  }
}
