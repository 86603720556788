import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMeeting } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { Globals } from '@app/shared/globals/globals';
import { PillType } from '../../pill/pill.component';
import { OneToOneBusinessService } from '@app/domain/one_to_one/service/one-to-one-business.service';

@Component({
  selector: 'app-one-to-one-schedule-detailed-display',
  templateUrl: './one-to-one-schedule-detailed-display.component.html',
  styleUrls: ['./one-to-one-schedule-detailed-display.component.scss']
})
export class OneToOneScheduleDetailedDisplayComponent implements OnInit, OnChanges {

  public readonly eCommonMessages = CommonMessages;
  public readonly ePillType = PillType;

  @Input() schedule: OneToOneSchedule;
  @Input() startExpanded: boolean;
  @Input() canToggleDropdown: boolean;

  dropdownExpanded: boolean;
  controlSelectedMeeting: FormControl;
  lastMeetingTimestamp: Date;

  selectedMeeting: OneToOneMeeting;

  meetingList: OneToOneMeeting[];

  constructor(
    public globals: Globals,
    private oneToOneBusinessService: OneToOneBusinessService
  ) {
    this.startExpanded = false;
    this.canToggleDropdown = true;
    this.schedule = undefined;
    this.selectedMeeting = undefined;
    this.dropdownExpanded = false;
    this.meetingList = [];
    this.controlSelectedMeeting = this.initControlSelectedMeeting();
  }

  ngOnInit(): void {
    this.dropdownExpanded = this.startExpanded;
    if (this.dropdownExpanded) {
      this.onDropdownExpanded();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changesSchedule = changes['schedule'];
    if (changesSchedule) {
      this.refreshData();
    }
  }

  initControlSelectedMeeting(): FormControl {
    const formControl = new FormControl(null, []);

    formControl.valueChanges.subscribe((value: number) => this.onMeetingChanged(value));

    return formControl;
  }

  onMeetingChanged(meetingId: number): void {
    if (!this.schedule) { return; }
    if (!this.meetingList) { return; }
    if (this.meetingList.length === 0) { return; }

    const selectedMeeting = this.meetingList.find(meeting => meeting.id === meetingId);
    if (!selectedMeeting) { return; }

    this.oneToOneBusinessService.getMeetingById(this.schedule.id, selectedMeeting.id)
      .toPromise()
      .then(meeting => {
        this.selectedMeeting = meeting;
      });
  }
  
  refreshData(): void {
    if (!this.schedule) { return; }

    if (Object.hasOwnProperty.call(this.schedule, 'meetingList')) {
      this.meetingList = this.schedule.meetingList;
    } else {
      this.meetingList = [];
    }

    this.lastMeetingTimestamp = this.getLastMeetingTimestamp(this.meetingList);
  }

  toggleDropdown(): void {
    if (!this.canToggleDropdown) { return; }
    this.dropdownExpanded = !this.dropdownExpanded;
    if (this.dropdownExpanded) {
      this.onDropdownExpanded();
    }
  }

  onDropdownExpanded(): void {
    if (!this.meetingList) { return; }
    if (this.meetingList.length === 0) { return; }
    this.controlSelectedMeeting.setValue(this.meetingList[this.meetingList.length - 1].id);
  }

  getLastMeetingTimestamp(meetingList: OneToOneMeeting[]): Date {
    if (!meetingList) { return null; }
    if (meetingList.length === 0) { return null; }

    const lastMeeting = meetingList[0];

    if (!lastMeeting.meetingTimestamp) { return null; }
    return new Date(lastMeeting.meetingTimestamp);
  }
}
