import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Goal } from 'app/models/goals/goal.model';
import { Observable, Subject } from 'rxjs';
import { GoalCommentDto, GoalProgressDto, GoalStatusDto, GoalUpdateDto } from 'app/models/goals/goal.dto';
import { map, tap } from 'rxjs/operators';
import { DateService } from 'app/shared/utils/date.service';
import { GoalTemplate } from '@app/models/goals/templates/goal-template.model';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { GoalDepartmentOverview } from '@app/models/goals/goal-department-overview.model';
import { OfficeLocationGoalOverview } from '@app/models/goals/office-location-goal-overview.model';
import { DepartmentGoalOverview } from '@app/models/goals/department-goal-overview.model';
import { GoalService } from '@app/shared/api/interfaces/goals.service';
import { GoalServerside } from '@app/models/goals/goal-serverside.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';
import { Page } from '@app/models/api/page.model';
import { ApiUtils } from '@app/shared/utils/api.utils';
import { PagingParams } from '@app/models/api/paging-params.model';
import { SortingParams } from '@app/models/api/sorting-params.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { CreateGoalTemplateDto } from '@app/models/goals/templates/create-goal-template.dto';
import { ParentFilter } from '@app/models/api/parent-filter.model';
import { GoalStats } from '@app/models/goals/goal-stats';
import { GoalStatsScope } from '@app/models/goals/goal-stats-scope';
import { UpdateGoalDraft } from '@app/models/goals/update-goal-draft';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { SseService } from '../../domain/server_side_event/service/sse.service';
import { GoalCreateResponse } from '@app/models/goals/goal-create-response.model';
import { ServerSideEventProgress } from '@app/domain/server_side_event/model/server-side-event-progress.model';
import { DashboardGoalsStats } from '@app/models/goals/dashboard-goal-overview.model';
import { Quarter } from '@app/models/quarter.model';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';

@Injectable()
export class GoalsAPIService implements GoalService {
  private readonly BASE_URL = 'api/goal/';

  constructor(
    private http: HttpClient,
    private dateService: DateService,
    private sseService: SseService
  ) { }
  /**
   * Parses Utc dates to localdate
   * @param goal
   */
  private mapGoalDraft(goal: GoalDraft): GoalDraft {
    if (goal.endDate !== null) {
      goal.endDate = this.dateService.parseUtcToLocal(goal.endDate);
    }
    goal.keyResults.forEach(result => {
      if (result.endDate !== null) {
        result.endDate = this.dateService.parseUtcToLocal(result.endDate);
      }
    });
    goal.keyResults = goal.keyResults.sort((a, b) => a.orderIndex - b.orderIndex);

    return goal;
  }


  private mapGoalTemplate(goal: GoalTemplate): GoalTemplate {
    goal.keyResults = goal.keyResults.sort((a, b) => a.orderIndex - b.orderIndex);

    return goal;
  }

  createGoal(createGoalDto: CreateGoalDto): Observable<GoalCreateResponse> {
    const url = this.BASE_URL;
    return this.http.post<GoalCreateResponse>(url, createGoalDto);
  }

  createGoalWithProgress(createGoalDto: CreateGoalDto): Observable<{
    create: Observable<GoalCreateResponse>,
    progress: Observable<ServerSideEventProgress>
  }> {
    const urlProgress = `${this.BASE_URL}create/progress`;

    let createStarted = false;

    return new Observable<{
      create: Observable<GoalCreateResponse>,
      progress: Observable<ServerSideEventProgress>
    }>(observer => {
      const subjectCreate = new Subject<GoalCreateResponse>();
      const subjectProgress = new Subject<ServerSideEventProgress>();
  
      this.sseService.generateProgressEventMonitor(urlProgress)
        .pipe(
          tap(firstEvent => { // Might be a better RXJS operator for this (Takes the first event only)
            if (createStarted) { return; }
            createStarted = true;
  
            this.createGoalUsingGUID(firstEvent.guid, createGoalDto)
              .toPromise()
              .then(res => {
                subjectCreate.next(res);
                subjectCreate.complete();
              });
          })
        )
        .subscribe(res => {
          subjectProgress.next(res);

          if (res.progress === 100) {
            subjectProgress.complete();
          }
        });
  
      observer.next({
        create: subjectCreate,
        progress: subjectProgress
      });
    });
  }

  createGoalUsingGUID(guid: string, createGoalDto: CreateGoalDto): Observable<GoalCreateResponse> {
    const url = `${this.BASE_URL}create/start/${guid}`;
    return this.http.post<GoalCreateResponse>(url, createGoalDto);
  }

  updateGoal(id: number, goalUpdateDto: GoalUpdateDto): Observable<Goal> {
    const url = this.BASE_URL + 'update/goal/' + id;
    return this.http.post<GoalServerside>(url, goalUpdateDto).pipe(map(g => new Goal(g)));
  }

  deleteGoal(id: number): Observable<number> {
    const url = this.BASE_URL + 'delete/' + id;
    return this.http.post<number>(url, null);
  }

  addComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    const url = this.BASE_URL + 'comment/add/' + id;
    return this.http.post<GoalServerside>(url, goalCommentDto).pipe(map(g => new Goal(g)));
  }

  deleteComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    const url = this.BASE_URL + 'comment/delete/' + id;
    return this.http.post<GoalServerside>(url, goalCommentDto).pipe(map(g => new Goal(g)));
  }

  updateProgress(id: number, goalProgressDto: GoalProgressDto) {
    const url = this.BASE_URL + 'update/progress/' + id;
    return this.http.post<GoalServerside>(url, goalProgressDto).pipe(map(g => new Goal(g)));
  }

  updateStatus(id: number, goalStatusDto: GoalStatusDto) {
    const url = this.BASE_URL + 'update/status/' + id;
    return this.http.post<GoalServerside>(url, goalStatusDto).pipe(map(g => new Goal(g)));
  }

  getPersonalGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsActive(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/active/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsComplete(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/complete/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsArchived(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/archived/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsActive(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/active/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsComplete(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/complete/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsArchived(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/archived/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalById(id: number): Observable<Goal> {
    const url = this.BASE_URL + id;
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getOldestGoal(): Observable<Goal> {
    const url = this.BASE_URL + 'oldest';
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getOfficeLocationGoalOverview(): Observable<Array<OfficeLocationGoalOverview>> {
    const url = this.BASE_URL + 'office-locations/overview';
    return this.http.get<Array<OfficeLocationGoalOverview>>(url);
  }

  /**
   * Gets all goals the current user is the owners of
   */
  getIndividualGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualActiveGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/active';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualArchivedGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/archived';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualCompleteGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/complete';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  managerGetGoalById(userId: number, goalId: number): Observable<Goal> {
    const url = this.BASE_URL + 'manager/' + userId + '/' + goalId;
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getPublicPersonalGoals(id: number) {
    const url = this.BASE_URL + 'personal/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoals(pagingParams: PagingParams, sortingParams: SortingParams, id: number): Observable<Page<Goal>> {
    const url = this.BASE_URL + 'tags/' + id;
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    return this.http.get<Page<GoalServerside>>(url, { params }).pipe(map(response => ({
      ...response,
      content: response.content.map(g => new Goal(g))
    })));
  }

  getGoalsPublicAll(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'all';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalsOverviewPercentage(): Observable<Array<number>> {
    const url = this.BASE_URL + 'completion';
    return this.http.get<Array<number>>(url);
  }

  getAlignedGoals(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'aligned/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getAdminGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'admin';
    return this.http.get<Array<GoalServerside>>(url)
      .pipe(
        map(response => response.map(g => new Goal(g)))
      );
  }

  // Goal Drafts
  createDraftGoal(createGoalDto: CreateGoalDto): Observable<GoalDraft> {
    const url = this.BASE_URL + 'drafts';
    return this.http.post<GoalDraft>(url, createGoalDto).pipe(map(g => this.mapGoalDraft(g)));
  }

  getDraftGoals(pagingParams: PagingParams, sortingParams: SortingParams): Observable<Page<GoalDraft>> {
    const url = this.BASE_URL + 'drafts';
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    return this.http.get<Page<GoalDraft>>(url, { params }).pipe(map(response => ({
      ...response,
      content: response.content.map(g => this.mapGoalDraft(g))
    })));
  }

  getDraftGoalById(id: number): Observable<GoalDraft> {
    const url = this.BASE_URL + 'drafts/' + id;
    return this.http.get<GoalDraft>(url).pipe(map(g => this.mapGoalDraft(g)));
  }

  updateDraftGoalById(id: number, updateGoalDraft: UpdateGoalDraft): Observable<GoalDraft> {
    const url = this.BASE_URL + 'drafts/' + id;
    return this.http.put<GoalDraft>(url, updateGoalDraft).pipe(map(g => this.mapGoalDraft(g)));
  }

  deleteDraftGoalById(id: number): Observable<GoalDraft> {
    const url = this.BASE_URL + 'drafts/' + id;
    return this.http.delete<GoalDraft>(url).pipe(map(g => this.mapGoalDraft(g)));
  }

  // Goal Templates
  getGoalTemplates(): Observable<Array<GoalTemplate>> {
    const url = this.BASE_URL + 'template';
    return this.http.get<Array<GoalTemplate>>(url);
  }

  createGoalTemplate(goalTemplate: CreateGoalTemplateDto): Observable<GoalTemplate> {
    const url = this.BASE_URL + 'template';
    return this.http.post<GoalTemplate>(url, goalTemplate).pipe(map(g => this.mapGoalTemplate(g)));
  }

  updateGoalTemplate(templateId: number, goalTemplate: CreateGoalTemplateDto): Observable<GoalTemplate> {
    const url = this.BASE_URL + `template/${templateId}`;
    return this.http.put<GoalTemplate>(url, goalTemplate).pipe(map(g => this.mapGoalTemplate(g)));
  }

  deleteGoalTemplate(templateId: number): Observable<GoalTemplate> {
    const url = this.BASE_URL + `template/${templateId}`;
    return this.http.delete<GoalTemplate>(url).pipe(map(g => this.mapGoalTemplate(g)));
  }

  getGoalDirectReportOverview(rootUserId: number, depth: number): Observable<GoalDirectReportOverview[]> {
    const url = `${this.BASE_URL}manage/direct`;
    const params = new HttpParams().appendAll({rootUserId, depth});
    return this.http.get<GoalDirectReportOverview[]>(url, { params });
  }

  searchGoalDirectReportOverviews(pageParams: PagingParams, parentFilter: ParentFilter): Observable<Page<GoalDirectReportOverview>> {
    const url = `${this.BASE_URL}manage/direct`;
    const params = ApiUtils.createPageParams(pageParams);
    return this.http.post<Page<GoalDirectReportOverview>>(url, parentFilter, { params });
  }

  searchGoalDirectReportOverviewsAsAdmin(pageParams: PagingParams, parentFilter: ParentFilter): Observable<Page<GoalDirectReportOverview>> {
    const url = `${this.BASE_URL}manage/direct/admin`;
    const params = ApiUtils.createPageParams(pageParams);
    return this.http.post<Page<GoalDirectReportOverview>>(url, parentFilter, { params });
  }

  getSecondarySubordinatesGoalsOverview(): Observable<GoalDirectReportOverview[]> {
    const url = `${this.BASE_URL}manage/secondary`;
    return this.http.get<GoalDirectReportOverview[]>(url);
  }

  getTeamGoalsOverviewAdmin(): Observable<GoalDirectReportOverview[]> {
    const url = `${this.BASE_URL}manage/direct/admin`;
    return this.http.get<GoalDirectReportOverview[]>(url);
  }

  getDepartmentGoalsOverview(id?: number): Observable<GoalDepartmentOverview> {
    const url = (id ? `${this.BASE_URL}overview/departments/${id}` : `${this.BASE_URL}overview/departments/`);
    return this.http.get<GoalDepartmentOverview>(url);
  }

  getDepartmentGoalOverviewOld(): Observable<Array<DepartmentGoalOverview>> {
    const url = this.BASE_URL + 'departments/overview';
    return this.http.get<Array<DepartmentGoalOverview>>(url);
  }

  // Office Location Goals

  getOfficeLocationGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/active`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/complete`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/archived`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsActiveByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/active`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsCompleteByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/complete`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsArchivedByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/archived`;
    return this.getRequestGoalsArray(url);
  }

  // Team Goals

  getTeamGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/active`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/complete`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/archived`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsActiveByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/active/`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsCompleteByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/complete`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsArchivedByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/archived`;
    return this.getRequestGoalsArray(url);
  }

  // Department Goals

  getDepartmentGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/active`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/complete`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/archived`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsActiveByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/active/`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsCompleteByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/complete/`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsArchivedByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/archived/`;
    return this.getRequestGoalsArray(url);
  }

  // Utils
  getAlignmentGoalsByGoalType(pagingParams: PagingParams, sortingParams: SortingParams, goalType: GoalType, title?: string): Observable<Page<Goal>> {
    const url = `${this.BASE_URL}alignment`;
    let params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    params = params.append('goalType', goalType);
    if (title) {
      params = params.append('title', title);
    }
    return this.http.get<Page<GoalServerside>>(url, { params }).pipe(map(response => ({
      ...response,
      content: response.content.map(g => new Goal(g))
    })));
  }

  getGoalStatsForScope(scope: GoalStatsScope): Observable<GoalStats> {
    const url = `${this.BASE_URL}stats`;
    const params = new HttpParams().append('scope', scope);
    return this.http.get<GoalStats>(url, { params });
  }

  getGoalStatsForUser(userId: number): Observable<GoalStats> {
    const url = `${this.BASE_URL}stats/${userId}`;
    return this.http.get<GoalStats>(url);
  }

  getRequestGoalsArray(url: string): Observable<Array<Goal>> {
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  searchGoals(pagingParams: PagingParams, sortingParams: SortingParams, parentFilter: ParentFilter) {
    const url = `${this.BASE_URL}search`;
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    return this.http.post<Page<GoalServerside>>(url, parentFilter, { params }).pipe(map(response => ({
      ...response,
      content: response.content.map(g => new Goal(g))
    })));
  }

  getGoals(
    userIdsViewing: number[],
    goalIds: number[],
    ownerIds: number[],
    types: GoalType[],
    statuses: GoalStatus[],
    visibility: GoalVisibility[],
    archived: boolean | null
  ) {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    userIdsViewing.forEach(id => {
      params = params.append('viewerId', id);
    });

    goalIds.forEach(id => {
      params = params.append('goalId', id);
    });

    ownerIds.forEach(id => {
      params = params.append('ownerId', id);
    });

    types.forEach(type => {
      params = params.append('type', type);
    });

    statuses.forEach(status => {
      params = params.append('status', status);
    });

    visibility.forEach(vis => {
      params = params.append('visibility', vis);
    });

    if (archived !== null) {
      params = params.append('archived', false);
    }

    return this.http.get<GoalServerside[]>(url, { params: params })
      .pipe(map(response => response.map(g => new Goal(g))));
  }

  getUsersGoalCounts(): Observable<Blob> {
    const url = `${this.BASE_URL}report-goal-counts`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getTagGoalsActive(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/active';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoalsComplete(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/complete';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoalsArchived(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/archived';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalCountDueThisYear(): Observable<number> {
    const url = `${this.BASE_URL}count-due-this-year`;
    return this.http.get<number>(url);
  }

  getDashboardWidgetDataForQuarter(quarter: Quarter): Observable<DashboardGoalsStats> {
    const url = `${this.BASE_URL}dashboard-widget-data/${quarter}`;
    return this.http.get<DashboardGoalsStats>(url);
  }

}
