import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { ICompetencyAPI } from '@app/domain/competency/api/competency-api.model';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mockCompetencies } from '@app/domain/competency/mock/competencies.mock';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { Competency } from '@app/domain/competency/model/competency.model';
import { CreateCompetencyDto } from '@app/domain/competency/model/create-competency.model';

@Injectable()
export class CompetencyAPIServiceMock implements MockService, ICompetencyAPI {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.endsWith('api/competency') && req.method === 'GET':
        return this.get(null, null, null);
      case url.match(/api\/competency\/\d+$/) !== null && req.method === 'GET':
        return this.get(null, null, null)[0];
      case url.match(/api\/competency\/category\/\d+$/) !== null && req.method === 'GET':
        return this.get(null, null, null)[0];
    }
  }

  get(
    ids: number[],
    name: string,
    categories: string[]
  ): Observable<Competency[]> {
    return of(mockCompetencies);
  }

  // No Ops listed below
  importCompetencies(file: File): Observable<Competency[]> {
    return of(mockCompetencies);
  }

  update(id: number, competency: CreateCompetencyDto): Observable<Competency> {
    return of(mockCompetencies[0]);
  }

  create(competency: CreateCompetencyDto): Observable<Competency> {
    return of(mockCompetencies[0]);
  }

  delete(id: number): Observable<Competency> {
    return of(mockCompetencies[0]);
  }

  deleteMultiple(competencyIds: number[]): Observable<Competency[]> {
    return of(mockCompetencies);
  }

  downloadCSV(): Observable<Blob> {
    return of(undefined);
  }

}