import { NgModule } from '@angular/core';
import { ProfileGoalsComponent } from './profile-goals/profile-goals.component';
import { ProfilePraiseComponent } from 'app/shared/profile/profile-praise/profile-praise.component';
import { ProfileDevelopmentComponent } from 'app/shared/profile/profile-development/profile-development.component';
import { ProfileContactInterestsComponent } from 'app/shared/profile/profile-contact-interests/profile-contact-interests.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { Angulartics2Module } from 'angulartics2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { PopoverModule } from 'ngx-bootstrap/popover';

const components = [
  ProfileGoalsComponent,
  ProfilePraiseComponent,
  ProfileDevelopmentComponent,
  ProfileContactInterestsComponent
]

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    RouterModule,
    CommonModule,
    PipesModule,
    Angulartics2Module,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PopoverModule.forRoot()
  ],
})
export class SharedProfileModule {}
