import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from 'app/models/user/user.model';
import {Globals} from 'app/shared/globals/globals';
import { CompanyFeatures } from '@app/models/company-features.model';
import {UserAPIService} from 'app/shared/api/user.api.service';
import {NavigationEnd, Router} from '@angular/router';
import {PreferredContactMethod} from '@app/models/user/preferred-contact-method.model';
import {NavTabData} from '@app/shared/components/nav-tabs/nav-tabs.component';
import {PagingParams} from '@app/models/api/paging-params.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { IState } from '@app/models/state/state.model';
import { Subscription } from 'rxjs';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';

enum PageTabs {
  NONE = 'NONE',
  CONTACT = 'CONTACT',
  PERSONAL = 'PERSONAL',
  PROFESSIONAL = 'PROFESSIONAL', 
  SKILLS = 'SKILLS'
}

interface SimilarInterests {
  interest: Interest;
  users: Array<User>
}

@Component({
  selector: 'app-profile-contact-interests',
  templateUrl: './profile-contact-interests.component.html',
  styleUrls: ['./profile-contact-interests.component.scss'],
})
export class ProfileContactInterestsComponent implements OnInit, OnDestroy {

  public readonly ePreferredContactMethod = PreferredContactMethod;
  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eFeature = CompanyFeatures;
  public readonly ePageTabs = PageTabs;

  @Input() userProfile !: User;

  personalInterests: SimilarInterests[];
  professionalInterests: SimilarInterests[];

  lastTab: string;
  tabOpen: string;

  subscriptions: Subscription[];

  displayCount: number;

  navTabData: NavTabData;

  state: IState;

  constructor(
    public globals: Globals,
    private router: Router,
    private userAPIService: UserAPIService) {
    this.tabOpen = this.ePageTabs.CONTACT;
    this.lastTab = this.ePageTabs.NONE;

    this.professionalInterests = [];
    this.personalInterests = [];
    this.subscriptions = [];

    this.displayCount = 5;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.navTabData = this.getNavTabData();
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.getData();
        }
      }),
    );
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    });
  }

  getData(): void {
    this.state.loading = true;

    this.personalInterests = [];
    this.professionalInterests = [];

    const pageParams: PagingParams = {
      pageNumber: 0,
      pageSize: 60
    };

    this.userAPIService.getUsersSimilar(pageParams).subscribe(pagedUsers => {
      const users = pagedUsers.content.filter(u => u.id !== this.userProfile.id);
      this.userProfile.personalInterests.forEach(pi => {
        const interest = {
          interest: pi,
          users: new Array<User>(),
        };
        users.forEach((u: any) => {
          if (u.interests!.map((ui: Interest) => ui.id).includes(pi.id)) {
            interest.users.push(u);
          }
        });
        this.personalInterests.push(interest);
      });

      this.userProfile.professionalInterests.forEach(pi => {
        const interest = {
          interest: pi,
          users: new Array<User>(),
        };
        users.forEach((u: any) => {
          if (u.interests!.map((ui: Interest) => ui.id).includes(pi.id)) {
            interest.users.push(u);
          }
        });
        this.professionalInterests.push(interest);
      });

      this.state.loading = false;
    });
  }

  getNavTabData(): NavTabData {
    return {
      label: 'Viewing',
      iconsEnabled: true,
      items: [
        {
          identifier: PageTabs.CONTACT,
          title: 'Contact Details',
          icon: 'fa-phone',
          condition: () => true,
          amplitude: {
            action: 'ClickNavContactDetails',
            category: 'Profile',
          },
          intercomTarget: 'View Contact Details'
        },
        {
          identifier: PageTabs.SKILLS,
          title: this.globals.getTerminology(TerminologyEntity.SKILL_PLURAL),
          icon: 'fa-screwdriver-wrench',
          condition: () => this.globals.hasFeature(CompanyFeatures.SKILLS),
          amplitude: {
            action: 'ClickNavSkills',
            category: 'Profile',
          },
          intercomTarget: 'View Skills'
        },
        {
          identifier: PageTabs.PERSONAL,
          title: 'Personal Interests',
          icon: 'fa-mug-hot',
          condition: () => true,
          amplitude: {
            action: 'ClickNavPersonalInterests',
            category: 'Profile',
          },
          intercomTarget: 'View Personal Interests'
        },
        {
          identifier: PageTabs.PROFESSIONAL,
          title: 'Professional Interests',
          icon: 'fas fa-briefcase',
          condition: () => true,
          amplitude: {
            action: 'ClickNavProfessionalInterests',
            category: 'Profile',
          },
          intercomTarget: 'View Professional Interests'
        }
      ]
    };
  }

  switchTab(tab: string) {
    this.tabOpen = tab;
    this.displayCount = 5;
  }

  loadMore() {
    this.displayCount += 5;
  }

  toggleExpand() {
    if (this.tabOpen === this.ePageTabs.NONE) {
      this.tabOpen = this.lastTab;
    } else {
      this.lastTab = this.tabOpen;
      this.tabOpen = this.ePageTabs.NONE;
    }
  }

  navigateToUser(id: number) {
    this.router.navigate(['/profile/', id]);
  }

  navigateToRoute(route: string[]): void {
    this.router.navigate(route);
  }
}
