<div class="container-employee-layout">
  <div class="area-main-page">
    <div class="row-sidebar-page" [class.sidebar-opened]="sidebarService.sidebarOpen">
      <div class="wrapper-sidebar">
        <app-sidebar-component></app-sidebar-component>
      </div>
    
      <div class="main-panel" [class.sidebar-collapsed]="sidebarService.sidebarMini" cdk-scrollable>
        <div class="wrapper-navbar">
          <app-navbar-component></app-navbar-component>
        </div>

        <div class="main-content" [class.pattern-background]="routeHasPatternBackground()" [class.hidden]="lazyLoading">
          <div class="main-content-inner">
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>

    <!-- Request sandbox modal -->
    <ng-container *ngIf="canRequestSandbox">
      <app-modal #modalRequestSandbox [background]="'light'" [width]="'20%'" [minWidth]="'400px'">
        <app-request-sandbox-component></app-request-sandbox-component>
      </app-modal>
    </ng-container>

    <!-- Error Overlays -->
    <ng-container *ngIf="displayOverlay">
      <div class="overlay-wrapper">
        <!-- No Internet Connection -->
        <ng-container *ngIf="status === 0">
          <app-overlay-no-internet-connection-component></app-overlay-no-internet-connection-component>
        </ng-container>
        <!-- Logged Out -->
        <ng-container *ngIf="status === 200">
          <app-overlay-login-component></app-overlay-login-component>
        </ng-container>
        <!-- Server Unavailable -->
        <ng-container *ngIf="status === 502 || status === 503 || status === 504">
          <app-overlay-server-unreachable-component></app-overlay-server-unreachable-component>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="area-banners">
    <app-sse-progress-bar></app-sse-progress-bar>
  
    <!-- Demo Banner -->
    <ng-container *ngIf="displayBanner && (company?.state === eCompanyState.DEMO) && (company?.configuration.showSandboxBanner)">
      <app-banner [style]="eBannerStyle.YELLOW">
        <div class="v-align-middle">
          <span class="fal fa-fw fa-info-circle"></span>
          <span class="m-right-5"></span>
          <span>{{user.firstName}}, you're still on the demo version of frankli.</span>
          <span class="m-right-5"></span>
          <a class="clickable" href="mailto:hello@frankli.io">Upgrade Now</a>
        </div>
      </app-banner>
    </ng-container>
    
    <!-- Public Demo Banner -->
    <ng-container *ngIf="canRequestSandbox">
      <app-banner [style]="eBannerStyle.YELLOW">
        <div class="v-align-middle">
          <span class="fal fa-fw fa-info-circle"></span>
          <span class="m-right-5"></span>
          <span>Hey there! You're interacting with a read-only version of Frankli -&nbsp;</span>
          <a class="clickable" href="https://frankli.io/demo-request/" target="_blank">Request a Product Tour with a member of the Team today</a>
          <span class="m-right-5"></span>
          <span>or create your own sandbox&nbsp;</span>
          <a class="clickable" (click)="startRequestSandbox()">now!</a>
        </div>
      </app-banner>
    </ng-container>
    
    <!-- App Update Banner -->
    <ng-container *ngIf="(updateStatus$ | async)">
      <app-banner [style]="eBannerStyle.BLUE" [canDismiss]="false" [canClick]="true" (click)="reloadApp()">
        <div class="v-align-middle">
          <span class="fal fa-fw fa-redo"></span>
          <span class="m-right-5"></span>
          <span>An update is available. Click here to update Frankli.</span>
        </div>
      </app-banner>
    </ng-container>
  
    <!-- Trial Banner -->
    <ng-container *ngIf="displayBanner && (company?.state === eCompanyState.FULL) && (company?.trial !== null)">
      <app-banner [style]="eBannerStyle.YELLOW">
        <div class="v-align-middle">
          <!-- TODO: Remove function calls from template -->
          <ng-container *ngIf="(getDayDifference() > 1); else templateEndsOneDayOrLess;">
            <span>Your trial will end in {{ getDayDifference() }} days.</span>
          </ng-container>
          <ng-template #templateEndsOneDayOrLess>
            <ng-container *ngIf="(getDayDifference() < 0); else templateEndsTodayOrTomorrow;">
              <span>Your trial has expired</span>
            </ng-container>
            <ng-template #templateEndsTodayOrTomorrow>
              <ng-container *ngIf="(getDayDifference() === 0); else templateEndsTomorrow;">
                <span>Your trial will end today</span>
              </ng-container>
              <ng-template #templateEndsTomorrow>
                <span>Your trial will end in 1 day.</span>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </app-banner>
    </ng-container>
  </div>
</div>
