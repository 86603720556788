import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallbackComponent } from '@app/callback/callback.component';
import { NewsActivityAPIService } from '@app/shared/api/news-activity.api.service';
import { FeatureLockedGuard } from '@app/shared/auth/feature-locked.guard';
import { FeatureLockedComponent } from '@app/shared/feature-locked/feature-locked.component';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Angulartics2Module } from 'angulartics2';
import { ProfileModule } from 'app/profile/profile.module';
import { CustomerAccountAPIService } from 'app/shared/api/customer-account.api.service';
import { FrankliAdminAPIService } from 'app/shared/api/frankli-admin.api.service';
import { PollAPIService } from 'app/shared/api/polls.api.service';
import { SettingsAPIService } from 'app/shared/api/settings.api.service';
import { ErrorService } from 'app/shared/interceptors/error.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { EmployeeModule } from './employee/employee.module';
import { AnalyticsAPIService } from './shared/api/analytics-api.service';
import { CompanyAPIService } from './shared/api/company/company.api.service';
import { CompetencyAPIService } from './domain/competency/api/competency-api.service';
import { NotificationAPIService } from './shared/api/events.api.service';
import { FeedbackAPIService } from './shared/api/feedback.api.service';
import { FileAPIService } from './shared/api/file.api.service';
import { GoalsAPIService } from './shared/api/goals.api.service';
import { IdeaAPIService } from './shared/api/idea.api.service';
import { InterestAPIService } from './domain/interest/api/interest-api.service';
import { OneToOneAPIService } from './domain/one_to_one/api/one-to-one-api.service';
import { RoleAPIService } from './domain/role/api/role-api.service';
import { PraiseAPIService } from './shared/api/praise.api.service';
import { SimilarUsersAPIService } from './shared/api/similar-users.api.service';
import { StatusAPIService } from './shared/api/status.api.service';
import { SurveyAPIService } from './shared/api/survey.api.service';
import { TaskAPIService } from './domain/task/api/task-api.service';
import { UserAPIService } from './shared/api/user.api.service';
import { WebsocketService } from './shared/api/websocket.service';
import { WelcomeAPIService } from './shared/api/welcome.api.service';
import { ArchivedComponent } from './shared/archived/archived.component';
import { AuthAPIService } from './shared/auth/auth.api.service';
import { AuthenticatedGuard } from './shared/auth/authenticated.guard';
import { CanDeactivateGuard } from './shared/auth/can-deactivate.guard';
import { FeatureGuard } from './shared/auth/feature.guard';
import { NewUserGuard } from './shared/auth/newuser.guard';
import { PasswordResetAllowGuard } from './shared/auth/password-reset-allow.guard';
import { PasswordResetGuard } from './shared/auth/password-reset.guard';
import { RoleGuard } from './shared/auth/role.guard';
import { UnauthenticatedGuard } from './shared/auth/unauthenticated.guard';
import { UserAccessRevokedGuard } from './shared/auth/user-access-revoked.guard';
import { UserArchivedGuard } from './shared/auth/user-archived.guard';
import { FeatureNotEnabledComponent } from './shared/feature-not-enabled/feature-not-enabled.component';
import { FooterModule } from './shared/footer/footer.module';
import { HttpInterceptors } from './shared/interceptors/interceptors.service';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { EmployeeLayoutComponent } from './shared/layouts/employee/employee-layout.component';
import { OverlayLoginComponent } from './shared/layouts/employee/overlay-login/overlay-login.component';
import { OverlayNoInternetConnectionComponent } from './shared/layouts/employee/overlay-no-internet-connection/overlay-no-internet-connection.component';
import { OverlayServerUnavailableComponent } from './shared/layouts/employee/overlay-server-unreachable/overlay-server-unreachable.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { NavbarModule } from './shared/navbar/navbar.module';
import { PaginationModule } from './shared/pagination/pagination.module';
import { RevokedComponent } from './shared/revoked/revoked.component';
import { SharedModule } from './shared/shared.module';
import { NavigationSidebarModule } from './shared/sidebar/navigation-sidebar.module';
import { DateService } from './shared/utils/date.service';
import { WindowReferenceService } from './shared/window/windowReference.service';
import { FAQAPIService } from '@app/shared/api/faq.api.service';
import { MockModule } from '@app/mock/mock.module';
import { EmailAPIService } from './shared/api/email.api.service';
import { HttpErrorInterceptor } from '@app/shared/interceptors/http.error.interceptor';
import { GlobalErrorHandler } from '@app/shared/interceptors/global-error-handler';
import { StartUpService, startUpServiceFactory } from '@app/services/start-up.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SwalModule } from '@sweetalert2/ngx-sweetalert2/lib/sweetalert2-loader.service';
import { PermissionAPIService } from './shared/api/permission.api.service';
import { MobileFeatureGuard } from './shared/guards/mobile-feature.guard';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { ScimUserApiService } from '@app/shared/api/scim-user.api.service';
import { CompanyDirectoryApiService } from '@app/shared/api/company-directory.api.service';
import { TasksComponent } from './domain/task/pages/tasks-overview/task-overview.component';
import { TeamsGatewayComponent } from './teams-gateway/teams-gateway.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CompanySecondaryManagerPermissionAPIService } from './shared/api/company/company-secondary-manager-permission.api.service';
import { SecondaryManagerAPIService } from './shared/api/company/secondary-manager.api.service';
import { VideoLibraryAPIService } from './shared/api/video-library.api.service';
import { IntercomService } from './shared/utils/intercom.service';
import { VincereCallbackComponent } from './vincere-callback/vincere-callback.component';
import { VincereUtils } from './shared/utils/vincere.utils';
import { SwalUtils } from './shared/utils/swal.utils';
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular';
import { SetUpStepsAPIService } from './shared/api/company/set-up-steps.api.service';
import { SetUpStepUtils } from './shared/utils/setupstep.utils';
import { TriNetConfigurationApiService } from 'app/shared/api/tri-net-configuration-api.service';
import { TrinetApiService } from 'app/shared/api/trinet.api.service';
import { CatchErrorUtils } from './shared/utils/exception/catch-errors.util';
import { SentryService } from './shared/utils/sentry.service';
import { RegistrationAPIService } from './shared/api/registration.api.service';
import { TaskBusinessService } from './domain/task/service/task-business.service';
import { NotifyUtils } from './shared/utils/notify.utils';
import { ConnectionSettingsBusinessService } from './domain/settings/service/connection-settings/connection-settings-business.service';
import { ContactInformationBusinessService } from './domain/settings/service/contact-information/contact-information-business.service';
import { InterestsBusinessService } from './domain/interest/service/interests-business.service';
import { LocationDetailsBusinessService } from './domain/settings/service/location-details/location-details-business.service';
import { ProfileDetailsBusinessService } from './domain/settings/service/profile-details/profile-details-business.service';
import { SecuritySettingsBusinessService } from './domain/settings/service/security-settings/security-settings-business.service';
import { ProfileDetailsAPIService } from './domain/settings/api/profile-details/profile-details-api.service';
import { LocationDetailsAPIService } from './domain/settings/api/location-details/location-details-api.service';
import { ContactInformationAPIService } from './domain/settings/api/contact-information/contact-information-api.service';
import { ConnectionSettingsAPIService } from './domain/settings/api/connection-settings/connection-settings-api.service';
import { SecuritySettingsAPIService } from './domain/settings/api/security-settings/security-settings-api.service';
import { ZendeskCallbackComponent } from 'app/zendesk-callback/zendesk-callback.component';
import { RoleBusinessService } from './domain/role/service/role-business.service';
import { CareerPathwaysBusinessService } from './domain/career_pathway/service/career-pathway-business.service';
import { CareerPathwayAPIService } from './domain/career_pathway/api/career-pathway-api.service';
import { BrowseRolesBusinessService } from './domain/browse_role/service/browse-role-business.service';
import { BrowseRoleAPIService } from './domain/browse_role/api/browse-role-api.service';
import { QuestionTemplateBusinessService } from './domain/question_template/service/question-template-business.service';
import { QuestionTemplateAPIService } from './domain/question_template/api/question-template-api.service';
import { PdfExportService } from './domain/pdf/service/pdf-export.service';
import { RoleApplicationConfigurationBusinessService } from './domain/role_application_configuration/service/role-application-configuration-business.service';
import { RoleApplicationConfigurationAPIService } from './domain/role_application_configuration/api/role-application-configuration-api.service';
import { RoleApplicationBusinessService } from './domain/role_application/service/role-application-business.service';
import { RoleApplicationAPIService } from './domain/role_application/api/role-application-api.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MicrosoftTeamsService } from './domain/microsoft/service/microsoft-teams.service';
import { ServerSideEventModule } from './domain/server_side_event/module/server-side-event.module';
import { MergeDevAPIService } from 'app/shared/api/mergedev.api.service';
import { TagAPIService } from './domain/tag/service/tag-api.service';
import { MissionStatementAPIService } from './domain/mission_statement/service/mission-statement-api.service';
import { TagBusinessService } from './domain/tag/service/tag-business.service';
import { VisionStatementAPIService } from './domain/vision_statement/service/vision-statement-api.service';
import { MissionStatementBusinessService } from './domain/mission_statement/service/mission-business.service';
import { VisionStatementBusinessService } from './domain/vision_statement/service/vision-business.service';
import { OneToOneBusinessService } from './domain/one_to_one/service/one-to-one-business.service';
import { MicrosoftGraphAPIService } from './domain/microsoftgraph/api/microsoft-graph-api.service';
import { LicensesComponent } from './licenses/licenses.component';
import { EvaluagentAPIService } from './domain/evaluagent/api/evaluagent-api.service';
import { TaskComponentsModule } from './domain/task/module/task-components.module';
import { GoogleAPIService } from './domain/google/api/google-api.service';
import { AbsorbLmsAPIService } from './domain/absorblms/api/absorblms-api.service';
import { ScheduledEventAPIService } from './domain/scheduled_event/api/scheduled-event-api.service';
import { SentimentScaleAPIService } from './domain/sentiment_scale/api/sentiment-scale-api.service';
import { SentimentScaleBusinessService } from './domain/sentiment_scale/service/sentiment-scale-business.service';
import { Globals } from './shared/globals/globals';
import { TerminologyAPIService } from './domain/terminology/service/terminology-api-service.model';
import { TerminologyBusinessService } from './domain/terminology/service/terminology-business.service';

async function provideSwalFn(): Promise<SwalModule> {
  const {default: swal} = await import('sweetalert2');
  return swal.mixin({
    buttonsStyling: true,
    customClass: {
      container: 'modal-content-ngx'
    },
    confirmButtonColor: '#30747F',
  });
}

@NgModule({
  imports: [
    // Angular
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Libraries
    Angulartics2Module,
    TranslateModule.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: provideSwalFn
    }),
    // Frankli
    NavigationSidebarModule,
    NavbarModule,
    CommonModule,
    FooterModule,
    AppRoutingModule,
    EmployeeModule,
    ProfileModule,
    PaginationModule,
    SharedModule,
    TooltipModule.forRoot(),
    environment.mock.enabled ? MockModule : [],
    TaskComponentsModule,
    ScrollingModule,
    ServerSideEventModule
  ],
  declarations: [
    AppComponent,
    CallbackComponent,
    EmployeeLayoutComponent,
    RevokedComponent,
    ArchivedComponent,
    LogoutComponent,
    FeatureNotEnabledComponent,
    FeatureLockedComponent,
    OverlayServerUnavailableComponent,
    OverlayLoginComponent,
    OverlayNoInternetConnectionComponent,
    TeamsGatewayComponent,
    VincereCallbackComponent,
    ZendeskCallbackComponent,
    TasksComponent,
    TeamsGatewayComponent,
    LicensesComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    Globals,
    AuthAPIService,
    RoleGuard,
    NewUserGuard,
    AuthenticatedGuard,
    FeatureLockedGuard,
    SettingsAPIService,
    WindowReferenceService,
    WebsocketService,
    RxStompService,
    DateService,
    PasswordResetGuard,
    PasswordResetAllowGuard,
    FrankliAdminAPIService,
    FeatureGuard,
    UserAccessRevokedGuard,
    UserArchivedGuard,
    MobileFeatureGuard,
    StatusAPIService,
    SidebarService,
    CustomerAccountAPIService,
    UserAPIService,
    CompanyAPIService,
    SurveyAPIService,
    InterestAPIService,
    NotificationAPIService,
    AnalyticsAPIService,
    FeedbackAPIService,
    IdeaAPIService,
    PraiseAPIService,
    FileAPIService,
    WelcomeAPIService,
    FAQAPIService,
    SimilarUsersAPIService,
    GoalsAPIService,
    OneToOneAPIService,
    OneToOneBusinessService,
    CanDeactivateGuard,
    CompetencyAPIService,
    RoleAPIService,
    RoleBusinessService,
    TaskAPIService,
    UnauthenticatedGuard,
    StartUpService,
    NewsActivityAPIService,
    ErrorService,
    PollAPIService,
    EmailAPIService,
    PermissionAPIService,
    RedirectGuard,
    ScimUserApiService,
    TriNetConfigurationApiService,
    TrinetApiService,
    CompanyDirectoryApiService,
    CompanySecondaryManagerPermissionAPIService,
    SecondaryManagerAPIService,
    VideoLibraryAPIService,
    IntercomService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    environment.mock.enabled ? [] :
      [
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
      ],
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: startUpServiceFactory, deps: [StartUpService], multi: true},
    SwalUtils,
    VincereUtils,
    {provide: TraceService, deps: [Router]},
    SetUpStepsAPIService,
    SetUpStepUtils,
    SentryService,
    RegistrationAPIService,
    CatchErrorUtils,
    TaskBusinessService,
    NotifyUtils,
    ProfileDetailsBusinessService,
    ProfileDetailsAPIService,
    LocationDetailsBusinessService,
    LocationDetailsAPIService,
    ContactInformationBusinessService,
    ContactInformationAPIService,
    ConnectionSettingsBusinessService,
    ConnectionSettingsAPIService,
    SecuritySettingsBusinessService,
    SecuritySettingsAPIService,
    InterestsBusinessService,
    CareerPathwaysBusinessService,
    CareerPathwayAPIService,
    BrowseRolesBusinessService,
    BrowseRoleAPIService,
    QuestionTemplateBusinessService,
    QuestionTemplateAPIService,
    RoleApplicationConfigurationBusinessService,
    RoleApplicationConfigurationAPIService,
    RoleApplicationBusinessService,
    RoleApplicationAPIService,
    PdfExportService,
    MicrosoftTeamsService,
    TagAPIService,
    TagBusinessService,
    MissionStatementAPIService,
    MissionStatementBusinessService,
    VisionStatementAPIService,
    VisionStatementBusinessService,
    EvaluagentAPIService,
    AbsorbLmsAPIService,
    MicrosoftGraphAPIService,
    MergeDevAPIService,
    GoogleAPIService,
    ScheduledEventAPIService,
    SentimentScaleAPIService,
    SentimentScaleBusinessService,
    TerminologyAPIService,
    TerminologyBusinessService
  ]
})
export class AppModule {
  constructor(
    private trace: TraceService
  ) {
    return;
  }
}
