import { Component, Input, OnInit } from '@angular/core';
import { CommonMessages } from '@app/constants/common.messages';
import { Competency } from '@app/domain/competency/model/competency.model';
import { Globals } from '@app/shared/globals/globals';
import { PillType } from '../../pill/pill.component';

@Component({
  selector: 'app-competency-display',
  templateUrl: './competency-display.component.html',
  styleUrls: ['./competency-display.component.scss']
})
export class CompetencyDisplayComponent implements OnInit {
  public readonly ePillType = PillType;
  public readonly eCommonMessages = CommonMessages;

  @Input() competency: Competency;

  dropdownExpanded: boolean;

  constructor(
    public globals: Globals
  ) {
    this.competency = undefined!;
    this.dropdownExpanded = false;
  }

  ngOnInit(): void {
  }

  toggleDropdown(): void {
    this.dropdownExpanded = !this.dropdownExpanded;
  }
}
