import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedProfileModule } from 'app/shared/profile/shared-profile.module';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { ProfileComponent } from './profile.component';
import { UserComponent } from './user/user.component';
import { MapsAPIService } from 'app/shared/api/maps.api.service';
import { SurveyFormComponentsModule } from '@app/shared/survey-components/survey-form-components.module';
import { ProfilePraiseFullComponent } from './profile-praise-full/profile-praise-full.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ManagerGoalsComponentsModule } from '@app/manager/sub-modules/manager-goals/manager-goals-components/manager-goals-components.module';
import { GoalsComponentsModule } from '@app/goals/goals-components/goals-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { QualificationComponentsModule } from '@app/domain/qualification/module/qualification-components.module';
import { OneToOneComponentsModule } from '@app/domain/one_to_one/module/one-to-one-components.module';

const components = [
  ProfileComponent,
  UserComponent,
  ProfilePraiseFullComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SweetAlert2Module,
    SharedProfileModule,
    Angulartics2Module,
    SharedModule,
    ManagerGoalsComponentsModule,
    SurveyFormComponentsModule,
    PopoverModule,
    TooltipModule,
    ImageCropperModule,
    GoalsComponentsModule,
    TranslateModule,
    TypeaheadModule.forRoot(),
    QualificationComponentsModule,
    OneToOneComponentsModule
  ],
  declarations: components,
  exports: components,
  providers: [
    MapsAPIService
  ]
})
export class ProfileComponentsModule { }
