import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {User, UserServerSide, UserState} from 'app/models/user/user.model';
import {CreateUserDto} from 'app/models/user/create-user.dto';
import {UserRole} from '@app/models/user-role.model';
import {ProfileDetailsDto} from '@app/domain/settings/model/profile-details.model';
import {catchError, map, tap} from 'rxjs/operators';
import {AdministrateUserDto} from 'app/models/user/administrate-user.dto';
import {LocationDetailsDto} from '@app/domain/settings/model/location-details.model';
import {environment} from 'environments/environment';
import {Globals} from '../globals/globals';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {ChangePasswordDto} from 'app/models/user/change-password.dto';
import {CompanyAPIService} from './company/company.api.service';
import {UserMinimal} from '@app/models/user/user-minimal.model';
import {UserService} from '@app/shared/api/interfaces/user.service';
import {AdministrateActiveDirectoryUserDto} from '@app/models/user/administrate-active-directory-user.dto';
import {PagingParams} from '@app/models/api/paging-params.model';
import {ParentFilter} from '@app/models/api/parent-filter.model';
import {SortingParams} from '@app/models/api/sorting-params.model';
import {ApiUtils} from '@app/shared/utils/api.utils';
import {Page} from '@app/models/api/page.model';
import {PeopleDirectoryCountOverview} from '@app/models/user/people-directory-count-overview';
import {UserSimilar} from '@app/models/user/user-similar.model';
import {UserInit, UserInitServerSide} from '@app/models/auth/user-init.model';
import {ResetPasswordResponse} from '@app/models/auth/reset-password-response.model';
import {UpdateUserPreferredLocaleDto} from '@app/models/user/update-user-preferred-locale.dto';
import {UserConfiguration} from '@app/models/user/user-configuration.model';
import {Site} from '@app/models/site.model';
import {validTimezones} from '@app/constants/timezone.constants';
import {ProvisionedUser} from '@app/models/user/provisioned-user.model';
import {AdministrateTriNetUserDto} from '@models/user/administrate-tri-net-user.dto';
import {AdministrateSageHrUserDto} from '@models/user/administrate-sage-hr-user.dto';
import { PasswordToken } from '@app/models/user/password-token.model';
import { PasswordReset } from '@app/models/user/password-reset.model';
import { ContactInformationDto } from '@app/domain/settings/model/contact-information.model';
import { Interest } from '@app/domain/interest/model/interest.model';
import { EmployeeHierarchy } from '@models/user/employee-hierarchy.model';
import { ExternalProvisioner } from '@app/models/user/external-provisioner';
import { AdministrateMergeDevUserDto } from '@app/models/user/administrate-merge-dev-user.dto';

@Injectable()
export class UserAPIService implements UserService {

  private readonly BASE_URL = 'api/user/';
  httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    public globals: Globals,
    private angulartics2Amplitude: Angulartics2Amplitude
  ) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
  }


  getMe(): Observable<User> {
    const url = this.BASE_URL + 'me';
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  getById(id: number): Observable<User> {
    const url = this.BASE_URL + id;
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  getAllUsers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all';
    return this.http.get<Array<User>>(url);
  }

  getAllUsersMinimal(): Observable<Array<UserMinimal>> {
    const url = this.BASE_URL + 'all/minimal';
    return this.http.get<Array<UserMinimal>>(url);
  }

  /**
   * Returns a list of all active, pending and invited users other than the currently logged in user
   */
  getAllOtherUsers(): Observable<Array<User>> {
    const url = '/api/user/other';
    return this.http.get<Array<User>>(url);
  }

  /**
   * Returns a list of all active, pending and invited users other than the currently
   * logged in user with their manager's name populated
   */
  getOtherUsersAndManagers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all/manager';
    return this.http.get<Array<User>>(url);
  }

  getAllUsersOnboarded(): Observable<Array<User>> {
    const url = this.BASE_URL + 'onboarded';
    return this.http.get<Array<User>>(url);
  }

  getPeopleDirectoryCountOverview(): Observable<PeopleDirectoryCountOverview> {
    const url = this.BASE_URL + 'directory/counts';
    return this.http.get<PeopleDirectoryCountOverview>(url);
  }

  countAllUsersOnboarded(): Observable<number> {
    const url = this.BASE_URL + 'onboarded/count';
    return this.http.get<number>(url);
  }

  create(user: CreateUserDto): Observable<UserServerSide> {
    const url = this.BASE_URL;
    return this.http.post<UserServerSide>(url, user, {headers: this.httpHeaders});
  }

  /**
   * Creates an individual bulk upload user (must have dependencies sorted by bulk upload service first)
   * @param user
   */
  createBulkUploadUser(user: CreateUserDto): Observable<UserServerSide> {
    const url = `${this.BASE_URL}bulk-upload`;
    return this.http.post<UserServerSide>(url, user, {headers: this.httpHeaders});
  }

  /**
   * Creates users from a list of bulk upload users
   * @param users
   */
  createAllBulkUploadUsers(users: Array<CreateUserDto>): Observable<Array<UserServerSide>> {
    const url = `${this.BASE_URL}bulk-upload/all`;
    return this.http.post<Array<UserServerSide>>(url, users, {headers: this.httpHeaders});
  }


  getAllRoles(): Observable<Array<UserRole>> {
    const url = this.BASE_URL + 'roles';
    return this.http.get<Array<UserRole>>(url);
  }

  updatePasswordManual(changePasswordDto: ChangePasswordDto): Observable<User> {
    const url = `${this.BASE_URL}change-password`;
    return this.http.post<UserServerSide>(url, changePasswordDto).pipe(map(u => new User(u)));
  }

  updatePassword(passwordReset: PasswordReset): Observable<any> {
    const url = `${this.BASE_URL}reset-password`;
    return this.http.post(url, passwordReset);
  }

  setPassword(passwordReset: PasswordReset): Observable<any>{
    const url = `${this.BASE_URL}set-password`;
    return this.http.post(url, passwordReset);
  }

  updateUserCity(city: string): Observable<User> {
    const url = this.BASE_URL + 'city';
    return this.http.put<UserServerSide>(url, city).pipe(map(u => new User(u)));
  }

  updateUserOfficeLocation(officeLocation: Site | null): Observable<User> {
    const url = this.BASE_URL + 'office-location';
    return this.http.put<UserServerSide>(url, officeLocation).pipe(map(u => new User(u)));
  }

  getUsersSimilar(pagingParams: PagingParams): Observable<Page<UserSimilar>> {
    const url = this.BASE_URL + 'similar';
    const params = ApiUtils.createPageParams(pagingParams);
    return this.http.get<Page<UserSimilar>>(url, { params });
  }

  getUsersByManagerMe(): Observable<Array<User>> {
    const url = this.BASE_URL + 'manager/me';
    return this.http.get<Array<User>>(url);
  }

  getUsersBySecondaryManagerMe(): Observable<User[]> {
    const url = this.BASE_URL + 'secondary-manager/me';
    return this.http.get<Array<User>>(url);
  }

  getUsersByManagerId(managerId: number): Observable<Array<User>> {
    const url = this.BASE_URL + 'manager/' + managerId;
    return this.http.get<Array<User>>(url);
  }

  searchUsersPaginated(pagingParams: PagingParams, sortingParams: SortingParams, parentFilter: ParentFilter): Observable<Page<UserMinimal>> {
    const url = this.BASE_URL + 'search/paginated';
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    return this.http.post<Page<UserMinimal>>(url, parentFilter , { params });
  }

  searchUsersPaginatedNotInActiveEvaluationCycle(pagingParams: PagingParams, sortingParams: SortingParams, parentFilter: ParentFilter): Observable<Page<UserMinimal>> {
    const url = this.BASE_URL + 'search/paginated/not-in-evaluation';
    const params = ApiUtils.createPageAndSortParams(pagingParams, sortingParams);
    return this.http.post<Page<UserMinimal>>(url, parentFilter, { params });
  }

  updateLocationDetails(user: LocationDetailsDto): Observable<User> {
    const url = this.BASE_URL + 'update/location-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updatePersonalDetails(user: ContactInformationDto): Observable<User> {
    const url = this.BASE_URL + 'update/personal-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updateProfileDetails(user: ProfileDetailsDto): Observable<User> {
    const url = this.BASE_URL + 'update/profile-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updateProfilePicture(file: any, fileExtension: string): Observable<User> {
    const url = this.BASE_URL + 'update/profile-picture';

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileExtension', fileExtension);

    return this.http.post<UserServerSide>(url, formData).pipe(map(u => new User(u)));
  }

  updateInterests(interests: Array<Interest>): Observable<User> {
    const url = this.BASE_URL + 'update/interests';
    return this.http.post<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  updateSkills(skills: Array<Interest>): Observable<User> {
    const url = this.BASE_URL + 'update/skills';
    return this.http.post<UserServerSide>(url, skills).pipe(map(u => new User(u)));
  }

  resetUserPassword(email: string): Observable<ResetPasswordResponse> {
    const url = this.BASE_URL + 'password/reset';
    return this.http.post<ResetPasswordResponse>(url, email);
  }

  getUserHash() {
    const url = this.BASE_URL + 'hash';
    return this.http.get(url, {responseType: 'text'});
  }


  /**
   * Gets the app initialisation details and updates amplitude
   */
  getUserInit(): Observable<UserInit> {
    const url = this.BASE_URL + 'init';

    return this.http.get<UserInitServerSide>(url).pipe(
      map(userInitServerSide => new UserInit(userInitServerSide)),
      tap((init: UserInit) => {
        this.globals.init(init);
        if (environment.production) {
          this.angulartics2Amplitude.setUsername(this.globals.user.email);
        }

        this.setTimezone(this.globals.user).subscribe(
          (user) => {
            this.globals.updateUser(user);
          }
        );
      }),
      catchError((error: HttpErrorResponse) => {
        // This should stop some sentry issue w/ localization service trying to change date after re-auth has failed
        if (error.status === 403) {
          this.globals.clearKeepToken();
        }

        return throwError(error);
      })
    );
  }

  archiveUser(userId: number): Observable<User> {
    const url = this.BASE_URL + 'archive/' + userId;
    return this.http.post<User>(url, null);
  }

  unarchiveUser(userId: number, role: UserRole): Observable<User> {
    const url = this.BASE_URL + 'unarchive/' + userId;
    return this.http.post<User>(url, role);
  }

  getAllUsersAdmin(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all/admin';
    return this.http.get<Array<User>>(url);
  }

  revokeAccess(userId: number): Observable<User> {
    const url = this.BASE_URL + 'revoke/' + userId;
    return this.http.post<User>(url, userId);
  }

  unrevokeAccessUser(userId: number, role: UserRole): Observable<User> {
    const url = this.BASE_URL + 'unrevoke/' + userId;
    return this.http.post<User>(url, role);
  }

  administrateUser(userId: number, administrateUserDto: AdministrateUserDto) {
    const url = this.BASE_URL + 'administrate/' + userId;
    return this.http.post<User>(url, administrateUserDto);
  }

  administrateActiveDirectoryUser(userId: number, administrateActiveDirectoryDto: AdministrateActiveDirectoryUserDto): Observable<User> {
    const url = this.BASE_URL + 'administrate/active-directory/' + userId;
    return this.http.post<User>(url, administrateActiveDirectoryDto);
  }

  administratePeopleHrUser(userId: number, roleId: number): Observable<User> {
    const url = this.BASE_URL + 'administrate/people-hr/' + userId;
    return this.http.post<User>(url, roleId);
  }

  administrateTriNetUser(userId: number, administrateTriNetUserDto: AdministrateTriNetUserDto): Observable<User> {
    const url = this.BASE_URL + 'administrate/tri-net/' + userId;
    return this.http.post<User>(url, administrateTriNetUserDto);
  }

  administrateSageHrUser(userId: number, administrateSageHrUserDto: AdministrateSageHrUserDto): Observable<User> {
    const url = this.BASE_URL + 'administrate/sage-hr/' + userId;
    return this.http.post<User>(url, administrateSageHrUserDto);
  }

  administrateMergeDevUser(userId: number, administrateMergeDevUserDto: AdministrateMergeDevUserDto): Observable<User> {
    const url = this.BASE_URL + 'administrate/merge-dev/' + userId;
    return this.http.post<User>(url, administrateMergeDevUserDto);
  }

  /**
   * Sends a user an invite email provided their status is pending or invited
   */
  inviteUser(userId: number): Observable<User> {
    const url = this.BASE_URL + 'invite/' + userId;
    return this.http.post<User>(url, null);
  }

  /**
   * Sends an invite email to all pending users
   */
  inviteAllPendingUsers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'invite/all';
    return this.http.post<Array<User>>(url, null);
  }

  userRefresh(): Observable<any> {
    const url = this.BASE_URL + 'refresh';
    return this.http.post<any>(url, null);
  }

  getUsersOnboardedNotInCycles(): Observable<Array<User>> {
    const url = this.BASE_URL + 'evaluation';
    return this.http.get<Array<User>>(url);
  }

  // Socialise
  // TODO: Move to socialise s
  getUsersByInterestId(id: number): Observable<Array<User>> {
    const url = `${this.BASE_URL}interests/${id}`;
    return this.http.get<Array<UserServerSide>>(url).pipe(map(u => this.usersServerToClient(u)));
  }

  getUsersSocialise(): Observable<Array<User>> {
    const url = `${this.BASE_URL}socialise`;
    return this.http.get<Array<UserServerSide>>(url).pipe(map(u => this.usersServerToClient(u)));
  }

  getUserInterestsSocialise(): Observable<Interest[]> {
    const url = `${this.BASE_URL}interests/socialise`;
    return this.http.get<Interest[]>(url);
  }

  getUsersWithCommonSocialiseInterests(): Observable<User[]> {
    const url = `${this.BASE_URL}interests/socialise/common`;
    return this.http.get<User[]>(url);
  }

  updateUserInterestsSocialise(interestIds: number[]): Observable<User> {
    const url = `${this.BASE_URL}interests/socialise`;
    return this.http.put<UserServerSide>(url, interestIds)
      .pipe(map(u => new User(u)));
  }

  updateUserInterestsCoach(interests: Array<Interest>) {
    const url = `${this.BASE_URL}interests/coach`;
    return this.http.put<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  updateUserInterestsMentor(interests: Array<Interest>) {
    const url = `${this.BASE_URL}interests/mentor`;
    return this.http.put<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  frankliAdminExportAllManagers(): Observable<any> {
    const url = this.BASE_URL + 'frankli-admin/export/manager';
    return this.http.get(url, {responseType: 'blob'});
  }

  frankliAdminExportAllAdmins(): Observable<any> {
    const url = this.BASE_URL + 'frankli-admin/export/admin';
    return this.http.get(url, {responseType: 'blob'});
  }

  private usersServerToClient(users: Array<UserServerSide>) {
    const usersReturn = new Array<User>();
    users.forEach(u => {
      const user = new User(u);
      usersReturn.push(user);
    });
    return usersReturn;
  }

  isSlackAccountLinked(): Observable<boolean> {
    const url = this.BASE_URL + 'integrations/slack/linked';
    return this.http.get<boolean>(url);
  }

  isTeamsAccountLinked(): Observable<boolean> {
    const url = this.BASE_URL + 'integrations/teams/linked';
    return this.http.get<boolean>(url);
  }

  getUserByEmail(email: string): Observable<User> {
    const url = `${this.BASE_URL}email?address=${email}`;
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  updateUserPreferredLocale(updateUserPreferredLocaleDto: UpdateUserPreferredLocaleDto): Observable<UserConfiguration> {
    const url = this.BASE_URL + 'configuration/locale';
    return this.http.put<UserConfiguration>(url, updateUserPreferredLocaleDto);
  }

  getEveryMinimalUserInCompany(): Observable<Array<UserMinimal>> {
    const url = this.BASE_URL + 'every-min';
    return this.http.get<Array<UserMinimal>>(url);
  }

  getDirectReportsForUser(userId: number): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}${userId}/direct-reports`;
    return this.http.get<UserMinimal[]>(url);
  }

  setTimezone(user?: User): Observable<User> {
    let timezoneChanged = false;
    let timezone = undefined;

    // If user passed in, use their timezone
    if (user && user.homeAddress && user.homeAddress.timezone) {
      timezone = user.homeAddress.timezone;
    }

    // If timezone is invalid the first time, clear it
    if (!validTimezones.includes(timezone)) {
      timezone = undefined;
    }

    // If there's no timezone, guess it
    if (!timezone) {
      timezoneChanged = true;

      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {
        timezone = 'UTC';
      }
    }

    // Make sure timezone is valid
    if (!validTimezones.includes(timezone)) {
      timezoneChanged = true;
      timezone = 'UTC';
    }

    // If nothing has changed and a valid user was passed in, return that
    if (!timezoneChanged && user) {
      return of(user);
    }

    // Otherwise, update the timezone
    const url = `${this.BASE_URL}timezone`;
    return this.http.post<UserServerSide>(url, timezone).pipe(map(u => new User(u)));
  }

  doFirstTimeLogin(): Observable<boolean> {
    const url = `${this.BASE_URL}first-time-login`;
    return this.http.get<boolean>(url).pipe(map(result => {
      this.globals.user.userState = UserState.FULL;
      return result;
    }));
  }

  getAllProvisionedUsers(): Observable<ProvisionedUser[]> {
    const url = `${this.BASE_URL}externally-provisioned`;
    return this.http.get<ProvisionedUser[]>(url);
  }

  getAllProvisionedUsersByProvisioner(externalProvisioner: ExternalProvisioner): Observable<ProvisionedUser[]> {
    const url = `${this.BASE_URL}externally-provisioned/provisioner/${externalProvisioner}`;
    return this.http.get<ProvisionedUser[]>(url);
  }

  getProvisionedUserByUserId(userId: number): Observable<ProvisionedUser> {
    const url = `${this.BASE_URL}externally-provisioned/${userId}`;
    return this.http.get<ProvisionedUser>(url);
  }

  checkPasswordToken(passwordToken: string){
    const url = `${this.BASE_URL}check-password-token?passwordToken=${passwordToken}`;
    return this.http.get<PasswordToken>(url);
  }

  findAllDescendantsForUserId(userId: number){
    const url = `${this.BASE_URL}descendants/${userId}`;
    return this.http.get<Array<EmployeeHierarchy>>(url);
  }

  getUsersWithDirectReports(): Observable<UserMinimal[]> {
    const url = `${this.BASE_URL}with-direct-reports`;
    return this.http.get<UserMinimal[]>(url);
  }

  exportUsersToCSVById(userIds: number[]): Observable<Blob> {
    const url = `${this.BASE_URL}export-to-csv`;
    return this.http.post(url, userIds, {responseType: 'blob'});
  }

  getUsersByIds(userIds: number[]): Observable<User[]> {
    const url = `${this.BASE_URL}by-ids`;
    return this.http.post<UserServerSide[]>(url, userIds).pipe(map(users => users.map(u => new User(u))));
  }
}
