import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneSchedule } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { AssessmentGroupInfoFiltered } from '@app/models/evaluation/assessment-group-info.model';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationFeedbackRequestState } from '@app/models/evaluation/evaluation-feedback-request-state.model';
import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { IState } from '@app/models/state/state.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { EvaluationCycleAPIService } from '@app/shared/api/evaluation-cycle.api.service';
import { ButtonGroupOption } from '@app/shared/components/inputs/button-group/button-group.component';
import { Globals } from '@app/shared/globals/globals';
import { EvaluationUtils } from '@app/shared/utils/evaluation.utils';
import { forkJoin } from 'rxjs';

type TabEntity = EvaluationCycle;

enum ReviewTabType {
  SELF_REFLECTION = 'SELF_REFLECTION',
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  // TODO: Add peer and upward reviews too
}

@Component({
  selector: 'app-information-sidebar-reviews',
  templateUrl: './information-sidebar-reviews.component.html',
  styleUrls: ['./information-sidebar-reviews.component.scss']
})
export class InformationSidebarReviewsComponent implements OnInit {
  public readonly eEvaluationFeedbackRequestState = EvaluationFeedbackRequestState;
  public readonly eEvaluationModuleType = EvaluationModuleType;
  public readonly eSurveyQuestionType = SurveyQuestionType;
  public readonly eCommonMessages = CommonMessages;
  public readonly eReviewTabType = ReviewTabType;

  @Input() schedule: OneToOneSchedule;

  state: IState;

  controlTab: FormControl;
  buttonGroupOptions: ButtonGroupOption[];

  evaluationCycle: EvaluationCycle;
  assessmentGroupsFiltered: AssessmentGroupInfoFiltered;
  selfReflection?: SelfReflection;
  managerReview?: EvaluationFeedback;

  stepsActive: EvaluationModuleType[];

  get hasData(): boolean {
    if (this.selfReflection) { return true; }
    if (this.managerReview) { return true; }
    return false;
  }

  constructor(
    public globals: Globals,
    private evaluationCycleAPIService: EvaluationCycleAPIService
  ) {
    this.evaluationCycle = undefined;
    this.selfReflection = undefined;
    this.managerReview = undefined;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.assessmentGroupsFiltered = new AssessmentGroupInfoFiltered([], false);

    this.controlTab = this.initControlTab();
    this.buttonGroupOptions = this.getButtonGroupOptions([EvaluationModuleType.SELF_REFLECTION, EvaluationModuleType.MANAGER_REVIEW]);
  }

  ngOnInit(): void {
    this.getData();
  }

  getButtonGroupOptions(stepsActive: EvaluationModuleType[]): ButtonGroupOption[] {
    const options: ButtonGroupOption[] = [];
    
    if (stepsActive.includes(EvaluationModuleType.SELF_REFLECTION)) {
      options.push({
        value: ReviewTabType.SELF_REFLECTION,
        friendlyText: 'Self reflection',
        iconClasses: undefined,
        color: undefined
      });
    }

    if (stepsActive.includes(EvaluationModuleType.MANAGER_REVIEW)) {
      options.push({
        value: ReviewTabType.MANAGER_REVIEW,
        friendlyText: 'Manager review',
        iconClasses: undefined,
        color: undefined
      });
    }

    return options;
  }

  getData(): void {
    if (!this.schedule) { return; }
    if (!this.schedule.evaluationCycleId) { return; }
    if (!this.schedule.participants) { return; }
    if (this.schedule.participants.length > 1) { return; }
    
    forkJoin([
      this.evaluationCycleAPIService.getEvaluationCycleById(this.schedule.evaluationCycleId),
      this.evaluationCycleAPIService.getAssessmentGroupInfoForCycle(this.schedule.evaluationCycleId)
    ]).subscribe(([cycle, assessmentGroupInfo]) => {
      this.evaluationCycle = cycle;
      this.assessmentGroupsFiltered = new AssessmentGroupInfoFiltered(assessmentGroupInfo, false);

      this.stepsActive = cycle.modules.map(m => m.evaluationModuleType);

      this.getAnswers();

      this.buttonGroupOptions = this.getButtonGroupOptions(this.stepsActive);
      const defaultTab = this.getDefaultTabForCycle(cycle);
      this.controlTab.setValue(defaultTab);
    });

    // this.evaluationCycleAPIService.getSidebarCycles(this.userIds)
    //   .subscribe(data => {
    //     data = data.filter(d => d.users.includes(this.otherUserId));
    //     data = this.sortData(data);
    //     this.populateData(data);
    //     this.state.loading = false;
    //   });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.name}`;
      const sortStringB = `${b.name}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }

  getDefaultTabForCycle(evaluationCycle: EvaluationCycle): ReviewTabType {
    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.SELF_REFLECTION)) {
      return ReviewTabType.SELF_REFLECTION;
    }
    
    if (EvaluationUtils.cycleHasModule(evaluationCycle, EvaluationModuleType.MANAGER_REVIEW)) {
      return ReviewTabType.MANAGER_REVIEW;
    }

    return null; 
  }

  initControlTab(tab?: ReviewTabType): FormControl {
    const formControl = new FormControl(ReviewTabType.SELF_REFLECTION);

    if (tab) {
      formControl.setValue(tab);
    }

    // formControl.valueChanges.subscribe((value: ReviewTabType) => this.getAnswers(value));

    return formControl;
  }

  getAnswers(): void {
    const scheduleManagerId = this.schedule.manager.id;
    const scheduleParticipantId = this.schedule.participants[0].id;

    const currentUserIsScheduleManager = (this.globals.user.id === scheduleManagerId);

    if (currentUserIsScheduleManager) {
      this.evaluationCycleAPIService.getEvaluationUserByCycleIdAndUserId(this.schedule.evaluationCycleId!, scheduleParticipantId)
        .toPromise()
        .then(evaluationUser => {
          if (evaluationUser.selfReflection) {
            evaluationUser.selfReflection = this.parseSelfReflection(evaluationUser.selfReflection);
          }
          this.selfReflection = evaluationUser.selfReflection;

          let managerReview = EvaluationUtils.getManagerReviewResponseFromEvaluationUser(evaluationUser);
          if (managerReview) {
            managerReview = this.parseManagerReview(managerReview);
          }

          this.managerReview = managerReview;
          this.state.loading = false;
        });
      return;
    }

    forkJoin([
      this.evaluationCycleAPIService.getSelfReflectionForCycleAndUser(this.schedule.evaluationCycleId!, scheduleParticipantId),
      this.evaluationCycleAPIService.getManagerReviewForUserMe(this.schedule.evaluationCycleId!)
    ])
      .toPromise()
      .then(([selfReflection, managerReview]) => {
        if (selfReflection) {
          selfReflection = this.parseSelfReflection(selfReflection);
        }
        this.selfReflection = selfReflection;

        if (managerReview) {
          managerReview = this.parseManagerReview(managerReview);
        }
        this.managerReview = managerReview;
        this.state.loading = false;
      });
  }

  parseSelfReflection(selfReflection: SelfReflection): SelfReflection {
    if (!selfReflection) { return selfReflection; }

    selfReflection.selfQuestionsAnswered.map(answer => {
      const question = this.evaluationCycle!.selfReflectionQuestions.find(y => y.id === answer.questionId)!;
      if (question) {
        answer.question = question;
      }

      return answer;
    });

    return selfReflection;
  }

  parseManagerReview(managerReview: EvaluationFeedback): EvaluationFeedback {
    if (!managerReview) { return managerReview; }

    managerReview.questionsAnswered.map(answer => {
      const question = this.evaluationCycle!.managerReviewQuestions.find(y => y.id === answer.questionId)!;
      if (question) {
        answer.question = question;
      }

      return answer;
    });

    return managerReview;
  }

  // TODO: Do this with pre-processing instead of calling it from the UI
  getAnswerForScale(step: EvaluationModuleType, question: EvaluationCycleQuestion, answer: string): string {
    if (question.assessmentQuestion) {
      const stepAnswers = this.assessmentGroupsFiltered.assessmentGroupInfo[step];

      if (stepAnswers) {
        const typeAnswers = stepAnswers[question.questionBankQuestion.surveyQuestionType];

        if (typeAnswers) {
          const value = typeAnswers.find(ag => ag.value === answer);

          if (value) {
            return `${value.groupTitle} (${answer})`;
          }
        }
      }
    }

    return 'Unknown';
  }
}
