import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CommonMessages} from '@app/constants/common.messages';
import {IState} from '@app/models/state/state.model';
import {ModalComponent} from '@app/shared/modal/modal.component';
import {TasksMessages} from '@app/domain/task/locale/tasks.messages';
import {forkJoin, of} from 'rxjs';
import {ButtonGroupOption} from '../../inputs/button-group/button-group.component';
import { TaskAlignmentType } from '@app/domain/task/model/task-alignment-type.enum';
import { TaskComment } from '@app/domain/task/model/task-comment.model';
import { TaskStatus } from '@app/domain/task/model/task-status.enum';
import { Task } from '@app/domain/task/model/task.model';
import { TaskBusinessService } from '@app/domain/task/service/task-business.service';

interface PageState extends IState {
  submitting: boolean;
}

@Component({
  selector: 'app-sidebar-tasks',
  templateUrl: './sidebar-tasks.component.html',
  styleUrls: ['./sidebar-tasks.component.scss']
})
export class SidebarTasksComponent implements OnInit, OnChanges {
  public readonly eTasksMessages = TasksMessages;

  @ViewChild('modalViewTask') modalViewTask: ModalComponent;

  @Input() userId: number;
  @Input() oneToOneScheduleId?: number;

  _tasks: Task[];

  taskViewing?: Task;

  state: PageState;

  statusOptions: ButtonGroupOption[];
  controlTaskStatus: FormControl;
  controlTaskArchived: FormControl;

  get tasks(): Task[] {
    if (!this._tasks) {
      return [];
    }

    // Don't show tasks associated with the currently viewed 1:1 schedule
    if (this.oneToOneScheduleId) {
      return this._tasks.filter(r => !((r.alignmentType === TaskAlignmentType.ONE_TO_ONE) && (r.alignmentId === this.oneToOneScheduleId)));
    }

    return this._tasks;
  }

  constructor(
      private taskBusinessService: TaskBusinessService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
      submitting: false
    };

    this.statusOptions = this.getStatusOptions();
    this.controlTaskStatus = this.initControlStatus();
    this.controlTaskArchived = this.initControlArchived();

    this._tasks = [];

    this.oneToOneScheduleId = undefined;

  }

  ngOnInit(): void {
    this.getData(this.userId, this.controlTaskStatus.value, this.controlTaskArchived.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userIdChanges = changes['userId'];

    if (userIdChanges && !userIdChanges.firstChange) {
      this.getData(this.userId, this.controlTaskStatus.value, this.controlTaskArchived.value);
    }
  }

  getData(userId: number, status: TaskStatus, archived: boolean): void {
    const statusAsArray: TaskStatus[] = (status ? [status] : []);

    if (archived === true) { // Don't just show archived tasks, show all
      archived = null;
    }

    forkJoin({
      tasks: (userId ? this.taskBusinessService.getTasks(null, [], [userId], statusAsArray, [], [], archived) : of([]))
    }).subscribe(({
      tasks
    }) => {
      this._tasks = tasks;

      this.state.loading = false;
    });
  }

  onTaskAction(action: string, task: Task): void {
    switch (action) {
      case 'view':
        this.startViewTask(task);
        break;
    }
  }

  startViewTask(task: Task): void {
    if (!task) {
      return;
    }

    this.taskViewing = task;

    setTimeout(() => {
      if (this.modalViewTask) {
        this.modalViewTask.show();
      }
    }, 1);
  }

  getStatusOptions(): ButtonGroupOption[] {
    return [
      {
        value: null,
        friendlyText: CommonMessages.ALL,
        // color?: string
      },
      {
        value: TaskStatus.NOT_STARTED,
        friendlyText: TasksMessages.STATUS_NOT_STARTED,
        // color?: string
      },
      {
        value: TaskStatus.IN_PROGRESS,
        friendlyText: TasksMessages.STATUS_IN_PROGRESS,
        // color?: string
      },
      {
        value: TaskStatus.COMPLETE,
        friendlyText: TasksMessages.STATUS_COMPLETE,
        // color?: string
      }
    ];
  }

  initControlStatus(): FormControl {
    const formControl = new FormControl(null, []);

    formControl.valueChanges.subscribe(val => this.onFilterStatusChanged(val));

    return formControl;
  }

  onFilterStatusChanged(val: TaskStatus): void {
    this.getData(this.userId, val, this.controlTaskArchived.value);
  }

  initControlArchived(): FormControl {
    const formControl = new FormControl(false, []);

    formControl.valueChanges.subscribe(val => this.onFilterArchivedChanged(val));

    return formControl;
  }

  onFilterArchivedChanged(val: boolean): void {
    this.getData(this.userId, this.controlTaskStatus.value, val);
  }

  onViewingStatusChanged(status: TaskStatus): void {
    if (this.state.submitting) {
      return;
    }
    this.state.submitting = true;

    this.taskBusinessService.updateStatusOfTask(this.taskViewing.id, status)
      .then(res => {
        this.taskViewing.status = status;
        this.updateTaskInList(this.taskViewing);
      })
      .finally(() => {
        this.state.submitting = false;
      });
  }

  onViewingCommentAdded(comment: TaskComment): void {
    this.taskViewing.comments = [...this.taskViewing.comments, comment];
    this.updateTaskInList(this.taskViewing);
  }

  onViewingCommentDeleted(comment: TaskComment): void {
    this.taskViewing.comments = this.taskViewing.comments.filter(c => (c.id !== comment.id));
    this.updateTaskInList(this.taskViewing);
  }

  updateTaskInList(task: Task): void {
    this._tasks = this._tasks.map(t => ((t.id === task.id) ? task : t));
  }
}
