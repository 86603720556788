<div class="container-profile-one-to-ones">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error">
      <div class="content">
        <h4 class="standard-heading">{{eOneToOneMessages.USERS_SCHEDULES | translate: { firstName: user.firstName } }}</h4>
        <div class="standard-description">{{eOneToOneMessages.USERS_SCHEDULES_DESCRIPTION | translate: { firstName: user.firstName } }}</div>
      </div>

      <hr class="m-top-0 m-bottom-0"/>

      <ng-container *ngIf="schedules.length > 0; else templateNoSchedules;">
          <div class="schedules-list">
            <ng-container *ngFor="let schedule of schedules; index as index; first as isFirst; last as isLast;">
              <div class="card no-margin">
                <div class="content">
                  <div class="schedule-item">
                    <div class="display-participant">
                      <app-person-picture-list [users]="schedule.participants"></app-person-picture-list>
                    </div>
                    <div class="display-purpose">
                      <h5 class="standard-heading">
                        <div>{{schedule.purposeTitle}} with {{schedule.participants[0].firstName}} {{schedule.participants[0].lastName}}</div>
                        <div class="standard-description">
                          <small>{{schedule.frequency}}&nbsp;</small>
                          <small>for&nbsp;</small>
                          <small [ngSwitch]="schedule.meetingLength">
                            <ng-container *ngSwitchCase="eOneToOneMeetingLength.FIFTEEN_MINUTES">15 minutes</ng-container>
                            <ng-container *ngSwitchCase="eOneToOneMeetingLength.THIRTY_MINUTES">30 minutes</ng-container>
                            <ng-container *ngSwitchCase="eOneToOneMeetingLength.FORTY_FIVE_MINUTES">45 minutes</ng-container>
                            <ng-container *ngSwitchCase="eOneToOneMeetingLength.SIXTY_MINUTES">1 hour</ng-container>
                          </small>
                        </div>
                      </h5>
                    </div>
                    <div class="display-actions">
                      <app-table-action-icon [icon]="'fa-calendar-circle-user'" [hoverColor]="eIconHoverColor.RED" [tooltip]="eOneToOneMessages.TRANSFER_SCHEDULE | translate" [triggers]="globals.tooltipTriggers" placement="left" container="body" (click)="startTransferSchedule(schedule)"></app-table-action-icon>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="state.scheduleTransferring">
            <app-modal #modalTransferSchedule (modalHidden)="onModalHiddenTransfer()">
              <app-transfer-schedule [canTransferToSelf]="true" [oneToOneSchedule]="state.scheduleTransferring" (onSuccess)="onTransferSuccess()"></app-transfer-schedule>
            </app-modal>
          </ng-container>
      </ng-container>
      <ng-template #templateNoSchedules>
          <app-empty-state [height]="'200px'" [message]="'No 1:1 schedules found'"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>
