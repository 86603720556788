import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Competency } from '@app/domain/competency/model/competency.model';
import { CompetencyBusinessService } from '@app/domain/competency/service/competency-business.service';
import { IState } from '@app/models/state/state.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

type TabEntity = Competency;

@Component({
  selector: 'app-information-sidebar-competencies',
  templateUrl: './information-sidebar-competencies.component.html',
  styleUrls: ['./information-sidebar-competencies.component.scss']
})
export class InformationSidebarCompetenciesComponent implements OnInit {
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };

  constructor(
    private competencyBusinessService: CompetencyBusinessService
  ) {
    this.userIds = [];

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    this.competencyBusinessService.getCompetencies(null, null, null)
      .subscribe(data => {
        data = this.sortData(data);
        this.populateData(data);
        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    // Order by name
    return data.sort((a, b) => {
      const sortStringA = `${a.name}`;
      const sortStringB = `${b.name}`;
      return sortStringA.localeCompare(sortStringB);
    });
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }
}
