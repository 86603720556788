import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonMessages } from '@app/constants/common.messages';
import { OneToOneMeetingMinimal } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { GoalType } from '@app/models/goals/goal-type.enum';
import { GoalVisibility } from '@app/models/goals/goal-visibility.model';
import { Goal } from '@app/models/goals/goal.model';
import { IState } from '@app/models/state/state.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { GoalUtils } from '@app/shared/utils/goal.utils';
import moment from 'moment';

type TabEntity = GoalComparion;

interface GoalComparion {
  previous: Goal,
  current: Goal
}

@Component({
  selector: 'app-information-sidebar-goals',
  templateUrl: './information-sidebar-goals.component.html',
  styleUrls: ['./information-sidebar-goals.component.scss']
})
export class InformationSidebarGoalsComponent implements OnInit {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCommonMessages = CommonMessages;
  public readonly eGoalUtils = GoalUtils;
  
  @ViewChild('pagination') pagination?: PaginationNewComponent;

  @Input() userIds: number[];
  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meetingPrevious?: OneToOneMeetingMinimal;
  @Input() meetingCurrent?: OneToOneMeetingMinimal;
  @Input() publicGoalsOnly: boolean;

  state: IState;
  data: {
    master: TabEntity[];
    filtered: TabEntity[];
    display: TabEntity[];
  };
  formFilters: FormGroup;
  optionsGoalType: GoalType[];

  get controlFilterGoalType(): FormControl {
    return this.formFilters.controls.goalType as FormControl;
  }

  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService
  ) {
    this.userIds = [];
    this.schedule = undefined;
    this.meetingPrevious = undefined;
    this.meetingCurrent = undefined;
    this.publicGoalsOnly = false;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.data = {
      master: [],
      filtered: [],
      display: []
    };

    this.formFilters = this.initFormFilters();
    this.optionsGoalType = this.getOptionsGoalType();
  }

  ngOnInit(): void {
    this.getData();
  }
  
  getData(): void {
    if (!this.userIds) { return; }
    if (this.userIds.length === 0) { return; }

    const paramGoalType: GoalType[] = this.controlFilterGoalType.value ? [this.controlFilterGoalType.value] : [];
    const paramGoalVisibility: GoalVisibility[] = this.publicGoalsOnly ? [GoalVisibility.PUBLIC] : [];

    let ownerIds = this.userIds;
    if (this.schedule && this.schedule.participants.length === 1) {
      ownerIds = [this.schedule.participants[0].id];
    }

    this.goalsAPIService.getGoals(this.userIds, [], ownerIds, paramGoalType, [], paramGoalVisibility, false)
      .subscribe(data => {
        data = Goal.getGoalArrayCompletionPercentage(data);

        let dataParsed = [];
        dataParsed = this.getGoalDataForPeriod(data);
        dataParsed = this.sortData(dataParsed);
        this.populateData(dataParsed);

        this.state.loading = false;
      });
  }

  sortData(data: TabEntity[]): TabEntity[] {
    data = data.map(goal => {
      // Order owners by name
      goal.current.owners = goal.current.owners.sort((a, b) => {
        const sortStringA = `${a.firstName}${a.lastName}`;
        const sortStringB = `${b.firstName}${b.lastName}`;
        return sortStringA.localeCompare(sortStringB);
      });

      return goal;
    });

    // Sort by name
    data = data.sort((a, b) => {
      const sortStringA = new Date(a.current.endDate);
      const sortStringB = new Date(b.current.endDate);
      return sortStringA.getTime() - sortStringB.getTime();
    });

    // Order by due date, latest to earliest
    data = data.sort((a, b) => {
      const sortStringA = new Date(a.current.endDate);
      const sortStringB = new Date(b.current.endDate);
      return sortStringB.getTime() - sortStringA.getTime();
    });

    // Sort by how many owners on the goal are in the provided userIds
    data = data.sort((a, b) => {
      const sortStringA = a.current.owners.filter(owner => this.userIds.includes(owner.id)).length;
      const sortStringB = b.current.owners.filter(owner => this.userIds.includes(owner.id)).length;
      return sortStringB - sortStringA;
    });

    return data;
  }

  populateData(data?: TabEntity[]): void {
    if (!data) { data = this.data.master; }
    this.data.master = data;
    this.data.filtered = data;

    // this.universalFilterData.searchProps = ['name'];
    // this.universalFilterData.filterOptions = data.map(i => this.getFilterOptionsForBrowseRole(i));

    this.refreshPagination();
  }

  refreshPagination(): void {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  getGoalDataForPeriod(goals: Goal[]): GoalComparion[] {
    let meetingCurrentTimestamp = null;
    if (this.meetingCurrent && this.meetingCurrent.meetingTimestamp) {
      meetingCurrentTimestamp = this.meetingCurrent.meetingTimestamp;
    } else {
      meetingCurrentTimestamp = moment().toDate();
    }

    return goals
      .map(goal => {
        let previous = JSON.parse(JSON.stringify(goal));
        previous.completionPercentage = 0;
        let current = JSON.parse(JSON.stringify(goal));

        if (!meetingCurrentTimestamp) {
          return {
            previous: previous,
            current: current
          };
        }

        if(this.meetingPrevious) {
          previous = Goal.getGoalStateSnapShotFromActivity(previous, this.meetingPrevious.meetingTimestamp);
        } else {
          previous = Goal.getGoalStateSnapShotFromActivity(previous, meetingCurrentTimestamp);
        }
        
        current = Goal.getGoalStateSnapShotFromActivity(current, meetingCurrentTimestamp);
        return {
          previous: previous,
          current: current
        };
      })
      .sort((a, b) => b.current.completionPercentage - a.current.completionPercentage); // Sort by completion percentage
  }

  initFormFilters(): FormGroup {
    const formGroup = new FormGroup({
      goalType: new FormControl(null, [])
    });

    formGroup.valueChanges.subscribe(() => this.onFiltersChanged());

    return formGroup;
  }

  onFiltersChanged(): void {
    this.getData();
  }

  getOptionsGoalType(): GoalType[] {
    return Object.values(GoalType)
      .filter(value => {
        if (value === GoalType.OFFICE_LOCATION) {
          return this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION);
        }

        return true;
      });
  }
}
