<div class="container-profile-qualifications">
  <h4 class="standard-heading" translate>{{eQualificationMessages.QUALIFICATIONS}}</h4>

  <div class="row">
    <hr/>
  </div>

  <ng-container *ngIf="qualifications.length > 0; else templateNoDisplay;">
    <app-qualifications-list-display [qualifications]="qualifications"></app-qualifications-list-display>
  </ng-container>
  <ng-template #templateNoDisplay>
    <ng-container *ngIf="(globals.user.id === user.id); else templateEmptyOtherUser;">
      <app-empty-state [height]="'200px'" [message]="eQualificationMessages.EMPTY_STATE" [primaryButtonShown]="true" [primaryButtonText]="eQualificationMessages.ADD_QUALIFICATION" (primaryButtonPressed)="onClickEmptyState()"></app-empty-state>
    </ng-container>
    <ng-template #templateEmptyOtherUser>
      <app-empty-state [height]="'200px'" [message]="eQualificationMessages.EMPTY_STATE_OTHER_USER"></app-empty-state>
    </ng-template>
  </ng-template>
</div>