import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rich-display',
  templateUrl: './rich-display.component.html',
  styleUrls: ['./rich-display.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RichDisplayComponent  implements OnInit, OnChanges {
  @Input() text: string;
  visible: boolean;
  constructor() {
    this.text = '';
    this.visible = true;
  }
  ngOnInit() {
  }
  ngOnChanges() {
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    }, 50);
  }
}
