import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { VincereMetricResult } from '@app/models/integrations/vincere/vincere-metric-result.model';
import { VincereUserStatusType } from '@app/models/integrations/vincere/vincere-user-status-type.model';
import { VincereUserStatus } from '@app/models/integrations/vincere/vincere-user-status.model';
import { IState } from '@app/models/state/state.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { VincereMetricAPIService } from '@app/shared/api/vincere-metric-api.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vincere-sidebar',
  templateUrl: './vincere-sidebar.component.html',
  styleUrls: ['./vincere-sidebar.component.scss']
})
export class VincereSidebarComponent implements OnInit, OnDestroy {

  readonly eVincereUserStatusType = VincereUserStatusType;

  private readonly ngUnsubscribe$ = new Subject<void>();

    @Input() user!: UserMinimal;
    @Input() own = false;

    dates: Array<Date> = [];
    metrics: VincereMetricResult[];
    userStatus!: VincereUserStatus;

    form: FormGroup;
    state: IState;

    get monthControl(): FormControl {
      return this.form.controls.month as FormControl;
    }


    constructor(
        private vincereMetricAPIService: VincereMetricAPIService,
        private formBuilder: FormBuilder
    ) {
      this.metrics = [];

      this.state = {
        loading: true,
        error: false,
        errorMessage: ''
      };

      this.form = this.initForm();
    }

    ngOnInit(): void {
      if (!this.user) { return; } // TODO: Error handling
      this.vincereMetricAPIService.getDatesForUser(this.user!.id).subscribe(dates => {
        this.dates = dates;

        if (this.dates.length > 0) {
          this.form.setValue({
            month: this.dates[0],
          });
        } else {
          this.state.loading = false;
        }
      });
      this.getStatus();
      // TODO: Default this to the current year + month

    }

    ngOnDestroy(): void {
      this.ngUnsubscribe$.next();
      this.ngUnsubscribe$.unsubscribe();
    }

    initForm() {
      const formGroup = this.formBuilder.group({
        month: new FormControl(null, [])
      });

      formGroup.valueChanges.subscribe((formValue) => this.getData(this.monthControl.value));

      return formGroup;
    }

    getStatus(): void {
      this.vincereMetricAPIService.getUserStatus(this.user!.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(userStatus => {
          this.userStatus = userStatus;
        });

    }

    getData(date): void {

      const dateMoment = moment(date);
      const month = (dateMoment.month() + 1); // These are 0 indexed in moment
      const year = dateMoment.year();

      this.vincereMetricAPIService.getUserMetrics(this.user!.id, year, month)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(result => {
          this.metrics = result.sort((a, b) => a.vincereMetric.orderIndex - b.vincereMetric.orderIndex);
          this.state.loading = false;
        });
    }

}
