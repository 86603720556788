import { Component, Input } from '@angular/core';
import { IconHoverColor } from '@app/shared/components/inputs/table-action-icon/table-action-icon.component';
import { ServerSideEventProgressLevel } from '../../model/server-side-event-progress-type.model';
import { ServerSideEventType } from '../../model/server-side-event-type.model';
import { ServerSideEventData, SseProgressService } from '../../service/sse-progress.service';

@Component({
  selector: 'app-sse-details-item',
  templateUrl: './sse-details-item.component.html',
  styleUrls: ['./sse-details-item.component.scss']
})
export class SseDetailsItemComponent {

  public readonly eIconHoverColor = IconHoverColor;
  public readonly eServerSideEventType = ServerSideEventType;

  @Input() event: ServerSideEventData;

  dropdownOpen: boolean;

  get highestProgress(): number {
    if (!this.event) { return 0; }
    if (!this.event.progressEvents) { return 0; }
    if (this.event.progressEvents.length === 0) { return 0; }
    return Math.max(...this.event.progressEvents.map(e => e.progress));
  }

  get eventHasErrors(): boolean {
    if (!this.event) { return false; }
    if (!this.event.progressEvents) { return false; }
    if (this.event.progressEvents.length === 0) { return false; }
    if (this.event.progressEvents.some(e => e.level === ServerSideEventProgressLevel.DANGER)) { return true; }
    return false;
  }

  constructor(
    private sseProgressService: SseProgressService
  ) {
    this.dropdownOpen = false;
  }

  toggleDropdownOpen(): void {
    if (this.dropdownOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  openDropdown(): void {
    this.dropdownOpen = true;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  onClickRemoveEvent(): void {
    if (!this.event) { return; }
    this.sseProgressService.removeEvent(this.event.guid);
  }
}
