<div class="container-sidebar-skills">
  <app-state [state]="state">
    <ng-container *ngIf="!state.loading && !state.error;">
      <ng-container *ngIf="data.master.length > 0; else templateNoData;">
        <div class="data-list">
          <ng-container *ngFor="let skill of data.display; index as index;">
            <app-interest-display [interest]="skill"></app-interest-display>
          </ng-container>
        </div>

        <app-pagination-new-component [arrayData]="data.filtered" [arrayDisplay]="data.display" #pagination></app-pagination-new-component>
      </ng-container>

      <ng-template #templateNoData>
        <app-empty-state [height]="'200px'" [imageSrc]="'assets/img/empty-state/empty-state-skills.svg'" [message]="'No' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL)"></app-empty-state>
      </ng-template>
    </ng-container>
  </app-state>
</div>