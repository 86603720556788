import { Injectable } from '@angular/core';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { Subject } from 'rxjs';
import { ServerSideEventProgress } from '../model/server-side-event-progress.model';
import { ServerSideEventType } from '../model/server-side-event-type.model';

export enum ServerSideEventState {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

interface ServerSideEventRetryData {
    url: string;
    body?: any;
}

export interface ServerSideEventData {
  guid: string;
  progressEvents: ServerSideEventProgress[];
  state: ServerSideEventState;
  type: ServerSideEventType;
  retryData?: ServerSideEventRetryData; // TODO: Implement a way to retry events
}

@Injectable({
  providedIn: 'root'
})
export class SseProgressService {

  events: ServerSideEventData[];
  $progressEvents: Subject<ServerSideEventProgress>;

  constructor(
    private notifyUtils: NotifyUtils
  ) {
    this.$progressEvents = new Subject<ServerSideEventProgress>();
    this.events = [];
    // this.events = [
    //   {
    //     'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //     'progressEvents': [
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 0,
    //         'message': 'Long running job started',
    //         'level': ServerSideEventProgressLevel.INFO,
    //         'type': null,
    //         'timestamp': new Date('2023-02-02T13:07:17.015Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 11,
    //         'message': 'Goal created for David Kenny (1 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:17.745Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 22,
    //         'message': 'Goal created for Noel Dykes (2 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:18.161Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 33,
    //         'message': this.parseEventMessage('Failed to distribute goal to Martin McLaughlin (3 of 9) | {"message":"Test exception","errorCode":null}'),
    //         'level': ServerSideEventProgressLevel.DANGER,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:18.615Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 44,
    //         'message': 'Goal created for Daire Finn (4 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:19.266Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 55,
    //         'message': 'Goal created for Ronan McCabe (5 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:19.852Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 66,
    //         'message': 'Goal created for Clair Ashmore (6 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:20.689Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 77,
    //         'message': 'Goal created for Danny Maguire (7 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:21.325Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 88,
    //         'message': 'Goal created for Celina Murphy (8 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:22.040Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 100,
    //         'message': 'Goal created for Jason Franz (9 of 9)',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:22.637Z')
    //       },
    //       {
    //         'guid': '0842fffd-45b7-4aab-817c-d2ace4da7a92',
    //         'progress': 100,
    //         'message': 'Finished distributing goals',
    //         'level': ServerSideEventProgressLevel.SUCCESS,
    //         'type': ServerSideEventType.GOAL_DISTRIBUTION,
    //         'timestamp': new Date('2023-02-02T13:07:22.740Z')
    //       }
    //     ],
    //     state: ServerSideEventState.FINISHED,
    //     type: ServerSideEventType.GOAL_DISTRIBUTION
    //   }
    // ];
  }

  public addProgressEvent(guid: string, event: ServerSideEventProgress) {
    event.message = this.parseEventMessage(event.message);

    const isFinished = (event.progress >= 100);

    const existingEvent = this.events.find(e => e.guid === guid);

    if (isFinished) {
      if (existingEvent) {
        if (existingEvent.state !== ServerSideEventState.FINISHED) {
          this.notifyUtils.notify('Your long-running job has finished');
        }
      } else {
        this.notifyUtils.notify('Your long-running job has finished');
      }
    }

    if (existingEvent) {
      if (isFinished) {
        existingEvent.state = ServerSideEventState.FINISHED;
      }

      if (existingEvent.type == null) { // First event for POST listeners might not have a type
        existingEvent.type = event.type;
      }

      existingEvent.progressEvents.push(event);
      this.$progressEvents.next(event);
      return;
    }

    this.events.push({
      guid,
      progressEvents: [event],
      state: ServerSideEventState.IN_PROGRESS,
      type: event.type
    });
    this.$progressEvents.next(event);
  }

  public removeEvent(guid: string) {
    this.events = this.events.filter(e => e.guid !== guid);
  }

  private parseEventMessage(message: string): string {
    try {
      if (!message) { return message; }
      if (!message.includes('|')) { return message; }
  
      const [messageMain, messageError] = message.split('|');
  
      const messageErrorParsed = this.parseEventMessageError(messageError);
  
      return `${messageMain} | ${messageErrorParsed}`;
    } catch {
      return message;
    }
  }

  private parseEventMessageError(errorMessage: string): string {
    if (!errorMessage) { return errorMessage; }
    try {
      let errorText = errorMessage;
      let errorCode = null;

      const errorJson = JSON.parse(errorMessage);

      if (Object.hasOwnProperty.call(errorJson, 'message')) {
        errorText = errorJson.message;
      }

      if (Object.hasOwnProperty.call(errorJson, 'errorCode') && errorJson.errorCode) {
        errorCode = errorJson.errorCode;
      }

      if (errorCode) {
        return `[${errorCode}] ${errorText}`;
      } else {
        return errorText;
      }
    } catch {
      return errorMessage;
    }
  }
}
