<div class="container-sse-progress-bar" *ngIf="sseProgressService?.events?.length > 0">
  <div class="sse-progress-bar-inner" (click)="onClickProgressBar()">
    <div>These types of requests can take some time to complete. Click here to view them.</div>
    
    <app-progress-bar [currentValue]="currentValue"></app-progress-bar>
  </div>

  <div class="sse-progress-bar-modal">
    <app-modal #modalSseDetails>
      <ng-container *ngIf="modalSseDetails?.visible">
        <app-sse-details-overview></app-sse-details-overview>
      </ng-container>
    </app-modal>
  </div>
</div>
