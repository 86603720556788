import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OneToOneMeetingMinimal } from '@app/domain/one_to_one/model/one-to-one-meeting.model';
import { OneToOneScheduleDetailsView } from '@app/domain/one_to_one/model/one-to-one-schedule.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { IState } from '@app/models/state/state.model';
import { Globals } from '@app/shared/globals/globals';

export enum InformationSidebarTab {
  SCHEDULE_CALENDAR = 'SCHEDULE_CALENDAR',

  ONE_TO_ONES = 'ONE_TO_ONES',

  GOALS = 'GOALS',
  TASKS = 'TASKS',
  ACTION_POINTS = 'ACTION_POINTS',

  FEEDBACK_UNPROMPTED = 'FEEDBACK_UNPROMPTED',
  FEEDBACK_IDEAS = 'FEEDBACK_IDEAS',
  FEEDBACK_PRAISE = 'FEEDBACK_PRAISE',
  FEEDBACK_PEER = 'FEEDBACK_PEER',
  FEEDBACK_REQUESTS = 'FEEDBACK_REQUESTS',
  FEEDBACK_EXTERNAL = 'FEEDBACK_EXTERNAL',

  REVIEWS = 'REVIEWS',

  QUALIFICATIONS = 'QUALIFICATIONS',
  COMPETENCIES = 'COMPETENCIES',
  SKILLS = 'SKILLS',

  VINCERE = 'VINCERE',
  ZENDESK = 'ZENDESK',
  ABSORBLMS = 'ABSORBLMS',
  EVALUAGENT = 'EVALUAGENT',

  SENTIMENT_SCALE_TIMELINE = 'SENTIMENT_SCALE_TIMELINE',
}

export interface SidebarTabData {
  icon: string;
  title: string;
  description?: string;
  width?: string;
}

export interface SidebarTabMap<T> {
  [key: string]: T
}

@Component({
  selector: 'app-information-sidebar',
  templateUrl: './information-sidebar.component.html',
  styleUrls: ['./information-sidebar.component.scss']
})
export class InformationSidebarComponent implements OnInit {

  public readonly eInformationSidebarTab = InformationSidebarTab;

  @Input() startExpanded: boolean;
  @Input() tabsShown: InformationSidebarTab[];
  @Input() tabsBlocked: InformationSidebarTab[];
  @Input() defaultContentWidth: string;
  @Input() userIds: number[];
  @Input() publicGoalsOnly: boolean;

  @Input() schedule: OneToOneScheduleDetailsView;
  @Input() meetingNavigationControl: FormControl;

  expanded: boolean;
  currentTab: InformationSidebarTab;
  tabData: SidebarTabMap<SidebarTabData>;
  state: IState;

  get meetingCurrent(): OneToOneMeetingMinimal {
    if (!this.schedule) return undefined;
    if (!this.meetingNavigationControl) return undefined;

    return this.schedule.meetingList.find(m => m.id === this.meetingNavigationControl.value);
  }

  get meetingPrevious(): OneToOneMeetingMinimal {
    if (!this.schedule) return undefined;
    if (!this.meetingNavigationControl) return undefined;

    const index = this.schedule.meetingList.findIndex(m => m.id === this.meetingNavigationControl.value);
    return this.schedule.meetingList[index - 1];
  }

  constructor(
    public globals: Globals
  ) {
    this.currentTab = undefined;

    this.startExpanded = false;
    this.expanded = true;
    this.publicGoalsOnly = false;

    this.tabsShown = Object.keys(InformationSidebarTab) as InformationSidebarTab[];
    this.tabsBlocked = [];

    this.defaultContentWidth = '400px';

    this.userIds = [];

    this.schedule = undefined;
    this.meetingNavigationControl = new FormControl(null, []);

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };
  }

  ngOnInit(): void {
    this.expanded = this.startExpanded;
    this.tabData = this.getDefaultTabData(); // TODO: Allow for overriding this per page
    this.tabsShown = this.getTabsShown(this.tabsShown, this.tabsBlocked);
    this.currentTab = this.getDefaultTab(this.tabsShown);
    this.state.loading = false;
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  getTabsShown(shown: InformationSidebarTab[], blocked: InformationSidebarTab[]): InformationSidebarTab[] {
    return shown
      .filter(t => !blocked.includes(t))
      .filter(t => this.checkIfTabFeatureEnabled(t));
  }

  checkIfTabFeatureEnabled(tabType: InformationSidebarTab): boolean {
    switch(tabType) {
      case InformationSidebarTab.COMPETENCIES:
        return this.globals.hasFeature(CompanyFeatures.COMPETENCIES);
      case InformationSidebarTab.ONE_TO_ONES:
        return this.globals.hasFeature(CompanyFeatures.ONE_TO_ONE);
      case InformationSidebarTab.FEEDBACK_UNPROMPTED:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_UNPROMPTED);
      case InformationSidebarTab.FEEDBACK_IDEAS:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_IDEAS);
      case InformationSidebarTab.FEEDBACK_PRAISE:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_PRAISE);
      case InformationSidebarTab.FEEDBACK_PEER:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_PEER);
      case InformationSidebarTab.FEEDBACK_REQUESTS:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_REQUESTS);
      case InformationSidebarTab.FEEDBACK_EXTERNAL:
        return this.globals.hasFeature(CompanyFeatures.FEEDBACK, CompanyFeatures.FEEDBACK_BANK);
      case InformationSidebarTab.REVIEWS:
        return this.globals.hasFeature(CompanyFeatures.EVALUATION_CYCLES) && (this.userIds.length === 2);
      case InformationSidebarTab.VINCERE:
        return this.globals.hasFeature(CompanyFeatures.VINCERE) && (this.userIds.length === 2);
      case InformationSidebarTab.ZENDESK:
        return this.globals.hasFeature(CompanyFeatures.ZENDESK) && (this.userIds.length === 2);
      case InformationSidebarTab.GOALS:
        return this.globals.hasFeature(CompanyFeatures.GOALS);
      case InformationSidebarTab.TASKS:
        return this.globals.hasFeature(CompanyFeatures.TODOS);
      case InformationSidebarTab.QUALIFICATIONS:
        return this.globals.hasFeature(CompanyFeatures.QUALIFICATIONS);
      case InformationSidebarTab.SKILLS:
        return this.globals.hasFeature(CompanyFeatures.SKILLS);
      case InformationSidebarTab.ABSORBLMS:
        return this.globals.hasFeature(CompanyFeatures.ABSORBLMS) && (this.userIds.length === 2);
      case InformationSidebarTab.SCHEDULE_CALENDAR:
        return this.schedule !== undefined;
      default:
        return true;
    }
  }

  getDefaultTab(tabsShown: InformationSidebarTab[]): InformationSidebarTab {
    if (!tabsShown) { return undefined; }
    if (tabsShown.length === 0) { return undefined; }
    return tabsShown[0];
  }

  getDefaultTabData(): SidebarTabMap<SidebarTabData> {
    return {
      [InformationSidebarTab.SCHEDULE_CALENDAR]: {
        icon: 'fa-calendar',
        title: 'Calendar'
      },
      [InformationSidebarTab.COMPETENCIES]: {
        icon: 'fa-sticky-note',
        title: this.globals.getTerminology(TerminologyEntity.COMPETENCY_PLURAL)
      },
      [InformationSidebarTab.ONE_TO_ONES]: {
        icon: 'fa-calendar',
        title: '1:1 schedules'
      },
      [InformationSidebarTab.FEEDBACK_UNPROMPTED]: {
        icon: 'fa-bullhorn',
        title: 'Unprompted feedback'
      },
      [InformationSidebarTab.FEEDBACK_IDEAS]: {
        icon: 'fa-lightbulb',
        title: 'Ideas'
      },
      [InformationSidebarTab.FEEDBACK_PEER]: {
        icon: 'fa-bullhorn',
        title: 'Peer feedback'
      },
      [InformationSidebarTab.FEEDBACK_PRAISE]: {
        icon: 'fa-thumbs-up',
        title: 'Praise'
      },
      [InformationSidebarTab.FEEDBACK_REQUESTS]: {
        icon: 'fa-bullhorn',
        title: 'Feedback requests'
      },
      [InformationSidebarTab.FEEDBACK_EXTERNAL]: {
        icon: 'fa-bullhorn',
        title: 'External feedback'
      },
      [InformationSidebarTab.REVIEWS]: {
        icon: 'fa-clipboard-user',
        title: 'Reviews'
      },
      [InformationSidebarTab.VINCERE]: {
        icon: 'fa-user',
        title: 'Vincere'
      },
      [InformationSidebarTab.ZENDESK]: {
        icon: 'fa-user-headset',
        title: 'Zendesk'
      },
      [InformationSidebarTab.GOALS]: {
        icon: 'fa-bullseye',
        title: 'Goals'
      },
      [InformationSidebarTab.TASKS]: {
        icon: 'fa-tasks',
        title: 'Tasks'
      },
      [InformationSidebarTab.QUALIFICATIONS]: {
        icon: 'fa-certificate',
        title: 'Qualifications'
      },
      [InformationSidebarTab.SKILLS]: {
        icon: 'fa-screwdriver-wrench',
        title: this.globals.getTerminology(TerminologyEntity.SKILL_PLURAL)
      },
      [InformationSidebarTab.ABSORBLMS]: {
        icon: 'fa-graduation-cap',
        title: 'Absorb enrollments (Beta)'
      },
      [InformationSidebarTab.EVALUAGENT]: {
        icon: 'fa-badge-percent',
        title: 'Evaluagent stats'
      },
      [InformationSidebarTab.ACTION_POINTS]: {
        icon: 'fa-list',
        title: 'Action points'
      },
      [InformationSidebarTab.SENTIMENT_SCALE_TIMELINE]: {
        icon: 'fa-chart-line',
        title: 'Sentiment scale history'
      }
    };
  }

  openTab(tabType: InformationSidebarTab): void {
    if (this.currentTab === tabType) {
      this.toggleExpanded();
      return;
    }

    if (!this.expanded) {
      this.toggleExpanded();
    }
    this.currentTab = tabType;
  }
}
